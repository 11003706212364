import React from 'react';
import './FullScreenPanel.scss';

/**
 * Strictly speaking this layout is not necessary, though I do like the semantics of being able to look at a screen and instantly
 * see and change the layout
 * @param props The properties passed to the element
 */
function FullScreenPanelLayout({
  children = <></>,
}: {
  children?: JSX.Element | JSX.Element[];
}) {
  return <div className="full-screen-panel-layout">{children}</div>;
}

export default FullScreenPanelLayout;
