export enum PatronState {
  WaitingForChart = 'WAITING_FOR_CHART',
  WaitingForTriage = 'WAITING_FOR_TRIAGE',
  Waiting = 'WAITING',
  MeetingRequest = 'MEETING_REQUEST',
  InMeeting = 'IN_MEETING',
  PostTriage = 'POST_TRIAGE',
  Completed = 'COMPLETED',
  Ejected = 'EJECTED',
  Left = 'LEFT',
}
