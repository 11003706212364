import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import PinIcon from "./PinIcon";
import ScreenShareOutlinedIcon from "@material-ui/icons/ScreenShareOutlined";
import Typography from "@material-ui/core/Typography";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import { Grid, useTheme } from "@material-ui/core";
import { useDeviceOrientation } from "../DeviceOrientationProvider";
import _ from "lodash";
import { useWrappedTranslation } from "../../utils/i18nService";
export default function ParticipantInfo({ participant, isSelected, isLocalParticipant, color, isCenter = false }) {
    const t = useWrappedTranslation();
    const theme = useTheme();
    const definedColor = color ? color : theme.palette.text.primary;
    const publications = usePublications(participant);
    const { clientDimension } = useDeviceOrientation();
    const audioPublication = publications.find(p => p.kind === "audio");
    const isScreenShareEnabled = publications.find(p => p.trackName.includes("screen"));
    const audioTrack = useTrack(audioPublication);
    return (_jsxs(Grid, { container: true, spacing: 1, alignItems: "center", children: [isScreenShareEnabled && (_jsx(Grid, { item: true, style: {
                    color: definedColor,
                    height: "29px",
                    marginLeft: "4px"
                }, children: _jsx(ScreenShareOutlinedIcon, { fontSize: "small" }) })), _jsx(Grid, { item: true, style: { height: "29px" }, children: _jsx(AudioLevelIndicator, { audioTrack: audioTrack, color: color }) }), _jsx(Grid, { xs: true, item: true, zeroMinWidth: true, style: { display: "flex" }, children: _jsxs(Grid, { container: true, justifyContent: isCenter ? "center" : "flex-start", wrap: "nowrap", spacing: 1, children: [(clientDimension.width > theme.breakpoints.values.sm ||
                            !isLocalParticipant) && (_jsx(Grid, { item: true, zeroMinWidth: true, children: _jsx(Typography, { variant: "body1", style: { color: definedColor }, noWrap: true, children: _.get(_.split(participant.identity, "|"), "0") }) })), isLocalParticipant && (_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "body1", style: { color: definedColor }, noWrap: true, children: isLocalParticipant && t("ParticipantInfo.Local") }) }))] }) }), _jsx(Grid, { item: true, children: _jsx(NetworkQualityLevel, { participant: participant, color: color }) }), isSelected && (_jsx(Grid, { item: true, style: { height: "29px" }, children: _jsx(PinIcon, { color: color }) }))] }));
}
