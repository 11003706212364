import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tooltip from '@material-ui/core/Tooltip';
import { Box, Button, IconButton, MenuItem, Typography, useTheme, } from '@material-ui/core';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useWrappedTranslation } from '../../../utils/i18nService';
import { useDeviceOrientation } from '../../DeviceOrientationProvider';
import { IconContainer } from '../../MenuBar/Menu/Menu';
export default function ToggleScreenShareButton(props) {
    const t = useWrappedTranslation();
    const theme = useTheme();
    const { isSharingScreen, toggleScreenShare } = useVideoContext();
    const { isPortrait } = useDeviceOrientation();
    const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    const isDisabled = props.disabled || !isScreenShareSupported;
    let tooltipMessage = isSharingScreen
        ? t('ToggleScreenShareButton.InProgress')
        : t('ToggleScreenShareButton');
    if (!isScreenShareSupported) {
        tooltipMessage = t('ToggleScreenShareButton.NotSupported');
    }
    if (props.variant && props.variant === 'menuItem') {
        return (_jsxs(MenuItem, { onClick: toggleScreenShare, disabled: isDisabled, children: [_jsx(IconContainer, { children: _jsx(ScreenShareOutlinedIcon, {}) }), _jsx(Typography, { variant: "body1", children: t('ToggleScreenShareButton') })] }));
    }
    if (props.variant && props.variant === 'icon') {
        return (_jsx(Tooltip, { title: tooltipMessage, placement: isPortrait ? 'left' : 'top', children: _jsx(Box, { boxShadow: 2, borderRadius: 20, children: _jsx(IconButton, { onClick: toggleScreenShare, disabled: isDisabled, style: {
                        backgroundColor: isSharingScreen
                            ? theme.palette.success.main
                            : theme.palette.grey[600],
                        color: theme.palette.getContrastText(isSharingScreen
                            ? theme.palette.success.main
                            : theme.palette.grey[600]),
                        padding: '6px',
                    }, "aria-label": tooltipMessage, "data-cy-share-screen": true, children: _jsx(ScreenShareOutlinedIcon, {}) }) }) }));
    }
    return (_jsx(Tooltip, { title: tooltipMessage, placement: "top", PopperProps: { disablePortal: true }, style: { cursor: isDisabled ? 'not-allowed' : 'pointer' }, children: _jsx("span", { children: _jsx(Button, { onClick: toggleScreenShare, disabled: isDisabled, "aria-label": tooltipMessage, "data-cy-share-screen": true, children: t('ToggleScreenShareButton') }) }) }));
}
