import { Box } from '@material-ui/core';
import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { TwilioVideoCall, useAppState, useVideoContext } from 'twilio-client';
import DataContext from '../../Contexts/DataContext';
import { joinAsGuest } from '../../Services/LinkService';

function LinkMeeting({
  meetingName,
  firstName,
  lastName,
  onLeaving,
}: {
  meetingName: string;
  firstName: string;
  lastName: string;
  onLeaving: (...a: any) => void;
}) {
  const { applicationConfig } = useContext(DataContext);
  const { setRoom, setName, setCaptioningAllowed } = useAppState();
  const { room } = useVideoContext();

  useEffect(() => {
    setRoom(meetingName);
    setName(`${firstName} ${lastName}`);
    joinAsGuest(meetingName, `${firstName} ${lastName}`);
    setCaptioningAllowed(_.get(applicationConfig, 'captioningAllowed', false));
  }, [
    firstName,
    lastName,
    meetingName,
    setName,
    setRoom,
    setCaptioningAllowed,
    applicationConfig,
  ]);

  useEffect(() => {
    if (room) {
      room.on('disconnected', onLeaving);
      return () => {
        room.off('disconnected', onLeaving);
        room.disconnect();
      };
    }
    return () => undefined;
  }, [room, onLeaving]);

  return (
    <Box display="flex" flex={1} className="meeting-screen">
      <Box display="flex" flex={1} className="meeting-in-progress">
        <TwilioVideoCall />
      </Box>
    </Box>
  );
}

export default LinkMeeting;
