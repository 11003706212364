/* eslint-disable @typescript-eslint/no-shadow */
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Field, FieldProps, Form, Formik, getIn } from 'formik';
import _ from 'lodash';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import {
  FormikErrorSnackbar,
  HealthCardCustom,
  HealthIDCustom,
  NumberFormatPhone,
  RegistrationCustom,
  SinglePanelLayout,
  TextBlockDescription,
} from '../../Components';
import useClinicConfig from '../../Hooks/useClinicConfig';
import { useFeatureFlags } from '../../Hooks/useFeatureFlags';
import { useExtendedTranslation } from '../../Services/i18nService';
import { ISurveyData, RegionNames } from '../../Types';
import { PHONE_REG_EXP } from '../../Util/phoneValid';
import {
  HEALTHID_REGEX,
  HEALTH_CARD_REGEX,
  REGISTRATION_REGEX,
  USER_ADDRESS_ENTRY_REGEX,
  USER_ENTRY_REGEX,
  areAllValuesTruthy,
} from '../../Util/util';
import './ScreenSummary.scss';

const SummaryFieldLayout: FC<{
  editMode: boolean;
  label: JSX.Element;
  input: JSX.Element;
  required?: boolean;
}> = ({ editMode, label, input, required = false }) => {
  return (
    <Grid
      container
      direction={editMode ? 'column' : 'row'}
      spacing={editMode ? 0 : 1}
      alignItems={editMode ? 'flex-start' : 'center'}
    >
      <Grid item xs={editMode ? 'auto' : 6}>
        <Grid container>
          <Grid item>{label}</Grid>
          {required && editMode && (
            <Grid item>
              <Typography color="error">*</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        zeroMinWidth
        xs={editMode ? 'auto' : true}
        style={{ minWidth: editMode ? '100%' : 'auto' }}
      >
        {input}
      </Grid>
    </Grid>
  );
};

function ScreenSummary({
  userData,
  setUserData,
  onNext,
}: {
  userData: ISurveyData;
  setUserData: Function;
  onNext: Function;
}) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const [editMode, setEditMode] = useState(false);

  const hasHealthCardNumber = !_.isEmpty(userData.healthCardNumber);
  const hasHealthCardBlob = !!userData.healthCardBlob;
  const hasPhinNumber = !_.isEmpty(userData.healthIdNumber);

  const {
    REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION,
    REACT_APP_USE_MANITOBA_HEALTHCARD,
  } = useFeatureFlags();

  const validationSchema = Yup.object().shape({
    ...{
      firstName: Yup.string()
        .matches(USER_ENTRY_REGEX, t('checkin.firstName.error'))
        .max(128, t('checkin.firstName.error'))
        .required(t('checkin.firstName.error')),
      lastName: Yup.string()
        .matches(USER_ENTRY_REGEX, t('checkin.lastName.error'))
        .max(128, t('checkin.lastName.error'))
        .required(t('checkin.lastName.error')),
      email: Yup.string()
        .email(t('checkin.email.error'))
        .matches(USER_ENTRY_REGEX, t('checkin.email.error'))
        .required(t('checkin.email.error')),
      phoneNumber: Yup.string()
        .matches(PHONE_REG_EXP, t('checkin.phone.error'))
        .matches(USER_ENTRY_REGEX, t('checkin.phone.error'))
        .min(10, t('checkin.phone.error'))
        .required(t('checkin.phone.error')),
      currentAddress: REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION
        ? Yup.string()
        : Yup.string()
            .matches(USER_ADDRESS_ENTRY_REGEX, t('checkin.address.error'))
            .max(128, t('checkin.address.error'))
            .required(t('checkin.address.error')),
      inRegion: Yup.boolean()
        .oneOf([true, false], t('checkin.inRegion.error'))
        .required(t('checkin.inRegion.error')),
      familyDoctor: Yup.string()
        .matches(USER_ENTRY_REGEX, t('provideInfo.familyDoctor.error'))
        .max(128, t('provideInfo.familyDoctor.error')),
      homeAddress: Yup.string()
        .matches(USER_ADDRESS_ENTRY_REGEX, t('provideInfo.homeAddress.error'))
        .max(128, t('provideInfo.homeAddress.error')),
      pregnantQ: Yup.string().required(t('provideInfo.required')),
    },

    ...(!REACT_APP_USE_MANITOBA_HEALTHCARD && {
      healthCardNumber: hasHealthCardNumber
        ? Yup.string()
            .matches(HEALTH_CARD_REGEX, t('healthcardform.healthcard.error'))
            .required(t('healthcardform.healthcard.error'))
        : Yup.string(),
    }),

    ...(REACT_APP_USE_MANITOBA_HEALTHCARD && {
      // Manitoba HealthCard validator
      hasNoHealthCard: Yup.boolean(),
      healthIdNumber: Yup.string().when('hasNoHealthCard', {
        is: false,
        then: () =>
          Yup.string()
            .matches(HEALTHID_REGEX, t('manitobahealthcardform.healthId.error'))
            .required(t('manitobahealthcardform.healthId.error')),
        otherwise: () => Yup.string().optional(),
      }),
      registrationNumber: Yup.string().when('hasNoHealthCard', {
        is: false,
        then: () =>
          Yup.string()
            .matches(
              REGISTRATION_REGEX,
              t('manitobahealthcardform.registration.error')
            )
            .required(t('manitobahealthcardform.registration.error')),
        otherwise: () => Yup.string().optional(),
      }),
      dobDay: Yup.number().when('hasNoHealthCard', {
        is: false,
        then: (s) =>
          s
            .required(t('manitobahealthcardform.dob.day.error'))
            .min(1, t('manitobahealthcardform.dob.day.error'))
            .max(31, t('manitobahealthcardform.dob.day.error')),
        otherwise: () => Yup.number().optional(),
      }),
      dobMonth: Yup.number().when('hasNoHealthCard', {
        is: false,
        then: (s) =>
          s
            .required(t('manitobahealthcardform.dob.month.error'))
            .min(1, t('manitobahealthcardform.dob.month.error'))
            .max(12, t('manitobahealthcardform.dob.month.error')),
        otherwise: () => Yup.number().optional(),
      }),
      dobYear: Yup.number().when('hasNoHealthCard', {
        is: false,
        then: (s) =>
          s
            .required(t('manitobahealthcardform.dob.year.error'))
            .min(1900, t('manitobahealthcardform.dob.year.error'))
            .max(2100, t('manitobahealthcardform.dob.year.error')),
        otherwise: () => Yup.number().optional(),
      }),
    }),
  });

  return (
    <Box display="flex" flex={1} className="screen-summary">
      <Formik
        enableReinitialize
        initialValues={userData}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (editMode) {
            setUserData(values);
            setEditMode(false);
            setSubmitting(false);
            return;
          }

          if (!values.hasNoHealthCard) {
            onNext(values);
            return;
          }

          const valuesWithoutHealthCard = {
            ...values,
            healthIdNumber: undefined,
            registrationNumber: undefined,
            dobDay: undefined,
            dobMonth: undefined,
            dobYear: undefined,
          };

          onNext(valuesWithoutHealthCard);
        }}
      >
        {({ values, isSubmitting, isValidating, submitForm }) => (
          <Form style={{ display: 'flex', flex: 1 }}>
            <FormikErrorSnackbar />
            <SinglePanelLayout
              footer={
                <Grid container justifyContent="center">
                  <Grid item>
                    {editMode ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          isSubmitting ||
                          isValidating ||
                          areAllValuesTruthy(
                            _.pick(
                              values,
                              _.compact([
                                'firstName',
                                'lastName',
                                'email',
                                'phoneNumber',
                                'inRegion',
                                'pregnantQ',
                                !REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION &&
                                  'currentAddress',
                                hasHealthCardNumber && 'healthCardNumber',
                                hasPhinNumber && 'healthIdNumber',
                                hasPhinNumber && 'registrationNumber',
                              ])
                            )
                          )
                        }
                        onClick={submitForm}
                      >
                        {t('summary.update')}
                      </Button>
                    ) : (
                      <Button
                        disabled={isSubmitting || isValidating}
                        onClick={submitForm}
                        variant="contained"
                        color="primary"
                      >
                        {t('summary.continue')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              }
              logo={clinicConfig.logoName}
            >
              <TextBlockDescription>
                {t('summary.description')}
              </TextBlockDescription>
              <Grid
                container
                direction="column"
                spacing={1}
                style={{ paddingBottom: '16px' }}
              >
                <Grid item>
                  <SummaryFieldLayout
                    required
                    editMode={editMode}
                    label={
                      <Typography variant="body2" color="primary">
                        {t('summary.firstname')}
                      </Typography>
                    }
                    input={
                      <Field name="firstName">
                        {({ form, field }: FieldProps) => (
                          <TextField
                            fullWidth
                            error={
                              getIn(form.errors, field.name) &&
                              getIn(form.touched, field.name)
                            }
                            variant="outlined"
                            margin="dense"
                            inputProps={{ maxLength: 128 }}
                            disabled={!editMode}
                            {...field}
                          />
                        )}
                      </Field>
                    }
                  />
                </Grid>
                <Grid item>
                  <SummaryFieldLayout
                    required
                    editMode={editMode}
                    label={
                      <Typography variant="body2" color="primary">
                        {t('summary.lastname')}
                      </Typography>
                    }
                    input={
                      <Field name="lastName">
                        {({ form, field }: FieldProps) => (
                          <TextField
                            fullWidth
                            error={
                              getIn(form.errors, field.name) &&
                              getIn(form.touched, field.name)
                            }
                            variant="outlined"
                            margin="dense"
                            inputProps={{ maxLength: 128 }}
                            disabled={!editMode}
                            {...field}
                          />
                        )}
                      </Field>
                    }
                  />
                </Grid>
                <Grid item>
                  <SummaryFieldLayout
                    required
                    editMode={editMode}
                    label={
                      <Typography variant="body2" color="primary">
                        {t('summary.phone')}
                      </Typography>
                    }
                    input={
                      <Field name="phoneNumber">
                        {({ form, field }: FieldProps) => (
                          <TextField
                            fullWidth
                            error={
                              getIn(form.errors, field.name) &&
                              getIn(form.touched, field.name)
                            }
                            variant="outlined"
                            margin="dense"
                            InputProps={{
                              inputComponent: NumberFormatPhone,
                            }}
                            inputProps={{ maxLength: 128 }}
                            disabled={!editMode}
                            {...field}
                            onChange={(val) =>
                              form.setFieldValue(field.name, val.target.value)
                            }
                          />
                        )}
                      </Field>
                    }
                  />
                </Grid>
                <Grid item>
                  <SummaryFieldLayout
                    required
                    editMode={editMode}
                    label={
                      <Typography variant="body2" color="primary">
                        {t('summary.email')}
                      </Typography>
                    }
                    input={
                      <Field name="email">
                        {({ form, field }: FieldProps) => (
                          <TextField
                            fullWidth
                            type="email"
                            error={
                              getIn(form.errors, field.name) &&
                              getIn(form.touched, field.name)
                            }
                            variant="outlined"
                            margin="dense"
                            inputProps={{ maxLength: 128 }}
                            disabled={!editMode}
                            {...field}
                          />
                        )}
                      </Field>
                    }
                  />
                </Grid>
                <Grid item>
                  <SummaryFieldLayout
                    required={!REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION}
                    editMode={editMode}
                    label={
                      <Typography variant="body2" color="primary">
                        {t('checkin.address')}
                      </Typography>
                    }
                    input={
                      <Field name="currentAddress">
                        {({ form, field }: FieldProps) => (
                          <TextField
                            placeholder={t(
                              'provideInfo.homeAddress.placeholder'
                            )}
                            fullWidth
                            error={
                              getIn(form.errors, field.name) &&
                              getIn(form.touched, field.name)
                            }
                            variant="outlined"
                            multiline
                            minRows={3}
                            margin="dense"
                            inputProps={{ maxLength: 128 }}
                            disabled={!editMode}
                            {...field}
                          />
                        )}
                      </Field>
                    }
                  />
                </Grid>
                {(values.homeAddress || editMode) && (
                  <Grid item>
                    <SummaryFieldLayout
                      editMode={editMode}
                      label={
                        <Typography variant="body2" color="primary">
                          {t('provideInfo.homeAddress')}
                        </Typography>
                      }
                      input={
                        <Field name="homeAddress">
                          {({ form, field }: FieldProps) => (
                            <TextField
                              placeholder={t(
                                'provideInfo.homeAddress.placeholder'
                              )}
                              fullWidth
                              error={
                                getIn(form.errors, field.name) &&
                                getIn(form.touched, field.name)
                              }
                              variant="outlined"
                              multiline
                              minRows={3}
                              margin="dense"
                              inputProps={{ maxLength: 128 }}
                              disabled={!editMode}
                              {...field}
                            />
                          )}
                        </Field>
                      }
                    />
                  </Grid>
                )}
                {(values.familyDoctor || editMode) && (
                  <Grid item>
                    <SummaryFieldLayout
                      editMode={editMode}
                      label={
                        <Typography variant="body2" color="primary">
                          {t('summary.doctor.long')}
                        </Typography>
                      }
                      input={
                        <Field name="familyDoctor">
                          {({ form, field }: FieldProps) => (
                            <TextField
                              fullWidth
                              error={
                                getIn(form.errors, field.name) &&
                                getIn(form.touched, field.name)
                              }
                              variant="outlined"
                              margin="dense"
                              inputProps={{ maxLength: 128 }}
                              disabled={!editMode}
                              {...field}
                            />
                          )}
                        </Field>
                      }
                    />
                  </Grid>
                )}
                <Grid item>
                  <SummaryFieldLayout
                    required
                    editMode={editMode}
                    label={
                      <Typography variant="body2" color="primary">
                        {t('summary.pregnant.long')}
                      </Typography>
                    }
                    input={
                      <Field name="pregnantQ">
                        {({ field }: FieldProps) => (
                          <RadioGroup defaultValue="" {...field}>
                            <FormControlLabel
                              value="Yes"
                              control={
                                <Radio color="primary" disabled={!editMode} />
                              }
                              label={
                                <Typography variant="body2">
                                  {t('questionnaire.yes.button')}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="Not sure"
                              control={
                                <Radio color="primary" disabled={!editMode} />
                              }
                              label={
                                <Typography variant="body2">
                                  {t('questionnaire.notsure.button')}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="No/Does not apply"
                              control={
                                <Radio color="primary" disabled={!editMode} />
                              }
                              label={
                                <Typography variant="body2">
                                  {t('questionnaire.no_or_dna.button')}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        )}
                      </Field>
                    }
                  />
                </Grid>

                {!REACT_APP_USE_MANITOBA_HEALTHCARD && (
                  <>
                    {hasHealthCardNumber && (
                      <Grid item>
                        <SummaryFieldLayout
                          required
                          editMode={editMode}
                          label={
                            <Typography variant="body2" color="primary">
                              {t('summary.healthcard')}
                            </Typography>
                          }
                          input={
                            <Field name="healthCardNumber">
                              {({ form, field }: FieldProps) => (
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  margin="dense"
                                  error={
                                    getIn(form.errors, field.name) &&
                                    getIn(form.touched, field.name)
                                  }
                                  InputProps={{
                                    inputComponent: HealthCardCustom,
                                  }}
                                  // eslint-disable-next-line react/jsx-no-duplicate-props
                                  inputProps={{
                                    maxLength: 128,
                                  }}
                                  placeholder={t(
                                    'healthcardform.healthcard.placeholder'
                                  )}
                                  disabled={!editMode}
                                  {...field}
                                />
                              )}
                            </Field>
                          }
                        />
                      </Grid>
                    )}

                    {hasHealthCardBlob && (
                      <Grid item>
                        <SummaryFieldLayout
                          required
                          editMode={editMode}
                          label={
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontStyle: 'italic' }}
                            >
                              {t('summary.healthcard')}
                            </Typography>
                          }
                          input={
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontStyle: 'italic' }}
                            >
                              {values.healthCardBlob
                                ? t('summary.attached')
                                : t('summary.unattached')}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                  </>
                )}

                {REACT_APP_USE_MANITOBA_HEALTHCARD && (
                  <>
                    <Grid item>
                      <Divider />
                      <SummaryFieldLayout
                        required
                        editMode={editMode}
                        label={
                          <Typography variant="body2" color="primary">
                            {t('summary.region')}
                          </Typography>
                        }
                        input={
                          <Field name="shmbRegion">
                            {({ field }: FieldProps) => (
                              <RadioGroup defaultValue="" {...field}>
                                <FormControlLabel
                                  value={RegionNames.Winnipeg}
                                  label={RegionNames.Winnipeg}
                                  control={
                                    <Radio
                                      color="primary"
                                      disabled={!editMode}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value={RegionNames.Interlake}
                                  label={RegionNames.Interlake}
                                  control={
                                    <Radio
                                      color="primary"
                                      disabled={!editMode}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value={RegionNames.Southern}
                                  label={RegionNames.Southern}
                                  control={
                                    <Radio
                                      color="primary"
                                      disabled={!editMode}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value={RegionNames.Prairie}
                                  label={RegionNames.Prairie}
                                  control={
                                    <Radio
                                      color="primary"
                                      disabled={!editMode}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value={RegionNames.Northern}
                                  label={RegionNames.Northern}
                                  control={
                                    <Radio
                                      color="primary"
                                      disabled={!editMode}
                                    />
                                  }
                                />
                              </RadioGroup>
                            )}
                          </Field>
                        }
                      />
                    </Grid>

                    {!values.hasNoHealthCard && (
                      <>
                        <Grid item>
                          <SummaryFieldLayout
                            required
                            editMode={editMode}
                            label={
                              <Typography variant="body2" color="primary">
                                {t('manitobahealthcardform.healthId')}
                              </Typography>
                            }
                            input={
                              <Field name="healthIdNumber">
                                {({ form, field }: FieldProps) => (
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    error={
                                      getIn(form.errors, field.name) &&
                                      getIn(form.touched, field.name)
                                    }
                                    InputProps={{
                                      inputComponent: HealthIDCustom,
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    inputProps={{
                                      maxLength: 128,
                                    }}
                                    placeholder={t(
                                      'manitobahealthcardform.healthId.placeholder'
                                    )}
                                    disabled={!editMode}
                                    {...field}
                                  />
                                )}
                              </Field>
                            }
                          />
                          <SummaryFieldLayout
                            required
                            editMode={editMode}
                            label={
                              <Typography variant="body2" color="primary">
                                {t('manitobahealthcardform.registration')}
                              </Typography>
                            }
                            input={
                              <Field name="registrationNumber">
                                {({ form, field }: FieldProps) => (
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    error={
                                      getIn(form.errors, field.name) &&
                                      getIn(form.touched, field.name)
                                    }
                                    InputProps={{
                                      inputComponent: RegistrationCustom,
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    inputProps={{
                                      maxLength: 128,
                                    }}
                                    placeholder={t(
                                      'manitobahealthcardform.registration.placeholder'
                                    )}
                                    disabled={!editMode}
                                    {...field}
                                  />
                                )}
                              </Field>
                            }
                          />
                        </Grid>
                        <Grid item>
                          <SummaryFieldLayout
                            required
                            editMode={editMode}
                            label={
                              <Typography variant="body2" color="primary">
                                {t('manitobahealthcardform.dob')}
                              </Typography>
                            }
                            input={
                              <>
                                <FormControl style={{ marginBottom: '16px' }}>
                                  <Grid container style={{ gap: '0.25rem' }}>
                                    <Grid item xs={3}>
                                      <FormControl>
                                        <FormLabel>
                                          {t('manitobahealthcardform.dob.day')}
                                        </FormLabel>
                                        <Field name="dobDay">
                                          {({ form, field }: FieldProps) => (
                                            <TextField
                                              fullWidth
                                              variant="outlined"
                                              margin="dense"
                                              type="text"
                                              error={
                                                getIn(
                                                  form.errors,
                                                  field.name
                                                ) &&
                                                getIn(form.touched, field.name)
                                              }
                                              placeholder={t(
                                                'manitobahealthcardform.dob.day.placeholder'
                                              )}
                                              inputProps={{
                                                maxLength: 2,
                                                inputMode: 'numeric',
                                              }}
                                              disabled={!editMode}
                                              {...field}
                                            />
                                          )}
                                        </Field>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <FormControl>
                                        <FormLabel>
                                          {t(
                                            'manitobahealthcardform.dob.month'
                                          )}
                                        </FormLabel>
                                        <Field name="dobMonth">
                                          {({ form, field }: FieldProps) => (
                                            <TextField
                                              fullWidth
                                              variant="outlined"
                                              margin="dense"
                                              type="text"
                                              error={
                                                getIn(
                                                  form.errors,
                                                  field.name
                                                ) &&
                                                getIn(form.touched, field.name)
                                              }
                                              placeholder={t(
                                                'manitobahealthcardform.dob.month.placeholder'
                                              )}
                                              inputProps={{
                                                maxLength: 2,
                                                inputMode: 'numeric',
                                              }}
                                              disabled={!editMode}
                                              {...field}
                                            />
                                          )}
                                        </Field>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <FormControl>
                                        <FormLabel>
                                          {t('manitobahealthcardform.dob.year')}
                                        </FormLabel>
                                        <Field name="dobYear">
                                          {({ form, field }: FieldProps) => (
                                            <TextField
                                              fullWidth
                                              variant="outlined"
                                              margin="dense"
                                              type="text"
                                              error={
                                                getIn(
                                                  form.errors,
                                                  field.name
                                                ) &&
                                                getIn(form.touched, field.name)
                                              }
                                              placeholder={t(
                                                'manitobahealthcardform.dob.year.placeholder'
                                              )}
                                              inputProps={{
                                                maxLength: 4,
                                                inputMode: 'numeric',
                                              }}
                                              disabled={!editMode}
                                              {...field}
                                            />
                                          )}
                                        </Field>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </FormControl>
                              </>
                            }
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      <Divider />
                      <FormControl style={{ padding: '0.5rem' }}>
                        <FormControlLabel
                          control={
                            <Field name="hasNoHealthCard">
                              {({ field, form }: FieldProps) => (
                                <Switch
                                  checked={field.value}
                                  onChange={(
                                    _event: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean
                                  ) => form.setFieldValue(field.name, checked)}
                                  color="primary"
                                  size="small"
                                  type="checkbox"
                                  disabled={!editMode}
                                />
                              )}
                            </Field>
                          }
                          label={
                            <Typography variant="body2" color="primary">
                              {t('manitobahealthcardform.nohealthcard')}
                            </Typography>
                          }
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}

                {!editMode ? (
                  <Grid item>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          onClick={() => setEditMode(true)}
                          variant="outlined"
                          color="primary"
                          className="smallButton"
                        >
                          {t('summary.editdetails')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </SinglePanelLayout>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default ScreenSummary;
