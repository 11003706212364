var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import { raam } from 'raam-client-lib';
import { createContext, useContext, useReducer, useState } from 'react';
import { initialSettings, settingsReducer, } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
export const StateContext = createContext(null);
/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
const AppStateProvider = ({ children }) => {
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [activeSinkId, setActiveSinkId] = useActiveSinkId();
    const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
    const [roomType /*, setRoomType */] = useState();
    const [name, setName] = useState('');
    const [room, setRoom] = useState('');
    const [isClinician, setIsClinician] = useState(false);
    const [pin, setPin] = useState('');
    const [captioningAllowed, setCaptioningAllowed] = useState(false);
    let contextValue = {
        error,
        name,
        setName,
        room,
        setRoom,
        isClinician,
        setIsClinician,
        captioningAllowed,
        setCaptioningAllowed,
        setError,
        isFetching,
        activeSinkId,
        setActiveSinkId,
        settings,
        dispatchSetting,
        roomType,
        pin,
        setPin,
        getToken: () => __awaiter(void 0, void 0, void 0, function* () {
            setIsFetching(true);
            return raam.twilio
                .getRoomToken(name, room, pin)
                .then((res) => {
                setIsFetching(false);
                return res.token;
            })
                .catch((err) => {
                setError({
                    code: 54000,
                    message: _.get(err, 'message', ''),
                    name: 'getToken',
                });
                setIsFetching(false);
                return Promise.reject(err);
            });
        }),
    };
    return (_jsx(StateContext.Provider, { value: contextValue, children: children }));
};
export function useAppState() {
    const context = useContext(StateContext);
    if (!context) {
        throw new Error('useAppState must be used within the AppStateProvider');
    }
    return context;
}
export default AppStateProvider;
