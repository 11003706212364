import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AudioLevelIndicator from "../../AudioLevelIndicator/AudioLevelIndicator";
import { FormControl, MenuItem, Typography, Select, Grid } from "@material-ui/core";
import { SELECTED_AUDIO_INPUT_KEY } from "../../../constants";
import useDevices from "../../../hooks/useDevices/useDevices";
import useMediaStreamTrack from "../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useWrappedTranslation } from "../../../utils/i18nService";
export default function AudioInputList() {
    const t = useWrappedTranslation();
    const { audioInputDevices } = useDevices();
    const { localTracks } = useVideoContext();
    const localAudioTrack = localTracks.find(track => track.kind === "audio");
    const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
    const localAudioInputDeviceId = mediaStreamTrack === null || mediaStreamTrack === void 0 ? void 0 : mediaStreamTrack.getSettings().deviceId;
    function replaceTrack(newDeviceId) {
        window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
        localAudioTrack === null || localAudioTrack === void 0 ? void 0 : localAudioTrack.restart({ deviceId: { exact: newDeviceId } });
    }
    return (_jsxs("div", { children: [_jsx(Typography, { variant: "subtitle2", gutterBottom: true, children: t("AudioInputList") }), _jsxs(Grid, { container: true, alignItems: "center", justifyContent: "space-between", spacing: 1, children: [_jsx(Grid, { item: true, xs: true, children: audioInputDevices.length > 1 ? (_jsx(FormControl, { fullWidth: true, children: _jsx(Select, { onChange: e => replaceTrack(e.target.value), value: localAudioInputDeviceId || "", variant: "outlined", children: audioInputDevices.map(device => (_jsx(MenuItem, { value: device.deviceId, children: device.label }, device.deviceId))) }) })) : (_jsx(Typography, { children: (localAudioTrack === null || localAudioTrack === void 0 ? void 0 : localAudioTrack.mediaStreamTrack.label) ||
                                t("AudioInputList.NoDevices") })) }), _jsx(Grid, { item: true, children: _jsx(AudioLevelIndicator, { audioTrack: localAudioTrack, color: "black" }) })] })] }));
}
