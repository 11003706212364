import _ from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

interface QueryParams {
  [key: string]: string;
}

function useValidateQueryParams(params: QueryParams): Boolean {
  const location = useLocation();

  const validParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return _.every(
      Object.keys(params),
      (key: string) => searchParams.get(key) === params[key]
    );
  }, [location.search, params]);

  return validParams;
}

export default useValidateQueryParams;
