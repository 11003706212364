import { useState } from 'react';

const registerPromise = <T>(
  setValue: (value: T) => void,
  promise?: Promise<T>,
  silentErrors?: boolean
) => {
  if (promise) {
    promise.then(setValue, (err) => {
      if (!silentErrors) {
        throw err;
      }
    });
  }
};

const usePromise = <T>(
  initialValue: T,
  promise?: Promise<T>,
  silentErrors?: boolean
) => {
  const [value, setValue] = useState<T>(initialValue);
  registerPromise(setValue, promise, silentErrors);
  return value;
};

const useMaybePromise = <T>(promise?: Promise<T>, silentErrors?: boolean) => {
  const [value, setValue] = useState<T>();
  registerPromise(setValue, promise, silentErrors);
  return value;
};

export { usePromise, useMaybePromise };
