import React from 'react';
import _ from 'lodash';
import linkify from 'linkify-it';
import { Link } from '@material-ui/core';

import './ChatMessage.scss';
import { MessageView, MessageDirection } from '../../../Services/Chat';
import ChatAvatar from '../ChatAvatar/ChatAvatar';

interface ChatMessageProps {
  message: MessageView;
}

function addLinks(text: string) {
  const matches = linkify().match(text);
  if (!matches) {
    return [text];
  }

  let lastIndex = 0;
  const results = _.flatMap(matches, (match, i) => {
    const rv = [
      text.slice(lastIndex, match.index),
      <Link target="_blank" rel="noreferrer" href={match.url} key={i}>
        {match.text}
      </Link>,
    ];

    lastIndex = match.lastIndex;
    return rv;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}

const messageHasInitial = (s: string): boolean => _.get(s, 2) === '|';
const extractInitial = (s: string): string =>
  messageHasInitial(s) ? _.join(_.take(s, 2), '') : '';
const removeMaybeInitial = (s: string): string =>
  messageHasInitial(s) ? _.join(_.slice(s, 3), '') : s;

function ChatMessage({ message: { direction, message } }: ChatMessageProps) {
  const className =
    direction === MessageDirection.Received ? 'received' : 'sent';

  return (
    <div className={`chat-message ${className}`}>
      <ChatAvatar initial={extractInitial(message)} />
      <div className="message-bubble">
        {addLinks(removeMaybeInitial(message))}
      </div>
    </div>
  );
}

export default ChatMessage;
