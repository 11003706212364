import _ from 'lodash';
import { ConnectionService } from 'raam-client-lib';
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMaybeObservable } from '../Hooks';
import useInstancesByClassName from '../Hooks/useInstanceByClassName';
import IAppState, { AppStates } from '../Types/IAppState';

interface ITranslatedMsg {
  en: string;
  fr: string;
}

interface ICombinedMsg {
  primary: ITranslatedMsg;
  secondary: ITranslatedMsg;
  displayCheckInHours: boolean;
}

interface IPrimarySecondaryMsg {
  primary: ITranslatedMsg;
  secondary: ITranslatedMsg;
}

export interface IApplicationConfig {
  msgCheckinHours: ITranslatedMsg;
  msgOpen: ICombinedMsg;
  msgClosed: ICombinedMsg;
  msgBreak: ICombinedMsg;
  msgForcedClose: ICombinedMsg;
  msgEjectedFromQueue: ITranslatedMsg;
  msgWaiting: ITranslatedMsg;
  msgVisitComplete: IPrimarySecondaryMsg;
  msgSubstanceUnsupported: ITranslatedMsg & {
    showPhone: boolean;
    showEmail: boolean;
  };
  usePIN?: boolean;
  captioningAllowed: boolean;
  isLoaded: boolean;
}

export interface IDirtyText {
  fr: string;
  en: string;
  dirty: boolean;
}

export interface IPhoneNumber {
  number: string;
  extension?: number;
}

export interface IClinicConfig {
  email: string;
  phoneNo: string;
  phoneNoExt: string;
  displayName: string;
  regionNameEn: string;
  regionNameFr: string;
  regionTextEn: string;
  regionTextFr: string;
  mapName: string;
  logoName: string;
  enableMapScreen: boolean;
  enableMoreRegionInfoScreen: boolean;
  includeRegionMap: boolean;
  mapPreview?: {
    name: string;
    id: string;
  };
  adminContactName: string;
  adminAddress: string;
  adminEmail: string;
  adminPhone: IPhoneNumber[];

  regionName: IDirtyText;
  clinicName: IDirtyText;

  accessRAAM: boolean;

  isLoaded: boolean;
}

export interface ILoadableAppState extends IAppState {
  isLoaded: boolean;
}

const DEFAULT_COMBINED_MSG: ICombinedMsg = {
  primary: { en: '', fr: '' },
  secondary: { en: '', fr: '' },
  displayCheckInHours: false,
};

const DEFAULT_PRIMARY_SECONDARY_MSG: IPrimarySecondaryMsg = {
  primary: { en: '', fr: '' },
  secondary: { en: '', fr: '' },
};

const DEFAULT_TRANSLATED_MSG: ITranslatedMsg = {
  en: '',
  fr: '',
};

const getDefaultApplicationConfig = (): IApplicationConfig => ({
  msgCheckinHours: DEFAULT_TRANSLATED_MSG,
  msgOpen: DEFAULT_COMBINED_MSG,
  msgClosed: DEFAULT_COMBINED_MSG,
  msgBreak: DEFAULT_COMBINED_MSG,
  msgForcedClose: DEFAULT_COMBINED_MSG,
  msgEjectedFromQueue: DEFAULT_TRANSLATED_MSG,
  msgWaiting: DEFAULT_TRANSLATED_MSG,
  msgVisitComplete: DEFAULT_PRIMARY_SECONDARY_MSG,
  msgSubstanceUnsupported: {
    ...DEFAULT_TRANSLATED_MSG,
    showEmail: true,
    showPhone: true,
  },
  captioningAllowed: false,
  isLoaded: false,
});

const EMPTY_TEXT: IDirtyText = {
  fr: '',
  en: '',
  dirty: false,
};

const getDefaultClinicConfig = () => ({
  email: '',
  phoneNo: '',
  phoneNoExt: '',
  displayName: '',
  regionNameEn: '',
  regionNameFr: '',
  regionTextEn: '',
  regionTextFr: '',
  mapName: '',
  logoName: '',
  enableMapScreen: false,
  enableMoreRegionInfoScreen: false,
  includeRegionMap: false,
  mapPreview: {
    name: '',
    id: '',
  },
  adminContactName: '',
  adminAddress: '',
  adminEmail: '',
  adminPhone: [],

  regionName: _.clone(EMPTY_TEXT),
  clinicName: _.clone(EMPTY_TEXT),

  accessRAAM: true,

  isLoaded: false,
});

const getDefaultAppState = () => ({
  state: AppStates.CLOSED,
  isLoaded: false,
});

const getDefaultDataContext = () => ({
  applicationConfig: getDefaultApplicationConfig(),
  clinicConfig: getDefaultClinicConfig(),
  appState: getDefaultAppState(),
  isLoaded: false,
});

export interface IDataContext {
  applicationConfig: IApplicationConfig;
  clinicConfig: IClinicConfig;
  appState: ILoadableAppState;
  isLoaded: boolean;
}

const DataContext = createContext<IDataContext>(getDefaultDataContext());

export default DataContext;

export function DataContextProvider({
  children,
}: PropsWithChildren<any>): JSX.Element {
  const connection: any = useMaybeObservable(ConnectionService.connection$);
  const configs = useInstancesByClassName<any>(connection, 'raam', 'config');
  const applicationConfig = useMemo(
    () => _.get(configs, `0.properties`, {}),
    [configs]
  ) as any;

  const [dataContext, setDataContext] = useState<IDataContext>(
    getDefaultDataContext
  );

  useEffect(() => {
    if (!_.isEmpty(applicationConfig)) {
      setDataContext((d) => ({
        ...d,
        applicationConfig: {
          ...d.applicationConfig,
          isLoaded: true,
          ..._.pick(applicationConfig, [
            'msgCheckinHours',
            'msgOpen',
            'msgClosed',
            'msgBreak',
            'msgForcedClose',
            'msgEjectedFromQueue',
            'msgWaiting',
            'msgVisitComplete',
            'msgSubstanceUnsupported',
            'usePIN',
            'captioningAllowed',
            'logo',
          ]),
        },
      }));
    }
  }, [applicationConfig]);

  const clinics = useInstancesByClassName<any>(connection, 'raam', 'clinic');

  const clinicConfig = useMemo(() => {
    let cc = _.get(clinics, `0.properties`, {}) as IClinicConfig;

    // The french regionName is not mandatory so using the english one when none is defined
    if (cc?.regionName) {
      cc.regionName.fr = cc.regionName.fr || cc.regionName.en;
    }

    return cc;
  }, [clinics]) as any;

  const defaultURL = clinicConfig.logoName
    ? `/logos/${clinicConfig.logoName}.svg`
    : undefined;
  const logoId = _.get(clinicConfig, 'logo.id') || null;
  const [logoUrl, setLogoUrl] = useState<undefined | string>(undefined);

  useEffect(() => {
    if (!connection?.bee) {
      return;
    }
    if (!logoId) {
      setLogoUrl(defaultURL);
      return;
    }

    connection.bee.blob
      .download(logoId)
      .then((blob: { content: BlobPart; contentType: any }) => {
        const jsblob = new Blob([blob.content], {
          type: blob.contentType,
        });

        setLogoUrl(URL.createObjectURL(jsblob));
      });
  }, [connection, logoId, clinicConfig, defaultURL]);

  useEffect(() => {
    if (!_.isEmpty(clinicConfig)) {
      setDataContext((d) => ({
        ...d,
        clinicConfig: {
          ...d.clinicConfig,
          isLoaded: true,
          ...clinicConfig,
          logoName: logoUrl,
        },
      }));
    }
  }, [clinicConfig, logoUrl]);

  const appStates = useInstancesByClassName<any>(
    connection,
    'raam',
    'appState'
  );
  const appState = useMemo<ILoadableAppState | undefined>(
    () => _.get(appStates, `0.properties`, undefined),
    [appStates]
  );

  useEffect(() => {
    if (!_.isEmpty(appState)) {
      setDataContext((d) => ({
        ...d,
        appState: { ...d.appState, state: appState.state, isLoaded: true },
      }));
    }
  }, [appState]);

  useEffect(() => {
    if (
      !dataContext.isLoaded &&
      dataContext.applicationConfig.isLoaded &&
      dataContext.clinicConfig.isLoaded &&
      dataContext.appState.isLoaded
    ) {
      setDataContext((d) => ({ ...d, isLoaded: true }));
    }
  }, [
    dataContext.isLoaded,
    dataContext.applicationConfig.isLoaded,
    dataContext.clinicConfig.isLoaded,
    dataContext.appState.isLoaded,
  ]);

  return (
    <DataContext.Provider value={dataContext}>{children}</DataContext.Provider>
  );
}
