import React from 'react';
import { Avatar } from '@material-ui/core';
import './ChatAvatar.scss';
import InQStyleGuide from '../../../constants';

export enum AvatarSize {
  Big = 'big',
  Small = 'small',
}

const DEFAULT_AVATAR_SIZE = AvatarSize.Big;

function ChatAvatar({
  initial,
  size = DEFAULT_AVATAR_SIZE,
}: {
  initial: string;
  size?: AvatarSize;
}) {
  return (
    <Avatar
      className={`chat-avatar ${size}`}
      sizes={size}
      alt={initial}
      style={{
        color: '#454545',
        backgroundColor: '#F1F1F1',
        border: `1px solid ${InQStyleGuide.blue.medium}`,
      }}
    >
      {initial}
    </Avatar>
  );
}

export default ChatAvatar;
