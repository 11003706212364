export interface FeatureFlags {
  [key: string]: boolean;
}

export function generateFeatureFlags() {
  const featureFlags: FeatureFlags = {};

  // Loop over the environment variables

  let env = {
    ...process.env,
    ...(window as any)._env_,
  };
  for (const key in env) {
    if (key.startsWith('REACT_APP_')) {
      // Convert the environment variable value to a boolean
      featureFlags[key] = env[key] === 'true';
    }
  }

  return featureFlags;
}
