import { useState, useEffect } from 'react';

const getLocalStorageOrDefault = (key: string, defaultValue: any) => {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  try {
    return JSON.parse(stored);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Invalid JSON object in localStorage, ignoring...');
  }

  return defaultValue;
};

export default function useLocalStorage(key: string, defaultValue: any) {
  const [value, setValue] = useState(
    getLocalStorageOrDefault(key, defaultValue)
  );

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
}
