/* eslint-disable no-console */
import moment from 'moment';

import { ConnectionService } from 'raam-client-lib';
import { createSyncStorageInterface, SyncStorage } from './Storage/SyncStorage';

export const connection = ConnectionService.connection$;

const KEY_CREDS_ADMISSION = 'hive-admission-app';
const KEY_CREDS_CLIENT = 'hive-client-app';

/* eslint-disable-next-line no-underscore-dangle */
const HIVE_HOST = (window as any)?._env_?.REACT_APP_HIVE_HOST;
/* eslint-disable-next-line no-underscore-dangle */
const HIVE_ORG_ID = (window as any)?._env_?.REACT_APP_HIVE_ORG_ID;
/* eslint-disable-next-line no-underscore-dangle */
const HIVE_APP_ID = (window as any)?._env_?.REACT_APP_HIVE_APP_ID;

/* eslint-disable-next-line no-underscore-dangle,prettier/prettier */
const HIVE_EVERYBODY_BEE_NAME =
  (window as any)?._env_?.REACT_APP_HIVE_BEE_NAME ||
  '251F0D1F-F7FF-40F7-A16A-4F4DECCC88A9_HIVE_EVERYBODY_BEE_NAME';
/* eslint-disable-next-line no-underscore-dangle,prettier/prettier */
const HIVE_EVERYBODY_BEE_PASS =
  (window as any)?._env_?.REACT_APP_HIVE_BEE_PASS ||
  '251F0D1F-F7FF-40F7-A16A-4F4DECCC88A9_HIVE_EVERYBODY_BEE_PASS';

interface IConnection {
  token: {
    access_token: string;
    access_token_expiry: number;
  };
  username: string;
  bee: any;
}

export enum AppType {
  Admission,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Client,
}

interface IConnectionData {
  appType: AppType;
  rememberMe: boolean;
}

interface IStoredCredentials {
  username: string;
  token: string;
  expiration: number;
  appType: AppType;
  rememberMe: boolean;
}

const CONFIG = {
  host: HIVE_HOST,
  orgId: HIVE_ORG_ID,
  appId: HIVE_APP_ID,
  categories: ['core', 'storage', 'invocation'],
};

let connectionData: IConnectionData;

const localeStorageWrapper = createSyncStorageInterface('', 'localstorage');
const sessionStorageWrapper = createSyncStorageInterface('', 'sessionstorage');

function storeCredentials(
  newConnection: IConnection,
  appType: AppType,
  rememberMe: boolean
) {
  const creds: IStoredCredentials = {
    username: newConnection.username,
    token: newConnection.token.access_token,
    expiration: newConnection.token.access_token_expiry,
    appType,
    rememberMe,
  };

  const storage: SyncStorage = rememberMe
    ? localeStorageWrapper
    : sessionStorageWrapper;
  switch (appType) {
    case AppType.Admission:
      storage.setItem(KEY_CREDS_ADMISSION, creds);
      break;

    case AppType.Client:
      storage.setItem(KEY_CREDS_CLIENT, creds);
      break;

    default:
      console.error('Cannot store credentials without app type');
      break;
  }
}

async function clearStoredCredentials(appType: AppType) {
  switch (appType) {
    case AppType.Admission:
      await localeStorageWrapper.removeItem(KEY_CREDS_ADMISSION);
      await sessionStorageWrapper.removeItem(KEY_CREDS_ADMISSION);
      break;

    case AppType.Client:
      await localeStorageWrapper.removeItem(KEY_CREDS_CLIENT);
      await sessionStorageWrapper.removeItem(KEY_CREDS_CLIENT);
      break;

    default:
      console.error('Cannot clear credentials without app type');
      break;
  }
}

function getStoredCredentials(
  appType: AppType
): IStoredCredentials | undefined {
  let data: IStoredCredentials | undefined;
  switch (appType) {
    case AppType.Admission:
      data =
        localeStorageWrapper.getItem(KEY_CREDS_ADMISSION) ||
        sessionStorageWrapper.getItem(KEY_CREDS_ADMISSION);
      break;

    case AppType.Client:
      data =
        localeStorageWrapper.getItem(KEY_CREDS_CLIENT) ||
        sessionStorageWrapper.getItem(KEY_CREDS_CLIENT);
      break;

    default:
      console.error('Cannot get credentials without app type');
      break;
  }

  return data;
}

function validateStoredCredentials(appType: AppType) {
  const data: IStoredCredentials | undefined = getStoredCredentials(appType);
  if (!data) {
    return undefined;
  }

  // Check if we have a stored token, if so, we don't need the login screen
  const token = {
    access_token: data.token,
    access_token_expiry: data.expiration,
  };
  const { username } = data;
  const { rememberMe } = data;
  const expiration = moment(token.access_token_expiry * 1000);
  const now = moment();

  if (token && expiration.isAfter(now)) {
    return { username, token, rememberMe };
  }

  return undefined;
}

/**
 * @description Attempts to connect using stored token
 * @returns {Promise<Object>} - a promise that resolves to the initial or current connection object.
 */
export function connectWithStoredCredentials(appType: AppType): Promise<any> {
  const storedCredentials = validateStoredCredentials(appType);
  if (storedCredentials) {
    console.debug('Attempting to connect with stored credentials');
    connectionData = { appType, rememberMe: storedCredentials.rememberMe };
    return ConnectionService.initBee(
      CONFIG,
      storedCredentials.username,
      storedCredentials.token
    ).catch((error: any) => {
      console.error(
        'Could not connect with stored credentials, reconnecting...',
        error
      );
      clearStoredCredentials(appType);

      throw error;
    });
  }

  return Promise.reject(new Error('No valid stored credentials'));
}

/**
 * @description Connects to Hive based on environment, if applicable.
 * @returns {Promise<Object>} - a promise that resolves to the initial or current connection object.
 */
export function connectAsClient() {
  const args = [CONFIG, HIVE_EVERYBODY_BEE_NAME, HIVE_EVERYBODY_BEE_PASS];
  console.debug('Attempting to sign in as bee', CONFIG);
  connectionData = { appType: AppType.Client, rememberMe: true };
  return ConnectionService.signInAsBee(...args);
}

let skipCount = 0;
ConnectionService.connection$.subscribe((conn?: IConnection) => {
  if (skipCount === 0) {
    // skip from rxjs doesn't exist in rxjs 6
    return;
  }
  skipCount += 1;

  if (conn) {
    storeCredentials(conn, connectionData.appType, connectionData.rememberMe);
  } else {
    clearStoredCredentials(connectionData.appType);
  }
});

export function assertConnection(): any {
  let assertedConnection;
  ConnectionService.connection$
    .subscribe((conn: any) => {
      assertedConnection = conn;
    })
    .unsubscribe();
  if (!connection) {
    throw new Error('Connection is needed!');
  }

  return assertedConnection;
}

export function disconnect() {
  clearStoredCredentials(connectionData.appType);
  ConnectionService.releaseBee();
}

function createNewConnection() {
  return connectWithStoredCredentials(AppType.Client).catch(() =>
    connectAsClient()
  );
}

export function initializeHiveConnection() {
  return createNewConnection();
}

export default connection;
