import { CircularProgress, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  i18n_enJson as TwilioEn,
  i18n_frJson as TwilioFr,
  TwilioAppStateProvider,
} from 'twilio-client';
import App from './App/App';
import ErrorBoundary from './Components/ErrorBoundary';
import { DataContextProvider } from './Contexts/DataContext';
import useAsync, { AsyncStatus } from './Hooks/useAsync';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import './index.scss';
import { initializeHiveConnection } from './Services/HiveService';
import { init as initI18n } from './Services/i18nService';
import { getTheme, init as initThemes } from './Services/ThemeService';
import * as serviceWorker from './serviceWorker';
import DarkTheme from './Themes/darkTheme';
import LightTheme from './Themes/lightTheme';

initThemes(LightTheme, DarkTheme);
initI18n(
  { translation: { ...en.translation, ...TwilioEn.translation } },
  { translation: { ...fr.translation, ...TwilioFr.translation } }
);

const onError = () => <>Sorry, Something went wrong</>;

const Root = () => {
  const initializeHiveConnectionAsync = useAsync(initializeHiveConnection);

  // Ensure this is called only once at the app bootUp
  useEffect(() => {
    initializeHiveConnectionAsync();
  }, [initializeHiveConnectionAsync]);

  return _.includes(
    [AsyncStatus.DONE, AsyncStatus.ERROR],
    initializeHiveConnectionAsync.status
  ) ? (
    <>
      <CssBaseline />
      <ErrorBoundary fallback={onError}>
        <Suspense fallback={<CircularProgress />}>
          <DataContextProvider>
            <TwilioAppStateProvider>
              <App />
            </TwilioAppStateProvider>
          </DataContextProvider>
        </Suspense>
      </ErrorBoundary>
    </>
  ) : (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={getTheme()}>
      <Root />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
