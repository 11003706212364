import { useCallback, useState } from 'react';
import { ContentObserver, Instance } from 'hive-client-utils';
import { EMPTY } from 'rxjs';
import { useMaybeObservable } from '.';

export interface IObserveOptions {
  debounce?: boolean;
  debounceWait?: number;
}

export default function useInstancesByClassName<T extends Instance>(
  connection: any,
  namespace: string,
  klassName: string,
  options: IObserveOptions = { debounce: false }
): T[] | undefined {
  // Using options directly would cause a re-render loop. So we move to a state, so it will stabilize.
  // NOTE: the options can thus NOT be changed.
  const [optionsState] = useState(options);

  const instances$ = useCallback(
    () =>
      connection && connection.bee
        ? ContentObserver.observe<any>(
            connection.bee,
            namespace,
            klassName,
            optionsState
          )
        : EMPTY,
    [connection, klassName, namespace, optionsState]
  );

  return useMaybeObservable(instances$);
}
