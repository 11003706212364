const { Observable } = require('rxjs');
const { assertConnection, invokeApi, invoke } = require('./utils');

const getRoomConfig = invokeApi('raam.getRoomConfig');
const observeRoomConfig = (roomName) => {
  const {bee} = assertConnection();

  return new Observable((subscriber) => {
    let notificationId = '';
    getRoomConfig(roomName)
      .then((roomConfigI) => {
        notificationId = roomConfigI?.properties?.notificationId;
        subscriber.next(roomConfigI);
      });

    const callbackIds = bee.reactions.setCallback('invocation:roomConfigChange', (R) => {
      let rId = _.get(R.details, '0.notificationId');
      if (notificationId === rId) {
        getRoomConfig(roomName).then(v => subscriber.next(v));
      }
    });

    return () => bee.reactions.removeCallback(callbackIds);
  });
};

module.exports = {
  getRoomToken: (userName, roomName, pin) => invokeApi('raam.getRoomToken')(userName, roomName, pin),
  observeRoomConfig,
  getAzureSpeechToken: (roomName) => invokeApi('raam.getAzureSpeechToken')(roomName),
  setCaptioningState: (roomName, participantName, captioning) => invokeApi('raam.setPaticipantCaptioningState')(roomName, participantName, captioning),
  setRoomLanguage: (roomName, languageCode) => invokeApi('raam.setRoomLanguage')(roomName, languageCode),
  removeRoomParticipant: (roomName, participantName) => invokeApi('raam.removeRoomParticipant')(roomName, participantName),
};
