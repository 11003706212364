import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  alpha,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useState } from 'react';
import { SinglePanelLayout } from '../../Components';
import useClinicConfig from '../../Hooks/useClinicConfig';
import {
  getLanguage,
  useExtendedTranslation,
} from '../../Services/i18nService';

import _ from 'lodash';
import { ClinicRegionDetails, RegionNames } from '../../Types';
import InQStyleGuide from '../../constants';
import {
  InterlakeMap,
  NoneMap,
  NorthernMap,
  PrairieMap,
  SouthernMap,
  WinnipegMap,
} from './maps';

const RegionToMap = {
  [RegionNames.None]: NoneMap,
  [RegionNames.Winnipeg]: WinnipegMap,
  [RegionNames.Interlake]: InterlakeMap,
  [RegionNames.Northern]: NorthernMap,
  [RegionNames.Southern]: SouthernMap,
  [RegionNames.Prairie]: PrairieMap,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0.25),
      top: theme.spacing(0.25),
      color: theme.palette.primary.main,
    },
    linkButton: {
      textAlign: 'center',
      textDecoration: 'underline',
    },
    mapPreview: {
      height: '100%',
      maxWidth: '100%',
      borderRadius: theme.spacing(1),
      border: '0.125rem solid rgba(255, 255, 255, 0)',
      '&:hover': {
        border: `0.125rem solid ${InQStyleGuide.blue.medium}`,
        cursor: 'pointer',
      },
    },
  })
);

function MapLightbox({
  open,
  handleClose,
  region,
  src,
}: {
  open: boolean;
  handleClose: () => void;
  region: RegionNames;
  src: string | undefined;
}) {
  const t = useExtendedTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: alpha(InQStyleGuide.white, 0.75),
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label={t('questionnaire.close')}
          className={classes.closeButton}
          onClick={handleClose}
        >
          <HighlightOffIcon />
        </IconButton>
      </DialogTitle>
      <img
        src={src}
        alt={region}
        style={{
          width: '100%',
          maxHeight: '100%',
          marginTop: 'auto',
          marginBottom: 'auto',
          paddingLeft: '1rem',
        }}
        onClick={handleClose}
      />
    </Dialog>
  );
}

function RegionLabel({ name, details }: { name: string; details: string }) {
  return (
    <Typography>
      <b>{name}</b>
      {` (${details})`}
    </Typography>
  );
}

function RegionChoice({
  region,
  handleChange,
}: {
  region: RegionNames;
  handleChange: (event: any) => void;
}) {
  const lang = getLanguage();

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="region"
        name="region"
        value={region}
        onChange={handleChange}
      >
        <FormControlLabel
          value={RegionNames.Winnipeg}
          label={
            <RegionLabel
              name={RegionNames.Winnipeg}
              details={_.get(ClinicRegionDetails, ['Winnipeg', lang], '')}
            />
          }
          control={<Radio />}
        />
        <FormControlLabel
          value={RegionNames.Interlake}
          label={
            <RegionLabel
              name={RegionNames.Interlake}
              details={_.get(ClinicRegionDetails, ['Interlake', lang], '')}
            />
          }
          control={<Radio />}
        />
        <FormControlLabel
          value={RegionNames.Southern}
          label={
            <RegionLabel
              name={RegionNames.Southern}
              details={_.get(ClinicRegionDetails, ['Southern', lang], '')}
            />
          }
          control={<Radio />}
        />
        <FormControlLabel
          value={RegionNames.Prairie}
          label={
            <RegionLabel
              name={RegionNames.Prairie}
              details={_.get(ClinicRegionDetails, ['Prairie', lang], '')}
            />
          }
          control={<Radio />}
        />
        <FormControlLabel
          value={RegionNames.Northern}
          label={
            <RegionLabel
              name={RegionNames.Northern}
              details={_.get(ClinicRegionDetails, ['Northern', lang], '')}
            />
          }
          control={<Radio />}
        />
      </RadioGroup>
    </FormControl>
  );
}

export default function ManitobaRegion({
  onContinue,
}: {
  onContinue: Function;
}) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  const [region, setRegion] = useState<RegionNames>(RegionNames.None);
  const [openLightbox, setOpenLightbox] = useState(false);

  const handleChange = (event: any) => {
    setRegion(event.target.value);
  };

  return (
    <Box display="flex" flex={1}>
      <SinglePanelLayout
        title={
          <Typography variant="h5" color="primary" align="center">
            {t('survey.manitobaregion.title')}
          </Typography>
        }
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={region === RegionNames.None}
                onClick={() => onContinue(region)}
              >
                {t('checkin.continue.button')}
              </Button>
            </Grid>
          </Grid>
        }
        logo={clinicConfig.logoName}
      >
        <Box height={'100%'}>
          <Box
            minHeight={'33ex'}
            maxWidth={'fit-content'}
            marginLeft={'auto'}
            marginRight={'auto'}
          >
            <RegionChoice region={region} handleChange={handleChange} />
          </Box>
          <Box height={'calc(100% - 33ex)'} textAlign={'center'}>
            {isSmall ? (
              <Link
                component="button"
                onClick={() => setOpenLightbox(true)}
                className={classes.linkButton}
              >
                <Typography>{t('questionnaire.map.unsure')}</Typography>
                <Typography>{t('questionnaire.map.click_here')}</Typography>
              </Link>
            ) : (
              <div
                className={classes.mapPreview}
                onClick={() => setOpenLightbox(true)}
              >
                <img
                  src={RegionToMap[region]}
                  alt={region}
                  style={{
                    height: 'calc(100% - 3ex)',
                    maxWidth: '100%',
                  }}
                />
                <Typography>{t('questionnaire.map.zoom')}</Typography>
              </div>
            )}
          </Box>
        </Box>
        <MapLightbox
          open={openLightbox}
          handleClose={() => setOpenLightbox(false)}
          region={region}
          src={RegionToMap[region]}
        />
      </SinglePanelLayout>
    </Box>
  );
}
