import _ from 'lodash';
import { Dict, MapFn } from '../../Util/util';

export interface HasId {
  id: string;
}

export interface Cache<T extends HasId> {
  get(id: string): T;
  all(): T[];
}

export function createObjectCache<T extends HasId>(): Cache<T> {
  const allItems: T[] = [];
  const byId: Dict<string, T> = {};
  const cache: Cache<T> = {
    get: (id: string) => {
      let obj = byId[id];
      if (!obj) {
        obj = { id } as any as T;
        byId[id] = obj;
        allItems.push(obj);
      }
      return obj;
    },
    all: () => allItems,
  };
  return cache;
}

export function mergeObjects<T extends any>(
  target: T,
  ...others: Partial<T>[]
): T {
  _.merge(target, ...others);
  return target;
}

export function arrayValueMap<T, T2>(mapFn: MapFn<T, T2>): MapFn<T[], T2[]> {
  return (items) => items.map(mapFn);
}

export function mapKeyMap<T, T2, V>(
  mapFn: MapFn<T, T2>
): MapFn<Map<T, V>, Map<T2, V>> {
  return (data) => {
    const result = new Map<T2, V>();
    data.forEach((v, k) => {
      result.set(mapFn(k), v);
    });
    return result;
  };
}

export function mergeArrays<T extends HasId>(target: T[], other: T[]): T[] {
  const orig = target || [];
  other.forEach((item) => {
    let toUpdate = orig.find((i) => i.id === item.id);
    if (!toUpdate) {
      toUpdate = {} as any;
      orig.push(toUpdate as T);
    }
    mergeObjects(toUpdate, item);
  });
  return orig;
}
