/* eslint-disable no-console */
import React, { useRef, useEffect, useCallback } from 'react';

function ScrollMagnet({ watch = '' }: { watch?: any }) {
  const elem = useRef<any>(null);

  const doScroll = useCallback(() => {
    try {
      if (!elem.current || !elem.current.scrollIntoView) {
        console.debug(
          '[ScrollMagnet] Scroll Element is no longer bound to the dom, ignoring attempt to scroll'
        );
        return;
      }
      elem.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    } catch (err) {
      console.error(
        '[ScrollMagnet] Error occured while trying to programatically scroll',
        err
      );
    }
  }, [elem]);

  useEffect(() => {
    doScroll();
  }, [watch, doScroll]);

  return <div className="scroll-magnet" ref={elem} />;
}

export default ScrollMagnet;
