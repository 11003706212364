import React from 'react';
import './FrostedGlassPanel.scss';
import { Grid, Box } from '@material-ui/core';

function FrostedGlassPanel({
  title,
  content = <></>,
  footer,
  color = '',
  disablePadding = false,
}: {
  title?: JSX.Element | JSX.Element[];
  content?: JSX.Element | JSX.Element[];
  footer?: JSX.Element | JSX.Element[];
  color?: String;
  disablePadding?: boolean;
}) {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item xs>
        <Box
          className={
            color === 'blue'
              ? 'frosted-glass-panel-blue'
              : 'frosted-glass-panel'
          }
          display="flex"
          height="100%"
        >
          <Grid container direction="column" wrap="nowrap">
            {title && (
              <Grid item>
                <Box paddingTop={2} paddingRight={2} paddingLeft={2}>
                  {title}
                </Box>
              </Grid>
            )}
            <Grid item xs container direction="column" wrap="nowrap">
              <Box
                overflow="auto"
                flexGrow={1}
                flexBasis={0}
                paddingTop={title || disablePadding ? 0 : 2}
                paddingRight={disablePadding ? 0 : 2}
                paddingLeft={disablePadding ? 0 : 2}
              >
                {content}
              </Box>
            </Grid>
            {footer && (
              <Grid item>
                <Box
                  padding={2}
                  boxShadow="rgba(0, 0, 0, 0.1) 0px -2px 1px -1px"
                >
                  {footer}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default FrostedGlassPanel;
