import React from 'react';
import './SevenSegmentDigit.scss';
import CountdownTimerSize from '../types';

function sevenSegmentClass(character: string) {
  /**
   *   a
   * f   b
   *   g
   * e   c
   *   d
   */
  switch (character) {
    case '0':
      return 'a b c d e f';
    case '1':
      return 'b c';
    case '2':
      return 'a b g e d';
    case '3':
      return 'a b g c d';
    case '4':
      return 'f g b c';
    case '5':
      return 'a f g c d';
    case '6':
      return 'a f e d c g';
    case '7':
      return 'a b c';
    case '8':
      return 'a b c d e f g';
    case '9':
      return 'g f a b c';
    case 'E':
    case 'e':
      return 'a d e f g';
    case 'R':
    case 'r':
      return 'e g';
    case '':
    case ' ':
      return '';
    default:
      // eslint-disable-next-line no-console
      console.warn('character not supported for 7 segment display', character);
      return '';
  }
}

/** *
 * Src: https://en.wikipedia.org/wiki/File:7-segment_abcdefg.svg
 * License: Public Domain
 */
function SevenSegmentDigit({
  character,
  size = CountdownTimerSize.NORMAL,
}: {
  character: string | number;
  size?: CountdownTimerSize;
}) {
  const firstChar = `${character} `[0];
  const cssClass = `${sevenSegmentClass(firstChar)} ${size}`;

  return (
    <div className={`seven-segment-display ${cssClass}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 20">
        <g id="abcdefg">
          <path
            id="a"
            d="M965.34,529.76a1.67,1.67,0,0,1,1.13.42l-1.72,1.58h-6.48l-.13,0-1.42-1.3a2,2,0,0,1,1.36-.71h7.26Z"
            transform="translate(-953.99 -529.76)"
          />
          <path
            id="b"
            d="M967,531.41l0,.14-.93,6.69-.73.73-.76-.65.93-6.31,1.39-1.28A1.55,1.55,0,0,1,967,531.41Z"
            transform="translate(-953.99 -529.76)"
          />
          <path
            id="c"
            d="M965.36,539.88l.41.35-1.11,7.93a1.4,1.4,0,0,1-.15.49l-1.29-1.36.92-6.19Z"
            transform="translate(-953.99 -529.76)"
          />
          <path
            id="d"
            d="M962.76,547.76l1.36,1.44a2,2,0,0,1-1.22.56h-7.26a1.63,1.63,0,0,1-1.08-.37l1.34-1.63h6.86Z"
            transform="translate(-953.99 -529.76)"
          />
          <path
            id="e"
            d="M956,540.23l.54.53h0l-1,6.38-1.41,1.72a1.54,1.54,0,0,1-.15-.74l0-.14.93-6.69Z"
            transform="translate(-953.99 -529.76)"
          />
          <path
            id="f"
            d="M956.4,531.05l1.35,1.24-.91,6.14-.88.87-.66-.65,1-7.28A1.25,1.25,0,0,1,956.4,531.05Z"
            transform="translate(-953.99 -529.76)"
          />
          <polygon
            id="g"
            points="10.08 9 10.85 9.67 9.51 11 3.44 11 2.46 10.02 3.48 9 10.08 9"
          />
        </g>
      </svg>
    </div>
  );
}

export default SevenSegmentDigit;
