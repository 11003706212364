export enum AppStates {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  BREAK = 'BREAK',
  FORCECLOSE = 'FORCECLOSE',
}

interface IAppState {
  state: AppStates;
}

export default IAppState;
