import React from 'react';
import './ChatLog.scss';
import ChatMessage from '../ChatMessage/ChatMessage';
import ChatSystemMessage from '../ChatSystemMessage/ChatSystemMessage';
import { useMaybeObservable } from '../../../Hooks';
import ScrollMagnet from '../ScrollMagnet';

import { ChatView, MessageView, ParticipantType } from '../../../Services/Chat';

function DisplayMessage({ message }: { message: MessageView }) {
  return message.author.type === ParticipantType.System ? (
    <ChatSystemMessage message={message} />
  ) : (
    <ChatMessage message={message} />
  );
}

function ChatLog({ chat }: { chat: ChatView }) {
  const messages = useMaybeObservable(chat.messages);

  return (
    <div className="chat-log">
      {messages !== undefined &&
        messages.map((message) => {
          return <DisplayMessage message={message} />;
        })}

      <ScrollMagnet watch={messages} />
    </div>
  );
}

export default ChatLog;
