/* eslint-diable */
/* eslint-disable react/jsx-props-no-spreading, react/jsx-no-duplicate-props */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { FastField, Field, FieldProps, Form, Formik, getIn } from 'formik';
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import {
  FormikErrorSnackbar,
  NumberFormatPhone,
  SinglePanelLayout,
} from '../../../Components';
import useClinicConfig from '../../../Hooks/useClinicConfig';
import { useFeatureFlags } from '../../../Hooks/useFeatureFlags';
import { useExtendedTranslation } from '../../../Services/i18nService';
import { ISurveyData } from '../../../Types';
import { PHONE_REG_EXP } from '../../../Util/phoneValid';
import {
  USER_ADDRESS_ENTRY_REGEX,
  USER_ENTRY_REGEX,
  areAllValuesTruthy,
} from '../../../Util/util';
import MoreInfoIcon from '../icons/MoreInfo.svg';
import './ScreenCheckIn.scss';

const ScreenCheckIn = ({
  onNext,
  onRegion,
  userData,
}: {
  onNext: Function;
  onRegion: Function;
  userData: ISurveyData;
}) => {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();

  const { REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION } = useFeatureFlags();

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(USER_ENTRY_REGEX, t('checkin.firstName.error'))
      .max(128, t('checkin.firstName.error'))
      .required(t('checkin.firstName.error')),
    lastName: Yup.string()
      .matches(USER_ENTRY_REGEX, t('checkin.lastName.error'))
      .max(128, t('checkin.lastName.error'))
      .required(t('checkin.lastName.error')),
    email: Yup.string()
      .email(t('checkin.email.error'))
      .matches(USER_ENTRY_REGEX, t('checkin.email.error'))
      .required(t('checkin.email.error')),
    phoneNumber: Yup.string()
      .matches(PHONE_REG_EXP, t('checkin.phone.error'))
      .matches(USER_ENTRY_REGEX, t('checkin.phone.error'))
      .min(10, t('checkin.phone.error'))
      .required(t('checkin.phone.error')),
    currentAddress: REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION
      ? Yup.string()
      : Yup.string()
          .matches(USER_ADDRESS_ENTRY_REGEX, t('checkin.address.error'))
          .max(128, t('checkin.address.error'))
          .required(t('checkin.address.error')),
    inRegion: Yup.boolean()
      .oneOf([true, false], t('checkin.inRegion.error'))
      .required(t('checkin.inRegion.error')),
  });

  return (
    <Box display="flex" flex={1} className="screen-check-in">
      <Formik
        initialValues={userData}
        validationSchema={validationSchema}
        onSubmit={(values) =>
          onNext({
            ...values,
            inRegion: clinicConfig.enableMapScreen ? values.inRegion : true,
          })
        }
        enableReinitialize
      >
        {({ values, isSubmitting, isValidating, submitForm }) => (
          <Form className="formLayout">
            <FormikErrorSnackbar />
            <SinglePanelLayout
              ariaLive="off"
              footer={
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        isSubmitting ||
                        isValidating ||
                        areAllValuesTruthy(
                          _.pick(values, [
                            'firstName',
                            'lastName',
                            'email',
                            'phoneNumber',
                            REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION
                              ? ''
                              : 'currentAddress',
                            clinicConfig.enableMapScreen ? 'inRegion' : '',
                          ])
                        )
                      }
                      onClick={submitForm}
                    >
                      {t('checkin.continue.button')}
                    </Button>
                  </Grid>
                </Grid>
              }
              logo={clinicConfig.logoName}
            >
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography className="description">
                    {t('checkin.description1')}
                  </Typography>
                  <Typography className="description">
                    {t('checkin.description2')}
                  </Typography>
                </Grid>

                <Grid item>
                  <FormControl>
                    <FormLabel required>{t('checkin.firstName')}</FormLabel>
                    <FastField name="firstName">
                      {({ form, field }: FieldProps) => (
                        <TextField
                          fullWidth
                          error={
                            getIn(form.errors, field.name) &&
                            getIn(form.touched, field.name)
                          }
                          variant="outlined"
                          margin="dense"
                          inputProps={{ maxLength: 128 }}
                          {...field}
                        />
                      )}
                    </FastField>
                  </FormControl>

                  <FormControl>
                    <FormLabel required>{t('checkin.lastName')}</FormLabel>
                    <FastField name="lastName">
                      {({ form, field }: FieldProps) => (
                        <TextField
                          fullWidth
                          error={
                            getIn(form.errors, field.name) &&
                            getIn(form.touched, field.name)
                          }
                          variant="outlined"
                          margin="dense"
                          inputProps={{ maxLength: 128 }}
                          {...field}
                        />
                      )}
                    </FastField>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl>
                    <FormLabel required>{t('checkin.phone')}</FormLabel>
                    <FastField name="phoneNumber">
                      {({ form, field }: FieldProps) => (
                        <TextField
                          fullWidth
                          error={
                            getIn(form.errors, field.name) &&
                            getIn(form.touched, field.name)
                          }
                          variant="outlined"
                          margin="dense"
                          InputProps={{
                            inputComponent: NumberFormatPhone,
                          }}
                          inputProps={{ maxLength: 128 }}
                          {...field}
                          onChange={(val) =>
                            form.setFieldValue(field.name, val.target.value)
                          }
                        />
                      )}
                    </FastField>
                  </FormControl>

                  <FormControl style={{ marginLeft: '6px' }}>
                    <FormControlLabel
                      control={
                        <FastField name="allowSMS">
                          {({ field, form }: FieldProps) => (
                            <Switch
                              checked={field.value}
                              onChange={(
                                _event: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean
                              ) => form.setFieldValue(field.name, checked)}
                              color="primary"
                              size="small"
                              type="checkbox"
                            />
                          )}
                        </FastField>
                      }
                      label={<Typography>{t('checkin.allowSMS')}</Typography>}
                    />
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl>
                    <FormLabel required>{t('checkin.email')}</FormLabel>
                    <FastField name="email">
                      {({ form, field }: FieldProps) => (
                        <TextField
                          fullWidth
                          type="email"
                          error={
                            getIn(form.errors, field.name) &&
                            getIn(form.touched, field.name)
                          }
                          variant="outlined"
                          margin="dense"
                          inputProps={{ maxLength: 128 }}
                          {...field}
                        />
                      )}
                    </FastField>
                  </FormControl>
                </Grid>

                {clinicConfig.enableMapScreen && (
                  <Grid item>
                    <FormGroup style={{ marginLeft: '6px' }}>
                      <FormControlLabel
                        control={
                          <FastField name="inRegion">
                            {({ field, form }: FieldProps) => (
                              <Switch
                                checked={field.value}
                                onChange={(
                                  _event: React.ChangeEvent<HTMLInputElement>,
                                  checked: boolean
                                ) => form.setFieldValue(field.name, checked)}
                                color="primary"
                                size="small"
                                type="checkbox"
                              />
                            )}
                          </FastField>
                        }
                        label={
                          <Grid
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" color="primary">
                                {t('checkin.region', { ...clinicConfig })}
                              </Typography>
                            </Grid>
                            {clinicConfig.enableMoreRegionInfoScreen && (
                              <Grid item>
                                <IconButton
                                  onClick={() => onRegion(values)}
                                  size="small"
                                >
                                  <img
                                    src={MoreInfoIcon}
                                    alt={t('checkin.alt')}
                                  />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        }
                      />
                    </FormGroup>
                  </Grid>
                )}

                <Grid item>
                  <FormControl>
                    <FormLabel
                      required={!REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION}
                    >
                      {t('checkin.address')}
                      {REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION && (
                        <>
                          <br />
                          <Typography style={{ fontSize: '12px' }}>
                            {t('checkin.address.why')}
                          </Typography>
                        </>
                      )}
                    </FormLabel>
                    <Field name="currentAddress">
                      {({ form, field }: FieldProps) => (
                        <TextField
                          placeholder={t('provideInfo.homeAddress.placeholder')}
                          fullWidth
                          error={
                            getIn(form.errors, field.name) &&
                            getIn(form.touched, field.name)
                          }
                          variant="outlined"
                          multiline
                          minRows={3}
                          margin="dense"
                          inputProps={{ maxLength: 128 }}
                          {...field}
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormGroup style={{ marginLeft: '6px' }}>
                    <FormControlLabel
                      control={
                        <FastField name="rememberMe">
                          {({ field, form }: FieldProps) => (
                            <Switch
                              checked={field.value}
                              onChange={(
                                _event: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean
                              ) => form.setFieldValue(field.name, checked)}
                              color="primary"
                              size="small"
                              type="checkbox"
                            />
                          )}
                        </FastField>
                      }
                      label={<Typography>{t('checkin.rememberMe')}</Typography>}
                      style={{ marginTop: '10px', marginBottom: '14px' }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </SinglePanelLayout>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default ScreenCheckIn;
