/* eslint-disable react/prop-types */
import {
  Badge,
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import { FC } from 'react';
import { useMaybePromise, useObservable } from '../Hooks';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { ChatView } from '../Services/Chat';
import { useExtendedTranslation } from '../Services/i18nService';

const ChatButton: FC<{
  chat?: Promise<ChatView>;
  onClick: () => void;
}> = ({ chat, onClick }) => {
  const t = useExtendedTranslation();
  const theme = useTheme();
  const { width, height } = useWindowDimensions();
  const isDownSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const chatUnwrapped = useMaybePromise(chat);
  const hasUnreadMessages = useObservable(
    false,
    chatUnwrapped?.hasUnreadMessages
  );
  const label = hasUnreadMessages ? t('chat.button.unread') : t('chat.button');

  return (
    <Tooltip
      title={label}
      placement={isDownSmall && width < height ? 'left' : 'bottom'}
    >
      <Box boxShadow={2} borderRadius={20}>
        <IconButton
          onClick={onClick}
          style={{
            backgroundColor: theme.palette.grey[600],
            color: theme.palette.getContrastText(theme.palette.grey[600]),
            padding: '6px',
          }}
          aria-label={label}
        >
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
            invisible={!hasUnreadMessages}
            color="primary"
          >
            <SmsOutlinedIcon />
          </Badge>
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default ChatButton;
