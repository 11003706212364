import React, { useEffect } from 'react';
import {
  useMaybeObservable,
  useMaybePromise,
  usePageVisibility,
} from '../../../Hooks';
import { ChatView, MessageView } from '../../../Services/Chat/chat.model';
import ErrorBoundary from '../../ErrorBoundary';

function BehaviourMarkAllAsReadInternal({
  chat,
  enabled,
}: {
  chat?: Promise<ChatView>;
  enabled: boolean;
}) {
  const chatUnwrapped = useMaybePromise(chat);
  const isVisible = usePageVisibility();
  const unreadMessages = useMaybeObservable(chatUnwrapped?.unreadMessages);

  useEffect(() => {
    if (!unreadMessages || !chatUnwrapped || !enabled || !isVisible) {
      return;
    }
    unreadMessages.forEach((message: MessageView) => {
      chatUnwrapped.markRead(message);
    });
  }, [chatUnwrapped, unreadMessages, enabled, isVisible]);
  return <></>;
}

function empty() {
  return <></>;
}

function BehaviourMarkAllAsRead({
  chat,
  enabled,
}: {
  chat?: Promise<ChatView>;
  enabled: boolean;
}) {
  return (
    <ErrorBoundary fallback={empty}>
      <BehaviourMarkAllAsReadInternal chat={chat} enabled={enabled} />
    </ErrorBoundary>
  );
}

export default BehaviourMarkAllAsRead;
