import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useEffect, useMemo, useState, } from 'react';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';
import AttachVisibilityHandler from './AttachVisibilityHandler/AttachVisibilityHandler';
// import useBackgroundSettings, {
//   BackgroundSettings
// } from "./useBackgroundSettings/useBackgroundSettings";
import useBackgroundBlur from './useBackgroundBlur/useBackgroundBlur';
import useHandleRoomDisconnection from './useHandleRoomDisconnection/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange';
import useRoom from './useRoom/useRoom';
import useScreenShareToggle from './useScreenShareToggle/useScreenShareToggle';
import { EventEmitter } from 'events';
export const VideoContext = createContext(null);
export function VideoProvider({ options, children, onError = () => { }, }) {
    const onErrorCallback = useCallback((error) => {
        console.log(`ERROR: ${error.message}`, error);
        onError(error);
    }, [onError]);
    const { localTracks, getLocalVideoTrack, getLocalAudioTrack, isAcquiringLocalTracks, removeLocalAudioTrack, removeLocalVideoTrack, getAudioAndVideoTracks, } = useLocalTracks();
    const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);
    const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError);
    // Register callback functions to be called on room disconnect.
    useHandleRoomDisconnection(room, onError, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare);
    useHandleTrackPublicationFailed(room, onError);
    useRestartAudioTrackOnDeviceChange(localTracks);
    const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] = useState(false);
    const videoTrack = localTracks.find((track) => !track.name.includes('screen') && track.kind === 'video');
    const [backgroundSettings, setBackgroundSettings] = useBackgroundBlur(videoTrack);
    const localTracksEventEmiter = useMemo(() => new EventEmitter(), []);
    useEffect(() => {
        const onLocalTracksDisconnected = (_, error) => {
            if (error) {
                onError(error);
            }
            removeLocalAudioTrack();
            removeLocalVideoTrack();
            if (isSharingScreen) {
                toggleScreenShare();
            }
        };
        localTracksEventEmiter.on('disconnected', onLocalTracksDisconnected);
        return () => {
            localTracksEventEmiter.off('disconnected', onLocalTracksDisconnected);
        };
    }, [
        isSharingScreen,
        localTracksEventEmiter,
        onError,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        toggleScreenShare,
    ]);
    return (_jsxs(VideoContext.Provider, { value: {
            localTracksEventEmiter,
            room,
            localTracks,
            isConnecting,
            onError: onErrorCallback,
            getLocalVideoTrack,
            getLocalAudioTrack,
            connect,
            isAcquiringLocalTracks,
            removeLocalVideoTrack,
            removeLocalAudioTrack,
            isSharingScreen,
            toggleScreenShare,
            getAudioAndVideoTracks,
            isBackgroundSelectionOpen,
            setIsBackgroundSelectionOpen,
            backgroundSettings,
            setBackgroundSettings,
        }, children: [_jsx(SelectedParticipantProvider, { room: room, children: children }), _jsx(AttachVisibilityHandler, {})] }));
}
