import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CountdownTimer, SinglePanelWithMessaging } from '../../Components';
import { usePageVisibility, useTimer } from '../../Hooks';
import {
  ackMeetingRequest,
  getCurrentDateTime,
  startMeeting,
} from '../../Services/PatronService';
import { useExtendedTranslation } from '../../Services/i18nService';
import { parseDateToISO } from '../../Util/date';
import './VisitTimer.scss';

function VisitTimer({ patron }: { patron: any }) {
  const t = useExtendedTranslation();
  const [loadTime, setLoadTime] = useState(new Date().toISOString());
  useEffect(() => {
    getCurrentDateTime().then((o: any) =>
      setLoadTime((prev) => _.get(o, ['details', 'message'], prev))
    );
  }, []);
  const expiry = useMemo(
    () => parseDateToISO(_.get(patron, 'properties.meetingRequestExpiry')),
    [patron]
  );
  const hiveTimeOffset = useMemo(
    () => Date.now() - parseDateToISO(loadTime),
    [loadTime]
  );

  const [timeLeft, timerActive] = useTimer(expiry, true, hiveTimeOffset);

  const isVisible = usePageVisibility();

  useEffect(() => {
    if (isVisible) {
      ackMeetingRequest(patron?.id);
    }
  }, [patron?.id, isVisible]);

  const handleStartMeetingClick =
    (patronId: string, currentCareId: string) => () => {
      startMeeting(patronId, currentCareId);
      if (!patron.properties.meetingName) {
        window.open(patron?.properties?.meetingUrl);
      }
    };

  return (
    <Box display="flex" flex={1} className="visit-timer">
      <SinglePanelWithMessaging
        patron={patron}
        color="blue"
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              {patron?.properties?.currentCareId ? (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      align="center"
                      variant="body2"
                      style={{ fontStyle: 'italic', color: 'white' }}
                    >
                      {t('visittimer.launch')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={handleStartMeetingClick(
                        patron.id,
                        patron.properties.currentCareId
                      )}
                    >
                      {t('visittimer.connect')}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
        }
      >
        <div className="visit-timer-text">{t('visittimer.ready')}</div>
        <br />
        <br />
        <div className="visit-timer-text">{t('visittimer.hold')}</div>
        <Grid container justifyContent="center">
          <Grid item>
            {timerActive ? (
              <CountdownTimer seconds={timeLeft} />
            ) : (
              <Paper className="countdownTimerSpinnerPaper" elevation={4}>
                <CircularProgress />
              </Paper>
            )}
          </Grid>
        </Grid>
        <div className="visit-timer-text">{t('visittimer.donotconnect')}</div>
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default VisitTimer;
