import { Observable } from 'rxjs';
import {
  Participant,
  ParticipantView,
  Chat,
  ChatView,
  Message,
  MessageView,
  ParticipantStatus,
  MessageDirection,
  ParticipantType,
} from './chat.model';
import {
  mergeObjects,
  createObjectCache,
  arrayValueMap,
  mapKeyMap,
} from './chat.util';
import { i18nText, i18nItemList } from '../i18nService';
import { stableMap } from '../../Util/util';

class ChatViewImplementation implements ChatView {
  public readonly id: string = this.chat.id;

  public title: string = this.chat.id;

  public readonly whoIsTyping: Observable<ParticipantView[]>;

  public readonly messages: Observable<MessageView[]>;

  public readonly participants: Observable<ParticipantView[]>;

  public readonly participantStatus: Observable<
    Map<ParticipantView, ParticipantStatus>
  >;

  public readonly perspective: ParticipantView;

  public readonly others: Observable<ParticipantView[]>;

  public readonly unreadMessages: Observable<MessageView[]>;

  public readonly unreadMessageCount: Observable<number>;

  public readonly hasUnreadMessages: Observable<boolean>;

  private allMessages = createObjectCache<MessageView>();

  private allParticipants = createObjectCache<ParticipantView>();

  constructor(private chat: Chat, perspective: Participant) {
    this.mapMessage = this.mapMessage.bind(this);
    this.mapParticipant = this.mapParticipant.bind(this);

    this.title = chat.id;
    this.perspective = this.mapParticipant(perspective);

    this.messages = stableMap(chat.messages, arrayValueMap(this.mapMessage));
    this.participants = stableMap(
      chat.participants,
      arrayValueMap(this.mapParticipant)
    );
    this.participantStatus = stableMap(
      chat.participantStatus,
      mapKeyMap(this.mapParticipant)
    );
    this.whoIsTyping = stableMap(chat.whoIsTyping, (allTypers) => {
      const mappedTypers = allTypers.map(this.mapParticipant);
      const butNotMyself = mappedTypers.filter(
        (who) => who !== this.perspective
      );
      return butNotMyself;
    });
    this.others = stableMap(this.participants, (people) =>
      people.filter(
        (person) =>
          person.type !== ParticipantType.System && person !== this.perspective
      )
    );
    this.others.subscribe((other) => {
      const names = i18nItemList(other.map((who) => who.name));
      this.title = i18nText('chat.textWith', { name: names });
    });
    this.unreadMessages = stableMap(this.messages, (messages) => {
      if (this.perspective.type === ParticipantType.Admin) {
        return messages.filter(
          (message) =>
            message.author.type !== ParticipantType.System &&
            !message.readBy.includes(this.perspective)
        );
      }
      return messages.filter(
        (message) => !message.readBy.includes(this.perspective)
      );
    });
    this.unreadMessageCount = stableMap(this.unreadMessages, (messages) => {
      return messages.length;
    });
    this.hasUnreadMessages = stableMap(
      this.unreadMessageCount,
      (count) => count > 0
    );
  }

  private mapMessage(message: Message): MessageView {
    const before = this.allMessages.get(message.id);
    const { author, readBy, lastReadLocationFor, ...toBeCopied } = message;
    const who = this.mapParticipant(message.author);
    const readByMapped = readBy.map((p) => this.allParticipants.get(p.id));
    mergeObjects(who, author);
    const calculatedFields = {
      author: who,
      readBy: readByMapped,
      lastReadLocationFor: lastReadLocationFor.map((p) =>
        this.allParticipants.get(p.id)
      ),
      direction:
        message.author.id === this.perspective.id
          ? MessageDirection.Sent
          : MessageDirection.Received,
    };
    const after = mergeObjects(before, toBeCopied) as MessageView;
    Object.assign(after, calculatedFields);
    return after;
  }

  private mapParticipant(participant: Participant): ParticipantView {
    const before = this.allParticipants.get(participant.id);
    const after = mergeObjects(
      before,
      {
        status: before.status || ParticipantStatus.Inactive,
      },
      participant
    );
    return after;
  }

  send(message: Partial<MessageView>) {
    const withDefaults = mergeObjects<Partial<MessageView>>(
      {
        author: this.perspective,
      },
      message
    );
    this.chat.send(this.perspective, withDefaults);
  }

  userIsTyping() {
    this.chat.userIsTyping(this.perspective);
  }

  markRead(message: MessageView) {
    this.chat.markRead(this.perspective, message);
  }
}

export default ChatViewImplementation;
