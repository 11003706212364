import { raam } from 'raam-client-lib';

export function getMeetingName(id: string) {
  // TODO: Placeholder for when we resolve the meetingName from any type of link
  return Promise.resolve(id);
}

export function joinAsGuest(meetingName: string, guestName: string) {
  return raam.patron.joinAsGuest(meetingName, guestName);
}

const LinkService = {
  getMeetingName,
  joinAsGuest,
};

export default LinkService;
