import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, IconButton, Menu as MenuContainer, MenuItem, Tooltip, Typography, styled, useTheme, } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import FlipCameraIcon from '../../../icons/FlipCameraIcon';
import InfoIconOutlined from '../../../icons/InfoIconOutlined';
import SettingsIcon from '../../../icons/SettingsIcon';
import { useWrappedTranslation } from '../../../utils/i18nService';
import AboutDialog from '../../AboutDialog/AboutDialog';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleScreenShareButton from '../../Buttons/ToggleScreenShareButton/ToggleScreenShareButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useDeviceOrientation } from '../../DeviceOrientationProvider';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
export const IconContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '1.5em',
    marginRight: '0.3em',
});
const Menu = ({ buttonClassName, options = ['settings', 'flipCamera'], placement }) => {
    const t = useWrappedTranslation();
    const theme = useTheme();
    const roomState = useRoomState();
    const isReconnecting = roomState === 'reconnecting';
    const { isPortrait } = useDeviceOrientation();
    const [aboutOpen, setAboutOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const label = t('Menu');
    const anchorRef = useRef(null);
    const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
    const menuItems = useMemo(() => ({
        audio: (_jsx(ToggleAudioButton, { disabled: isReconnecting, variant: "menuItem" }, 'audio')),
        video: (_jsx(ToggleVideoButton, { disabled: isReconnecting, variant: "menuItem" }, 'video')),
        screenShare: (_jsx(ToggleScreenShareButton, { disabled: isReconnecting, variant: "menuItem" }, 'screenShare')),
        settings: (_jsxs(MenuItem, { onClick: () => {
                setSettingsOpen(true);
                setMenuOpen(false);
            }, children: [_jsx(IconContainer, { children: _jsx(SettingsIcon, {}) }), _jsx(Typography, { variant: "body1", children: t('Menu.AudioVideoSettings') })] }, 'settings')),
        flipCamera: flipCameraSupported && (_jsxs(MenuItem, { disabled: flipCameraDisabled, onClick: toggleFacingMode, children: [_jsx(IconContainer, { children: _jsx(FlipCameraIcon, {}) }), _jsx(Typography, { variant: "body1", children: t('Menu.FlipCamera') })] }, 'flipCamera')),
        about: (_jsxs(MenuItem, { onClick: () => setAboutOpen(true), children: [_jsx(IconContainer, { children: _jsx(InfoIconOutlined, {}) }), _jsx(Typography, { variant: "body1", children: t('Menu.About') })] }, 'about')),
    }), [
        isReconnecting,
        flipCameraDisabled,
        flipCameraSupported,
        toggleFacingMode,
        t,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: label, placement: placement, children: _jsx(Box, { boxShadow: 2, borderRadius: 20, children: _jsx(IconButton, { onClick: () => setMenuOpen((isOpen) => !isOpen), ref: anchorRef, className: buttonClassName, style: {
                            backgroundColor: theme.palette.grey[600],
                            color: theme.palette.getContrastText(theme.palette.grey[600]),
                            padding: '6px',
                        }, "aria-label": label, "data-cy-more-button": true, children: _jsx(MoreIcon, {}) }) }) }), _jsx(MenuContainer, { open: menuOpen, onClose: () => setMenuOpen((isOpen) => !isOpen), anchorEl: anchorRef.current, getContentAnchorEl: null, anchorOrigin: {
                    vertical: isPortrait ? 'center' : 'bottom',
                    horizontal: isPortrait ? 'left' : 'right',
                }, transformOrigin: {
                    vertical: isPortrait ? 'center' : 'top',
                    horizontal: 'right',
                }, PaperProps: {
                    style: {
                        transform: isPortrait ? 'translateX(-6px)' : 'translateY(6px)',
                    },
                }, children: _.filter(menuItems, (item, key) => _.includes(options, key)) }), _jsx(AboutDialog, { open: aboutOpen, onClose: () => {
                    setAboutOpen(false);
                    setMenuOpen(false);
                } }), _jsx(DeviceSelectionDialog, { open: settingsOpen, onClose: () => {
                    setSettingsOpen(false);
                    setMenuOpen(false);
                } })] }));
};
export default Menu;
