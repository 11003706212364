import { Box, Typography } from '@material-ui/core';
import React from 'react';
import buildNumber from '../../build-number';

function BuildNumber() {
  return (
    <Box padding={1}>
      <Typography color="primary" variant="body2">
        {buildNumber}
      </Typography>
    </Box>
  );
}

export default BuildNumber;
