import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, makeStyles, TextField, Grid, Button, InputLabel } from "@material-ui/core";
import { useAppState } from "../../../state";
const useStyles = makeStyles((theme) => ({
    gutterBottom: {
        marginBottom: "1em"
    },
    inputContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "1.5em 0 3.5em",
        "& div:not(:last-child)": {
            marginRight: "1em"
        },
        [theme.breakpoints.down("sm")]: {
            margin: "1.5em 0 2em"
        }
    },
    textFieldContainer: {
        width: "100%"
    },
    continueButton: {
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    }
}));
export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }) {
    const classes = useStyles();
    const { user } = useAppState();
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleRoomNameChange = (event) => {
        setRoomName(event.target.value);
    };
    const hasUsername = !window.location.search.includes("customIdentity=true") &&
        (user === null || user === void 0 ? void 0 : user.displayName);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h5", className: classes.gutterBottom, children: "Join a Room" }), _jsx(Typography, { variant: "body1", children: hasUsername
                    ? "Enter the name of a room you'd like to join."
                    : "Enter your name and the name of a room you'd like to join" }), _jsxs("form", { onSubmit: handleSubmit, children: [_jsxs("div", { className: classes.inputContainer, children: [!hasUsername && (_jsxs("div", { className: classes.textFieldContainer, children: [_jsx(InputLabel, { shrink: true, htmlFor: "input-user-name", children: "Your Name" }), _jsx(TextField, { id: "input-user-name", variant: "outlined", fullWidth: true, size: "small", value: name, onChange: handleNameChange })] })), _jsxs("div", { className: classes.textFieldContainer, children: [_jsx(InputLabel, { shrink: true, htmlFor: "input-room-name", children: "Room Name" }), _jsx(TextField, { autoCapitalize: "false", id: "input-room-name", variant: "outlined", fullWidth: true, size: "small", value: roomName, onChange: handleRoomNameChange })] })] }), _jsx(Grid, { container: true, justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", type: "submit", color: "primary", disabled: !name || !roomName, className: classes.continueButton, children: "Continue" }) })] })] }));
}
