import BrowserFingerPrint from './Fingerprint';
import { Encryptor } from './Encrypt.model';
import {
  textToArrayBuffer,
  arrayBufferToByteString,
  byteStringToArrayBuffer,
  arrayBufferToText,
} from './Encrypt-utils';

const AesGcm = 'AES-GCM'; // specify algorithm to use
const AesKeyLengthBytes = 12;

/**
 * https://gist.github.com/chrisveness/43bcda93af9f646d083fad678071b90a
 */
class AesGcmEncryptorImplementation implements Encryptor {
  /**
   * Encrypts the data
   * @param plainText The data to be encrypted
   */
  async encrypt(plainText: string): Promise<string> {
    const plainBuffer = textToArrayBuffer(plainText);
    const cipherBuffer = await this.encryptBuffer(plainBuffer);
    const cipherByteString = arrayBufferToByteString(cipherBuffer);
    const encodedCipher = btoa(cipherByteString);
    return encodedCipher;
  }

  /**
   * Decrypts the data
   * @param byteString The data to be decrypted
   */
  async decrypt(encodedCipher: string): Promise<string> {
    const cipherByteString = atob(encodedCipher);
    const cipherBuffer = byteStringToArrayBuffer(cipherByteString);
    const plainBuffer = await this.decryptBuffer(cipherBuffer);
    const plainText = arrayBufferToText(plainBuffer);
    return plainText;
  }

  /**
   * Encrypts the data
   * @param data The data to be encrypted
   */
  async encryptBuffer(dataBuffer: ArrayBuffer): Promise<ArrayBuffer> {
    const initializingVector = crypto.getRandomValues(
      new Uint8Array(AesKeyLengthBytes)
    );
    const key = await this.resolveKey(initializingVector, true);

    const alg = { name: AesGcm, iv: initializingVector };

    const cipherData = await crypto.subtle.encrypt(alg, key, dataBuffer);

    const combined = new Uint8Array(
      cipherData.byteLength + initializingVector.byteLength
    );
    combined.set(initializingVector, 0);
    combined.set(new Uint8Array(cipherData), AesKeyLengthBytes);
    return combined.buffer;
  }

  /**
   * Decrypts the data
   * @param data The data to be decrypted
   */
  async decryptBuffer(combined: ArrayBuffer): Promise<ArrayBuffer> {
    const initializingVector = combined.slice(0, AesKeyLengthBytes);
    const cipherData = combined.slice(AesKeyLengthBytes);

    const key = await this.resolveKey(
      new Uint8Array(initializingVector),
      false
    );

    const alg = { name: AesGcm, iv: initializingVector }; // specify algorithm to use

    const plainData = await crypto.subtle.decrypt(alg, key, cipherData); // decrypt ciphertext using key

    return plainData;
  }

  /**
   * Resolves the cryptographic key to use for encryption
   */
  // eslint-disable-next-line class-methods-use-this
  private async resolveKey(
    initializingVector: Uint8Array,
    isForEncrypt: boolean
  ): Promise<CryptoKey> {
    const password = await BrowserFingerPrint;
    const pwUtf8 = textToArrayBuffer(password); // encode password as UTF-8
    const pwHash = await crypto.subtle.digest('SHA-256', pwUtf8); // hash the password
    const key = await crypto.subtle.importKey(
      'raw',
      pwHash,
      { name: AesGcm, length: 256 },
      false,
      [isForEncrypt ? 'encrypt' : 'decrypt']
    );
    return key;
  }
}

const AesGcmEncryptor: Encryptor = new AesGcmEncryptorImplementation();

export default AesGcmEncryptor;
