/* eslint-disable */

import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import _ from 'lodash';

function maybeToString(text?: string): any {
  return text ? text.toString() : undefined;
}

export type InQFieldDefaultProps = Omit<
  TextFieldProps,
  'onChange' | 'onInput'
> & {
  onChange?: (value: string) => void;
  onInput?: (value: string) => void;
  onEnter?: () => void;
  hint?: React.ReactNode;
};

function InQFieldDefaultInput(props: InQFieldDefaultProps) {
  const filteredProps = _.omit(props, 'label');
  const { children, onChange, onInput, onEnter, select, value, ...moreProps } =
    filteredProps;

  if (!_.isNil(children) && !select) {
    if (
      props.required ||
      props.placeholder ||
      props.value ||
      props.InputProps ||
      props.error ||
      props.id ||
      onChange ||
      onInput ||
      onEnter
    ) {
      throw new Error(
        `InQFieldDefaultInput (${props.label}): You have provided a child element so this component is working in custom field mode.  As a result you must remove all data bindings from InQFieldDefaultInput except for label`
      );
    }
    return <>{children}</>;
  }
  if (value === undefined && onChange === undefined && onInput === undefined) {
    throw new Error(
      `InQFieldDefaultInput (${props.label}): You have not provided a child element so this component is working in TextField/Readonly mode.  As a result you must provide either a value or an onChange handler`
    );
  }

  if (onChange || onInput) {
    const wrappedOnChange = onChange
      ? (e: any) => onChange(maybeToString(e?.target?.value))
      : () => {};
    const wrappedOnInput = onInput
      ? (e: any) => onInput(maybeToString(e?.target?.value))
      : () => {};
    const wrappedOnEnter = onEnter
      ? (e: any) => {
          if (e.keyCode === 13) {
            onEnter();
          }
        }
      : () => {};

    return (
      <TextField
        variant="outlined"
        fullWidth
        margin="dense"
        onChange={wrappedOnChange}
        onInput={wrappedOnInput}
        onKeyUp={wrappedOnEnter}
        value={value}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...moreProps}
      />
    );
  }
  const valueString = value as string;
  return <>{valueString}</>;
}

export default InQFieldDefaultInput;
