import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, IconButton, SvgIcon, Tooltip, useTheme, } from '@material-ui/core';
import { ClosedCaption, ClosedCaptionOutlined } from '@material-ui/icons';
import { raam } from 'raam-client-lib';
import { useEffect, useMemo, useState } from 'react';
import { DisabledCCIcon } from '../../../assets';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';
import { useWrappedTranslation } from '../../../utils/i18nService';
import useIdentity from '../../../utils/useIdentity';
import { useCaption } from '../../CaptionProvider';
export default function ToggleCaptionButton({ disabled, className, variant, placement, }) {
    const [captionRequested, setCaptionRequested] = useState(false);
    const { room } = useVideoContext();
    const { room: roomName } = useAppState();
    const { updateLocallyDisplayCaptionsFn, ccDisabled } = useCaption();
    const t = useWrappedTranslation();
    const theme = useTheme();
    const identity = useIdentity(room);
    useEffect(() => {
        if (!identity) {
            return;
        }
        raam.twilio
            .setCaptioningState(roomName, identity, captionRequested)
            .catch(() => console.error('Error while trying to set the captioningState in hive'));
    }, [captionRequested, identity, roomName]);
    useEffect(() => {
        updateLocallyDisplayCaptionsFn(captionRequested);
    }, [captionRequested, updateLocallyDisplayCaptionsFn]);
    const tooltipText = useMemo(() => {
        if (ccDisabled) {
            return t('CaptionSetupError');
        }
        return captionRequested
            ? t('ToggleCaptionButton.DisableCaption')
            : t('ToggleCaptionButton.EnableCaption');
    }, [captionRequested, ccDisabled, t]);
    if (variant === 'icon') {
        return (_jsx(Tooltip, { title: tooltipText, placement: placement, children: _jsx(Box, { boxShadow: 2, borderRadius: 20, children: _jsx(IconButton, { disabled: ccDisabled || disabled, className: className, onClick: () => setCaptionRequested((prev) => !prev), style: {
                        backgroundColor: ccDisabled
                            ? theme.palette.grey[500]
                            : captionRequested
                                ? theme.palette.error.main
                                : theme.palette.grey[600],
                        color: theme.palette.getContrastText(captionRequested
                            ? theme.palette.error.main
                            : theme.palette.grey[600]),
                        padding: '6px',
                    }, "aria-label": captionRequested
                        ? t('ToggleCaptionButton.DisableCaption')
                        : t('ToggleCaptionButton.EnableCaption'), "data-cy-caption-toggle": true, children: ccDisabled || disabled ? (_jsx(SvgIcon, { children: _jsx(DisabledCCIcon, {}) })) : captionRequested ? (_jsx(ClosedCaption, {})) : (_jsx(ClosedCaptionOutlined, {})) }) }) }));
    }
    return (_jsx(Button, { disabled: ccDisabled || disabled, onClick: () => setCaptionRequested((prev) => !prev), startIcon: captionRequested ? _jsx(ClosedCaption, {}) : _jsx(ClosedCaptionOutlined, {}), className: className, "aria-label": captionRequested
            ? t('ToggleCaptionButton.DisableCaption')
            : t('ToggleCaptionButton.EnableCaption'), "data-cy-caption-toggle": true, children: captionRequested
            ? t('ToggleCaptionButton.DisableCaption')
            : t('ToggleCaptionButton.EnableCaption') }));
}
