import { createTheme, ThemeOptions } from '@material-ui/core';
import InQStyleGuide from '../constants';

const LightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: InQStyleGuide.blue.medium,
      light: InQStyleGuide.blue.light,
      dark: InQStyleGuide.blue.dark,
    },
    secondary: { main: InQStyleGuide.blue.bright },
    info: { main: InQStyleGuide.blue.bright },
    warning: { main: InQStyleGuide.orange },
    error: { main: InQStyleGuide.red },
    success: { main: InQStyleGuide.green },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
  },
} as ThemeOptions);

export default LightTheme;
