import React from 'react';
import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { SinglePanelLayout } from '../../Components';
import useClinicConfig from '../../Hooks/useClinicConfig';
import { useExtendedTranslation } from '../../Services/i18nService';

function AboutInQ({ onNext }: { onNext: Function }) {
  const clinicConfig = useClinicConfig();
  const t = useExtendedTranslation();

  return (
    <Box display="flex" flex={1}>
      <SinglePanelLayout
        title={
          <Typography
            variant="h5"
            color="primary"
            align="center"
            style={{ fontStyle: 'italic', paddingBottom: '20px' }}
          >
            {t('about.title')}
          </Typography>
        }
        footer={
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Button
                className="Button"
                color="primary"
                variant="contained"
                onClick={() => onNext()}
              >
                {t('about.footer.button')}
              </Button>
            </Grid>
          </Grid>
        }
        logo={clinicConfig.logoName}
        disableBuildNumber={false}
      >
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="body2" color="primary">
              {t('about.text.description')}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ fontStyle: 'italic' }}>
              <ul>
                <li>
                  <Typography variant="body2" color="primary">
                    {t('about.text.point1')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color="primary">
                    {t('about.text.point2')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color="primary">
                    {t('about.text.point3')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color="primary">
                    {t('about.text.point4')}
                  </Typography>
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Typography variant="body2" color="primary">
              <Link
                underline="always"
                style={{
                  color: '#077ad3',
                  fontWeight: 'bold',
                }}
                target="_blank"
                rel="noreferrer"
                href="https://www.emids.com/privacy-policy/"
              >
                {t('about.text.privacy')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </SinglePanelLayout>
    </Box>
  );
}

export default AboutInQ;
