import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { InQRadioButton, InQRadioButtonGroup } from '../InQUI';
import { useExtendedTranslation } from '../../Services/i18nService';
import { SatisfactionScore } from '../../Types/IPatron';
import './SatisfactionSurvey.scss';

interface Props {
  onSelectSatisfactionScore: (satisfactionScore: SatisfactionScore) => void;
}

function SatisfactionSurvey(props: Props) {
  const t = useExtendedTranslation();
  const { onSelectSatisfactionScore } = props;
  const [selected, setSelected] = useState(SatisfactionScore.Nothing);

  const selectButton = (satisfactionScore: string) => {
    setSelected(Number(satisfactionScore) as SatisfactionScore);
    onSelectSatisfactionScore(Number(satisfactionScore) as SatisfactionScore);
  };

  return (
    <Grid container>
      <Grid item container>
        <InQRadioButtonGroup
          value={selected.toString()}
          onChange={selectButton}
        >
          <InQRadioButton
            whiteBackground
            value={SatisfactionScore.VeryHappy.toString()}
            label={t('completeSession.verySatisfied')}
          />
          <InQRadioButton
            whiteBackground
            value={SatisfactionScore.Happy.toString()}
            label={t('completeSession.somewhatSatisfied')}
          />
          <InQRadioButton
            whiteBackground
            value={SatisfactionScore.Neither.toString()}
            label={t('completeSession.neither')}
          />
          <InQRadioButton
            whiteBackground
            value={SatisfactionScore.Sad.toString()}
            label={t('completeSession.somewhatDissatisfied')}
          />
          <InQRadioButton
            whiteBackground
            value={SatisfactionScore.VerySad.toString()}
            label={t('completeSession.veryDissatisfied')}
          />
        </InQRadioButtonGroup>
      </Grid>
    </Grid>
  );
}

export default SatisfactionSurvey;
