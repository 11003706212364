const _ = require('lodash');
const { assertConnection, invokeActionAndWait } = require('./utils');

const getKlsInstances = _.curry((ns, name, bee) => bee.storage.getClassInstancesByClassName(ns, name));
const getManyKlsInstances = _.curry((ns, name, bee, ids) => bee.storage.getManyClassInstanceByClassNameAndId(ns, name, ids));
const invokeQuery = _.curry((ns, name, bee, params) => bee.storage.executeQuery(ns, name, params));
const getRaamInstances = getKlsInstances('raam');
const getManyRaamInstances = getManyKlsInstances('raam');
const getConfig = getRaamInstances('config');
const queryStats = invokeQuery('stats');
const queryRaam = invokeQuery('raam');
const getState = getRaamInstances('appState');

const extractProperties = (ins) => _.map(ins, 'properties');

const extractReportProperties = (instances) => _.map(instances, (instance) => ({
  created: instance.created,
  ...instance.properties
}));

const getApplicationConfig = () => {
  const connection = assertConnection();
  let {bee} = connection;

  return getConfig(bee)
    .then(extractProperties)
    .then(_.first);
};

const getAppState = () => {
  const connection = assertConnection();
  let {bee} = connection;

  return getState(bee)
    .then(extractProperties)
    .then(_.first);
};

module.exports = {
  getRaamInstances,
  getManyRaamInstances,
  queryStats,
  queryRaam,
  getApplicationConfig,
  getAppState,
  extractProperties,
  extractReportProperties
};
