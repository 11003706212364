/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import {
  ErrorMessage,
  FastField,
  Field,
  FieldProps,
  Form,
  Formik,
  getIn,
} from 'formik';
import _ from 'lodash';
import { FC, useContext, useState } from 'react';
import PinField from 'react-pin-field';
import * as Yup from 'yup';
import { FormikErrorSnackbar, SinglePanelLayout } from '../../Components';
import DataContext from '../../Contexts/DataContext';
import useClinicConfig from '../../Hooks/useClinicConfig';
import { useExtendedTranslation } from '../../Services/i18nService';
import { USER_ENTRY_REGEX, areAllValuesTruthy } from '../../Util/util';
import './LinkCheckIn.scss';

const SimpleInput: FC<TextFieldProps> = ({ label, ...props }) => {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item>
          <Typography
            color="primary"
            variant="body2"
            style={{ fontStyle: 'italic' }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth variant="outlined" margin="dense" {...props} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export interface LinkCheckInProps {
  onConnect: (firstName: string, lastName: string, pin: string) => void;
}

function LinkCheckIn({
  onConnect,
  validatePin,
}: {
  onConnect: (firstName: string, lastName: string, pin: string) => void;
  validatePin: (pin: string) => Promise<boolean>;
}) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const {
    applicationConfig: { usePIN },
  } = useContext(DataContext);
  const [isPinValid, setIsPinValid] = useState(false);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(USER_ENTRY_REGEX, t('checkin.firstName.error'))
      .max(128, t('checkin.firstName.error'))
      .required(t('checkin.firstName.error')),
    lastName: Yup.string()
      .matches(USER_ENTRY_REGEX, t('checkin.lastName.error'))
      .max(128, t('checkin.lastName.error'))
      .required(t('checkin.lastName.error')),
    ...(usePIN && {
      pin: Yup.string()
        .required(t('checkin.pin.error'))
        .min(4)
        .test({
          name: 'isValidPin',
          message: t('checkin.pin.error'),
          test: async (v: string | undefined) => {
            // Future TODO: Find a way to test only if the value have changed.
            let isValid = v?.length === 4 && (await validatePin(v));
            setIsPinValid(isValid);
            return isValid;
          },
        }),
    }),
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    pin: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(formValues) => {
        onConnect(formValues.firstName, formValues.lastName, formValues.pin);
      }}
    >
      {({ values, submitForm, setFieldValue }) => (
        <Form style={{ display: 'flex', flex: 1 }}>
          <FormikErrorSnackbar />
          <SinglePanelLayout
            title={
              <Typography variant="h5" color="primary" align="center">
                {t('linkcheckin.header')}
              </Typography>
            }
            footer={
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={areAllValuesTruthy(
                      _.pick(values, [
                        'firstName',
                        'lastName',
                        ...(usePIN ? ['pin'] : []),
                      ])
                    )}
                    onClick={submitForm}
                  >
                    {t('linkcheckin.button')}
                  </Button>
                </Grid>
              </Grid>
            }
            logo={clinicConfig.logoName}
            ariaLive="off"
          >
            <Grid container spacing={1} style={{ paddingTop: '12px' }}>
              <Grid item xs={12}>
                <Typography variant="body2">{t('linkcheckin.body')}</Typography>
              </Grid>

              <FastField name="firstName">
                {({ form, field }: FieldProps) => (
                  <SimpleInput
                    error={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                    label={t('linkcheckin.firstName')}
                    inputProps={{ maxLength: 128 }}
                    {...field}
                  />
                )}
              </FastField>

              <FastField name="lastName">
                {({ form, field }: FieldProps) => (
                  <SimpleInput
                    error={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                    label={t('linkcheckin.lastName')}
                    inputProps={{ maxLength: 128 }}
                    {...field}
                  />
                )}
              </FastField>

              {usePIN && (
                <Grid item xs={12}>
                  <Field name="pin">
                    {() => {
                      return (
                        <Box mt={2}>
                          <Grid
                            container
                            spacing={2}
                            direction="column"
                            alignItems="center"
                            justify-content="center"
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant="h6"
                                style={{ textAlign: 'center' }}
                              >
                                {t('checkin.pin')}
                              </Typography>
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid item xs={12}>
                                <div className="pin-field-container">
                                  <PinField
                                    className={[
                                      'pin-field',
                                      isPinValid && 'complete',
                                    ]
                                      .filter((c) => c)
                                      .join(' ')}
                                    autoComplete="one-time-password"
                                    length={4}
                                    validate={/^[a-zA-Z0-9]$/}
                                    onChange={(value: string) => {
                                      setFieldValue('pin', value);
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                color: 'red',
                              }}
                            >
                              <ErrorMessage name="pin" />
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    }}
                  </Field>
                </Grid>
              )}
            </Grid>
          </SinglePanelLayout>
        </Form>
      )}
    </Formik>
  );
}

export default LinkCheckIn;
