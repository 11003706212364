import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, InputLabel, MenuItem, Select, Typography, useTheme, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useWrappedTranslation } from '../../utils/i18nService';
import { useDeviceOrientation } from '../DeviceOrientationProvider';
import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import VideoInputList from './VideoInputList/VideoInputList';
import { useCaption } from '../CaptionProvider';
import { useAppState } from '../../state';
import { raam } from 'raam-client-lib';
const useStyles = makeStyles(() => ({
    button: {
        float: 'right',
    },
    headline: {
        marginBottom: '1.3em',
        fontSize: '1.1rem',
    },
    listSection: {
        margin: '2em 0 0.8em',
        '&:first-child': {
            margin: '1em 0 2em 0',
        },
    },
}));
export default function DeviceSelectionDialog({ open, onClose, }) {
    const t = useWrappedTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const { clientComponent, clientDimension } = useDeviceOrientation();
    const { isClinician, room: roomName } = useAppState();
    const { languageCode } = useCaption();
    const primaryCaptionLanguages = t('PrimaryCaptionLanguages', {
        returnObjects: true,
    });
    const otherCaptionLanguages = t('OtherCaptionLanguages', {
        returnObjects: true,
    });
    const primaryLanguages = useMemo(() => _.map(primaryCaptionLanguages, (entry) => {
        return (_jsx(MenuItem, { value: entry.locale, children: entry.languageName }, entry.locale));
    }), [primaryCaptionLanguages]);
    const otherLanguages = useMemo(() => _.map(otherCaptionLanguages, (entry) => {
        return (_jsx(MenuItem, { value: entry.locale, children: entry.languageName }, entry.locale));
    }), [otherCaptionLanguages]);
    const onChangeLanguageCodeClick = useCallback((newLang) => {
        if (!isClinician) {
            return;
        }
        raam.twilio.setRoomLanguage(roomName, newLang);
    }, [roomName, isClinician]);
    return (_jsxs(Dialog, { open: open, onClose: onClose, container: clientComponent, style: { position: 'absolute' }, BackdropProps: { style: { position: 'absolute' } }, fullWidth: true, fullScreen: clientDimension.width < theme.breakpoints.values.sm, children: [_jsx(DialogTitle, { disableTypography: true, style: { padding: '4px 12px 4px 24px' }, children: _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", children: [_jsx(Grid, { item: true, xs: true, zeroMinWidth: true, children: _jsx(Typography, { variant: "h5", noWrap: true, children: t('DeviceSelectionDialog.Title') }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { "aria-label": t('DeviceSelectionDialog.CloseButton'), onClick: onClose, children: _jsx(CloseIcon, {}) }) })] }) }), _jsx(Divider, {}), _jsxs(DialogContent, { children: [_jsxs("div", { className: classes.listSection, children: [_jsx(Typography, { variant: "h6", className: classes.headline, children: t('DeviceSelectionDialog.Video') }), _jsx(VideoInputList, {})] }), _jsx(Divider, {}), _jsxs("div", { className: classes.listSection, children: [_jsx(Typography, { variant: "h6", className: classes.headline, children: t('DeviceSelectionDialog.Audio') }), _jsx(AudioInputList, {})] }), _jsx("div", { className: classes.listSection, children: _jsx(AudioOutputList, {}) }), isClinician && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx("div", { className: classes.listSection, children: _jsxs("div", { children: [_jsx(Typography, { variant: "h6", className: classes.headline, children: t('DeviceSelectionDialog.Captioning') }), _jsx(InputLabel, { id: "lang", style: {
                                                fontSize: '0.875rem',
                                            }, children: t('DeviceSelectionDialog.CaptionLanguageSelection') }), _jsxs(Select, { labelId: "lang", variant: "outlined", value: languageCode, onChange: (e) => {
                                                const nextLanguageCode = _.get(e, 'target.value', '');
                                                onChangeLanguageCodeClick(nextLanguageCode);
                                            }, children: [primaryLanguages, _jsx(Divider, {}), otherLanguages] })] }) })] }))] })] }));
}
