import { jsx as _jsx } from "react/jsx-runtime";
import useTrack from "../../hooks/useTrack/useTrack";
import AudioTrack from "../AudioTrack/AudioTrack";
import VideoTrack from "../VideoTrack/VideoTrack";
import DataTrack from "../DataTrack/DataTrack";
export default function Publication({ publication, isLocalParticipant, videoOnly, videoPriority }) {
    const track = useTrack(publication);
    if (!track)
        return null;
    switch (track.kind) {
        case "video":
            return (_jsx(VideoTrack, { track: track, priority: videoPriority, isLocal: !track.name.includes("screen") && isLocalParticipant }));
        case "audio":
            return videoOnly ? null : _jsx(AudioTrack, { track: track });
        case "data":
            return videoOnly ? null : _jsx(DataTrack, { track: track });
        default:
            return null;
    }
}
