var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isPlainObject } from "is-plain-object";
export const isMobile = (() => {
    if (typeof navigator === "undefined" ||
        typeof navigator.userAgent !== "string") {
        return false;
    }
    return /Mobile/.test(navigator.userAgent);
})();
// Recursively removes any object keys with a value of undefined
export function removeUndefineds(obj) {
    if (!isPlainObject(obj))
        return obj;
    const target = {};
    for (const key in obj) {
        const val = obj[key];
        if (typeof val !== "undefined") {
            target[key] = removeUndefineds(val);
        }
    }
    return target;
}
export function getDeviceInfo() {
    return __awaiter(this, void 0, void 0, function* () {
        const devices = yield navigator.mediaDevices.enumerateDevices();
        return {
            audioInputDevices: devices.filter(device => device.kind === "audioinput"),
            videoInputDevices: devices.filter(device => device.kind === "videoinput"),
            audioOutputDevices: devices.filter(device => device.kind === "audiooutput"),
            hasAudioInputDevices: devices.some(device => device.kind === "audioinput"),
            hasVideoInputDevices: devices.some(device => device.kind === "videoinput")
        };
    });
}
// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export function isPermissionDenied(name) {
    return __awaiter(this, void 0, void 0, function* () {
        //this is to fix bug with PermissionName (https://github.com/microsoft/TypeScript/issues/33923) :
        const permissionName = name;
        if (navigator.permissions) {
            try {
                const result = yield navigator.permissions.query({
                    name: permissionName
                });
                return result.state === "denied";
            }
            catch (_a) {
                return false;
            }
        }
        else {
            return false;
        }
    });
}
export * from './i18nService';
export * from './IRoomConfig';
export * from './tokenUtil';
export * from './useCCToken';
export * from './useConnectionOptions';
export * from './useDataTrack';
export * from './useIdentity';
export * from './useRoomConfig';
export * from './useSpeechRecognizer';
