import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, IconButton, MenuItem, Tooltip, Typography, useTheme, } from '@material-ui/core';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useWrappedTranslation } from '../../../utils/i18nService';
import { IconContainer } from '../../MenuBar/Menu/Menu';
export default function ToggleAudioButton(props) {
    const t = useWrappedTranslation();
    const theme = useTheme();
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const { localTracks } = useVideoContext();
    const hasAudioTrack = localTracks.some((track) => track.kind === 'audio');
    const label = !hasAudioTrack
        ? t('ToggleAudioButton.NoAudio')
        : isAudioEnabled
            ? t('ToggleAudioButton.EnableAudio')
            : t('ToggleAudioButton.DisableAudio');
    if (props.variant && props.variant === 'menuItem') {
        return (_jsxs(MenuItem, { className: props.className, onClick: toggleAudioEnabled, disabled: !hasAudioTrack || props.disabled, children: [_jsx(IconContainer, { children: isAudioEnabled ? _jsx(MicNoneOutlinedIcon, {}) : _jsx(MicOffOutlinedIcon, {}) }), _jsx(Typography, { variant: "body1", children: label })] }));
    }
    if (props.variant && props.variant === 'icon') {
        return (_jsx(Tooltip, { title: label, placement: props.placement, children: _jsx(Box, { boxShadow: 2, borderRadius: 20, children: _jsx(IconButton, { className: props.className, onClick: toggleAudioEnabled, disabled: !hasAudioTrack || props.disabled, style: {
                        backgroundColor: isAudioEnabled
                            ? theme.palette.grey[600]
                            : theme.palette.error.main,
                        color: theme.palette.getContrastText(isAudioEnabled
                            ? theme.palette.grey[600]
                            : theme.palette.error.main),
                        padding: '6px',
                    }, "aria-label": label, "data-cy-audio-toggle": true, children: isAudioEnabled ? _jsx(MicNoneOutlinedIcon, {}) : _jsx(MicOffOutlinedIcon, {}) }) }) }));
    }
    return (_jsx(Button, { className: props.className, onClick: toggleAudioEnabled, disabled: !hasAudioTrack || props.disabled, startIcon: isAudioEnabled ? _jsx(MicNoneOutlinedIcon, {}) : _jsx(MicOffOutlinedIcon, {}), "aria-label": label, "data-cy-audio-toggle": true, children: label }));
}
