import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, Box, Grid, Typography, useTheme } from "@material-ui/core";
import _ from "lodash";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useWrappedTranslation } from "../../utils/i18nService";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import PinIcon from "../ParticipantInfo/PinIcon";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
const MainParticipantInfoBox = ({ participant }) => {
    const t = useWrappedTranslation();
    const theme = useTheme();
    const { room } = useVideoContext();
    const localParticipant = room.localParticipant;
    const isLocal = localParticipant === participant;
    const [selectedParticipant] = useSelectedParticipant();
    const publications = usePublications(participant);
    const screenSharePublication = publications.find(p => p.trackName.includes("screen"));
    const audioPublication = publications.find(p => p.kind === "audio");
    const audioTrack = useTrack(audioPublication);
    return (_jsx(Box, { bgcolor: alpha(theme.palette.grey[900], 0.8), padding: "4px", paddingLeft: 1, paddingRight: 1, borderRadius: 20, display: "flex", maxWidth: "300px", width: "100%", boxShadow: 2, children: _jsxs(Grid, { container: true, spacing: 1, alignItems: "center", children: [_jsx(Grid, { item: true, style: { height: "29px" }, children: _jsx(AudioLevelIndicator, { audioTrack: audioTrack, color: "#fff" }) }), _jsx(Grid, { xs: true, item: true, zeroMinWidth: true, style: { display: "flex" }, children: _jsxs(Grid, { container: true, justifyContent: "center", wrap: "nowrap", spacing: 1, children: [_jsx(Grid, { item: true, zeroMinWidth: true, children: _jsx(Typography, { variant: "body1", style: { color: "white" }, noWrap: true, children: _.get(_.split(participant.identity, "|"), "0") }) }), isLocal && (_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "body1", style: { color: "white" }, noWrap: true, children: t("MainParticipantInfo.Local") }) })), screenSharePublication && (_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "body1", style: { color: "white" }, noWrap: true, children: t("MainParticipantInfo.ScreenShare") }) }))] }) }), _jsx(Grid, { item: true, children: _jsx(NetworkQualityLevel, { participant: participant, color: "#fff" }) }), participant === selectedParticipant && (_jsx(Grid, { item: true, style: { height: "29px" }, children: _jsx(PinIcon, { color: "#fff" }) }))] }) }));
};
export default MainParticipantInfoBox;
