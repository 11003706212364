import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
export var SupportedLanguages;
(function (SupportedLanguages) {
    SupportedLanguages["English"] = "en";
    SupportedLanguages["French"] = "fr";
})(SupportedLanguages || (SupportedLanguages = {}));
function multiple(t, key, props, delimiter = "|") {
    return t(key, props).split(delimiter);
}
function map(t, key, mapFn, props, delimiter = "|") {
    const tokens = multiple(t, key, props, delimiter);
    let mapped;
    if (Array.isArray(mapFn)) {
        if (tokens.length !== mapFn.length) {
            throw new Error("Delimited Translation must have the same number of sections as the number of map functions provided in an array, or pass a single map function not in an array");
        }
        mapped = tokens.map((text, i) => (
        // eslint-disable-next-line react/no-array-index-key
        _jsx(React.Fragment, { children: mapFn[i](text) }, i)));
    }
    else {
        mapped = tokens.map((text, i) => (
        // eslint-disable-next-line react/no-array-index-key
        _jsx(React.Fragment, { children: mapFn(text) }, i)));
    }
    return _jsx(_Fragment, { children: mapped });
}
function plainText(text) {
    return _jsx(_Fragment, { children: text });
}
function useWrappedTranslationActual(ot) {
    const t = ot;
    t.map = map.bind(null, t);
    t.multiple = multiple.bind(null, t);
    t.plainText = plainText;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    t.useSpecificLanguage = useSpecificLanguage;
    return t;
}
function useSpecificLanguage(lang) {
    return useWrappedTranslationActual(i18n.getFixedT(lang === SupportedLanguages.English ? "en" : "fr"));
}
function useWrappedTranslation() {
    return useWrappedTranslationActual(useTranslation().t);
}
export { useWrappedTranslation, useSpecificLanguage };
export default i18n;
