import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, Box, createStyles, Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useEffect } from "react";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useVideoTrackDimensions from "../../hooks/useVideoTrackDimensions/useVideoTrackDimensions";
import AvatarIcon from "../../icons/AvatarIcon";
import { useWrappedTranslation } from "../../utils/i18nService";
import { useDeviceOrientation } from "../DeviceOrientationProvider";
import ParticipantInfo from "../ParticipantInfo/ParticipantInfo";
import ParticipantTracks from "../ParticipantTracks/ParticipantTracks";
const useStyles = makeStyles((theme) => createStyles({
    container: {
        overflow: "hidden",
        "& video": {
            filter: "none"
        },
        background: "black"
    },
    containerCover: {
        overflow: "hidden",
        "& video": {
            filter: "none",
            objectFit: "cover !important"
        },
        background: "black"
    },
    innerContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
    },
    infoContainer: {
        position: "absolute",
        zIndex: 2,
        height: "100%",
        width: "100%",
        display: "flex"
    },
    avatarContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "black",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                transform: "scale(0.7)"
            }
        }
    },
    reconnectingContainer: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(40, 42, 43, 0.75)",
        zIndex: 1
    },
    screenShareIconContainer: {
        background: "rgba(0, 0, 0, 0.5)",
        padding: "0.18em 0.3em",
        marginRight: "0.3em",
        display: "flex",
        "& path": {
            fill: "white"
        }
    },
    identity: {
        background: "rgba(0, 0, 0, 0.5)",
        color: "white",
        padding: "0.18em 0.3em",
        margin: 0,
        display: "flex",
        alignItems: "center"
    },
    infoRowBottom: {
        display: "flex",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 0,
        left: 0
    },
    typeography: {
        color: "white",
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.75rem"
        }
    },
    hideParticipant: {
        display: "none"
    },
    cursorPointer: {
        cursor: "pointer"
    }
}));
export default function Participant({ participant, videoOnly, enableScreenShare, onClick, isSelected, isLocalParticipant, hideParticipant }) {
    const classes = useStyles();
    const t = useWrappedTranslation();
    const theme = useTheme();
    const publications = usePublications(participant);
    const { isPortrait } = useDeviceOrientation();
    const videoPublication = publications.find(p => !p.trackName.includes("screen") && p.kind === "video");
    const isVideoEnabled = Boolean(videoPublication);
    const videoTrack = useTrack(videoPublication);
    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);
    const dimensions = useVideoTrackDimensions(videoTrack);
    useEffect(() => {
        const timeoutId = setTimeout(() => videoTrack === null || videoTrack === void 0 ? void 0 : videoTrack.emit("dimensionsChanged", videoTrack), 200);
        if ((dimensions === null || dimensions === void 0 ? void 0 : dimensions.width) && (dimensions === null || dimensions === void 0 ? void 0 : dimensions.height)) {
            clearTimeout(timeoutId);
        }
        return () => clearTimeout(timeoutId);
    }, [dimensions, videoTrack]);
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);
    return (_jsxs(Grid, { container: true, alignItems: "center", direction: "column", "data-cy-participant": participant.identity, onClick: onClick, style: {
            transform: "translateZ(0)",
            overflow: "hidden",
            borderRadius: "8px",
            border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
            position: "relative",
            boxShadow: "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px"
        }, className: clsx({
            [classes.container]: !isPortrait,
            [classes.containerCover]: isPortrait,
            [classes.hideParticipant]: hideParticipant,
            [classes.cursorPointer]: Boolean(onClick)
        }), children: [_jsx("div", { className: classes.infoContainer, children: _jsx(Grid, { container: true, justifyContent: "center", alignItems: "flex-end", children: _jsx(Grid, { item: true, xs: true, children: _jsx(Box, { bgcolor: alpha(theme.palette.grey[900], 0.8), padding: "2px", children: _jsx(ParticipantInfo, { participant: participant, isSelected: isSelected, isLocalParticipant: isLocalParticipant, color: "#fff", isCenter: true }) }) }) }) }), _jsxs("div", { className: classes.innerContainer, children: [(!isVideoEnabled || isVideoSwitchedOff) && (_jsx("div", { className: classes.avatarContainer, children: _jsx(AvatarIcon, {}) })), isParticipantReconnecting && (_jsx("div", { className: classes.reconnectingContainer, children: _jsx(Typography, { variant: "body1", className: classes.typeography, children: t("ParticipantInfo.Reconnecting") }) }))] }), _jsx(Grid, { xs: true, item: true, style: { display: "flex", flexDirection: "column", width: "100%" }, children: _jsx(ParticipantTracks, { participant: participant, videoOnly: videoOnly, enableScreenShare: enableScreenShare, isLocalParticipant: isLocalParticipant }) })] }));
}
