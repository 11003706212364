/* eslint-disable */

import { useState } from 'react';
import { getMeetingName } from '../Services/LinkService';

export default function useLink(id: string) {
  const [meetingName, setMeetingName] = useState('');

  getMeetingName(id).then((name: string) => {
    setMeetingName(name);
  });

  return meetingName;
}
