var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GaussianBlurBackgroundProcessor, Pipeline, isSupported, } from '@twilio/video-processors';
import { useCallback, useEffect } from 'react';
import { SELECTED_BACKGROUND_SETTINGS_KEY } from '../../../constants';
import { useLocalStorageState } from '../../../hooks/useLocalStorageState/useLocalStorageState';
const assetsPath = process.env.PUBLIC_URL + '/virtualbackground';
let blurProcessor;
export default function useBackgroundBlur(videoTrack) {
    const [backgroundSettings, setBackgroundSettings] = useLocalStorageState(SELECTED_BACKGROUND_SETTINGS_KEY, {
        type: 'none',
    });
    const removeProcessor = useCallback(() => {
        if (videoTrack && videoTrack.processor) {
            videoTrack.removeProcessor(videoTrack.processor);
        }
    }, [videoTrack]);
    const addProcessor = useCallback((processor) => {
        if (!videoTrack || videoTrack.processor === processor) {
            return;
        }
        removeProcessor();
        const addProcessorOptions = {
            inputFrameBufferType: 'video',
            outputFrameBufferContextType: 'webgl2',
        };
        videoTrack.addProcessor(processor, addProcessorOptions);
    }, [videoTrack, removeProcessor]);
    useEffect(() => {
        if (!isSupported) {
            return;
        }
        const handleProcessorChange = () => __awaiter(this, void 0, void 0, function* () {
            if (backgroundSettings.type === 'blur') {
                if (!blurProcessor) {
                    blurProcessor = new GaussianBlurBackgroundProcessor({
                        assetsPath: assetsPath,
                        pipeline: Pipeline.WebGL2,
                        debounce: true,
                    });
                    yield blurProcessor.loadModel();
                }
                addProcessor(blurProcessor);
            }
            else {
                removeProcessor();
            }
        });
        handleProcessorChange();
    }, [videoTrack, backgroundSettings, addProcessor, removeProcessor]);
    return [backgroundSettings, setBackgroundSettings];
}
