import _get from 'lodash/get';

import enabledFlags from '../featureFlags.json';

export enum FeatureFlags {
  Chat = 'chat',
  Important = 'important',
  // TODO remove screener feature
  Screener = 'screener',
  ManualRegistration = 'manual-registration',
  HasAppointment = 'has-appointment',
  WaitingForTriage = 'waiting-for-triage',
  WaitingForChart = 'waiting-for-chart',
  PostTriage = 'post-triage',
  Completed = 'completed',
  PhoneOnly = 'phone-only',
}

const EnabledFeatures: FeatureFlags[] =
  (_get(enabledFlags, 'enabled') as FeatureFlags[]) || [];

export function isFeatureEnabled(feature: FeatureFlags) {
  return EnabledFeatures.includes(feature);
}

const isReady: Promise<void> = Promise.resolve();
export function featureFlagsReady(): Promise<void> {
  return isReady;
}
