import { CircularProgress, Grid } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState as useTwilioAppState } from 'twilio-client';
import useLink from '../../Hooks/useLink';
import { LinkCheckIn, LinkEnd, LinkMeeting } from '../../Screens';
import { validatePin } from '../../Services/PatronService';

enum Screen {
  CheckIn,
  InMeeting,
  Exit,
}

function CenteredSpinner() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

export default function LinkFlow() {
  const { shortId } = useParams() as any;
  const [screen, setScreen] = useState<Screen>(Screen.CheckIn);
  const meetingName = useLink(shortId);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { setPin } = useTwilioAppState();

  const isOn = (expected: Screen) => screen === expected;
  const goTo = (newScreen: Screen) => () => setScreen(newScreen);
  const displayWhen = (expected: Screen, elements: JSX.Element) =>
    isOn(expected) && elements;

  const onConnect = useCallback(
    (first: string, last: string, pin: string) => {
      setFirstName(first);
      setLastName(last);
      setPin(pin || ''); //set guest pin to empty string incase usePIN is false
      setScreen(Screen.InMeeting);
    },
    [setPin]
  );

  const validatorFn = useCallback(
    (pin: string) => validatePin(meetingName, pin),
    [meetingName]
  );
  return (
    <>
      {meetingName ? (
        <>
          {displayWhen(
            Screen.CheckIn,
            <LinkCheckIn onConnect={onConnect} validatePin={validatorFn} />
          )}
          {displayWhen(
            Screen.InMeeting,
            <LinkMeeting
              firstName={firstName}
              lastName={lastName}
              meetingName={meetingName}
              onLeaving={goTo(Screen.Exit)}
            />
          )}
          {displayWhen(Screen.Exit, <LinkEnd />)}
        </>
      ) : (
        <CenteredSpinner />
      )}
    </>
  );
}
