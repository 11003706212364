import { useEffect, useState } from 'react';
import { raam } from 'raam-client-lib';
export default function useRoomConfig(roomName) {
    const [roomConfig, setRoomConfig] = useState();
    useEffect(() => {
        if (!roomName) {
            return;
        }
        let o$ = raam.twilio.observeRoomConfig(roomName);
        const subscription = o$.subscribe((v) => setRoomConfig(v.properties));
        return () => subscription.unsubscribe();
    }, [roomName]);
    return roomConfig;
}
