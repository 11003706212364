import React from 'react';

type Props = {
  children: JSX.Element;
  fallback?: () => JSX.Element;
};

class ErrorBoundary extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    if ((this.state as any).hasError) {
      return (this.props as any).fallback ? (
        (this.props as any).fallback((this.state as any).error)
      ) : (
        <>
          <h1>Something went wrong, and there is no fallback content.</h1>;
        </>
      );
    }

    return children;
  }
}
export default ErrorBoundary;
