import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';

const useObservableEffect = <T>(
  observableOrFn?: Observable<T> | (() => Observable<T>),
  next: (value: T) => void = _.identity,
  error: (err: any) => void = _.identity
) => {
  useEffect(() => {
    let observable$;
    if (typeof observableOrFn === 'function') {
      observable$ = observableOrFn();
    } else {
      observable$ = observableOrFn;
    }
    if (observable$) {
      const subscription = observable$.subscribe({
        next,
        error,
      });
      return () => {
        subscription.unsubscribe();
      };
    }
    return undefined;
  }, [observableOrFn, next, error]);
};

const useMaybeObservable = <T>(
  observableOrFn?: Observable<T> | (() => Observable<T>),
  error?: (err: any) => void
) => {
  const [value, setValue] = useState<T>();
  useObservableEffect(observableOrFn, setValue, error);
  return value;
};

const useObservable = <T>(
  initialValue: T,
  observableOrFn?: Observable<T> | (() => Observable<T>),
  error?: (err: any) => void
) => {
  const [value, setValue] = useState<T>(initialValue);
  useObservableEffect(observableOrFn, setValue, error);
  return value;
};

export { useObservable, useMaybeObservable };
