import { useEffect, useState } from 'react';
import useClinicConfig from '../../../Hooks/useClinicConfig';
import {
  ChatView,
  ParticipantType,
  createChatView,
} from '../../../Services/Chat';
import MessagingDialog from './MessagingDialog/MessagingDialog';
import MessagingIndicator from './MessagingIndicator';
import SinglePanelLayout from './SinglePanel/SinglePanel';
import './SinglePanel/SinglePanel.scss';

function SinglePanelWithMessaging({
  children,
  title,
  footer,
  color,
  patron,
  maxWidth = 'sm',
  disablePadding = false,
  disableGutters = false,
  disableBuildNumber = true,
}: {
  children?: JSX.Element | JSX.Element[];
  title?: JSX.Element;
  footer?: JSX.Element;
  color?: String;
  patron: any;
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  disablePadding?: boolean;
  disableGutters?: boolean;
  disableBuildNumber?: boolean;
}) {
  const [chatPromise, setChatPromise] = useState<Promise<ChatView>>();
  const clinicConfig = useClinicConfig();

  useEffect(() => {
    const conversationId = patron?.properties?.conversationId;
    const patronId = patron?.id;
    if (conversationId && patronId) {
      const chat = createChatView(
        conversationId,
        ParticipantType.User,
        patronId
      );
      setChatPromise(chat);
    }
  }, [patron]);

  return (
    <>
      <MessagingDialog chat={chatPromise} patron={patron} />
      <SinglePanelLayout
        extraButton={<MessagingIndicator chat={chatPromise} />}
        logo={clinicConfig.logoName}
        title={title}
        footer={footer}
        color={color}
        maxWidth={maxWidth}
        disablePadding={disablePadding}
        disableGutters={disableGutters}
        disableBuildNumber={disableBuildNumber}
      >
        {children}
      </SinglePanelLayout>
    </>
  );
}

export default SinglePanelWithMessaging;
