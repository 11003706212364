import {
  Box,
  Button,
  Card,
  Grid,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import _ from 'lodash';
import { SnackbarContent, useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { TwilioVideoCall, useAppState, useVideoContext } from 'twilio-client';
import { SinglePanelWithMessaging } from '../../Components';
import ChatButton from '../../Components/ChatButton';
import MessagingDialog from '../../Components/Layout/SinglePanel/MessagingDialog/MessagingDialog';
import { ReactComponent as Unread } from '../../Components/Layout/SinglePanel/icons/chat_message_waiting.svg';
import { useChatContext } from '../../Contexts/ChatContext';
import DataContext from '../../Contexts/DataContext';
import { useMaybePromise, useObservable, usePrevious } from '../../Hooks';
import useApplicationConfig from '../../Hooks/useApplicationConfig';
import { ChatView, ParticipantType, createChatView } from '../../Services/Chat';
import { useExtendedTranslation } from '../../Services/i18nService';
import IPatron from '../../Types/IPatron';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: 'white',
    width: '100%',
    textAlign: 'left',
    padding: '0.5rem 0.5rem 0 0.5rem',
  },
  messageIcon: {
    verticalAlign: 'middle',
  },
  messageText: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '0.5rem',
  },
  tapButton: {
    color: '#027AC5',
  },
}));

const messageHasInitial = (s: string): boolean => _.get(s, 2) === '|';
const removeMaybeInitial = (s: string): string =>
  messageHasInitial(s) ? _.join(_.slice(s, 3), '') : s;

function Rejoin({
  onContinue,
  onRejoin,
}: {
  onContinue: Function;
  onRejoin: Function;
}) {
  const t = useExtendedTranslation();
  const applicationConfig = useApplicationConfig();

  return (
    <Grid container alignItems="center" direction="column" spacing={1}>
      <Grid item>
        <Typography paragraph variant="body2" color="primary">
          {ReactHtmlParser(
            applicationConfig.msgVisitComplete.primary[
              i18next.language as 'en' | 'fr'
            ]
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onContinue()}
        >
          {t('meeting.inProgress.continue')}
        </Button>
      </Grid>
      <Grid item>
        <Typography paragraph variant="body2" color="primary">
          {ReactHtmlParser(
            applicationConfig.msgVisitComplete.secondary[
              i18next.language as 'en' | 'fr'
            ]
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Button color="primary" variant="outlined" onClick={() => onRejoin()}>
          {t('meeting.inProgress.reconnect')}
        </Button>
      </Grid>
    </Grid>
  );
}

enum MeetingState {
  InTwilio,
  InRejoin,
}

function Meeting({
  patron,
  onContinue,
}: {
  patron: IPatron;
  onContinue: Function;
}) {
  useExtendedTranslation();
  const { room } = useVideoContext();
  const { applicationConfig } = useContext(DataContext);
  const { setRoom, setName, setIsClinician, setPin, setCaptioningAllowed } =
    useAppState();
  const [currentState, setCurrentState] = useState(
    patron.properties.meetingName
      ? MeetingState.InTwilio
      : MeetingState.InRejoin
  );
  const { showMessaging, updateShowMessaging } = useChatContext();
  const [chatPromise, setChatPromise] = useState<Promise<ChatView>>();
  const chatUnwrapped = useMaybePromise(chatPromise);
  const hasUnreadMessages = useObservable(
    false,
    chatUnwrapped?.hasUnreadMessages
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const messagesList = useObservable([], chatUnwrapped?.messages);
  const previous = usePrevious(messagesList);
  const classes = useStyles();
  const t = useExtendedTranslation();

  const getContent = useCallback(
    (messageToDisplay: string) => {
      return (
        <SnackbarContent className={classes.root}>
          <Card className={classes.card}>
            <Typography variant="body1">
              <SvgIcon component={Unread} className={classes.messageIcon} />
              {`  ${t('messagenotif.newmessage')}`}
            </Typography>
            <Typography variant="body1" className={classes.messageText}>
              &quot;{removeMaybeInitial(messageToDisplay)}&quot;
            </Typography>
            <Box textAlign="center">
              <Button
                onClick={() => updateShowMessaging(true)}
                variant="text"
                className={classes.tapButton}
              >
                {t('messagenotif.respond')}
              </Button>
            </Box>
          </Card>
        </SnackbarContent>
      );
    },
    [
      classes.card,
      classes.messageIcon,
      classes.messageText,
      classes.root,
      classes.tapButton,
      t,
      updateShowMessaging,
    ]
  );

  useEffect(() => {
    setRoom(patron.properties.meetingName);
    setName(`${patron.properties.firstName} ${patron.properties.lastName}`);
    setIsClinician(false);

    // set pin for patron to empty string in case usePIN is false
    setPin(patron.properties.meetingPin || '');
    setCaptioningAllowed(_.get(applicationConfig, 'captioningAllowed', false));
  }, [
    patron,
    setRoom,
    setName,
    setIsClinician,
    applicationConfig,
    setCaptioningAllowed,
    setPin,
  ]);

  useEffect(() => {
    const conversationId = patron?.properties?.conversationId;
    const patronId = patron?.id;
    if (conversationId && patronId) {
      const chat = createChatView(
        conversationId,
        ParticipantType.User,
        patronId
      );
      setChatPromise(chat);
    }
  }, [patron]);

  useEffect(() => {
    setCurrentState(
      patron.properties.meetingName
        ? MeetingState.InTwilio
        : MeetingState.InRejoin
    );
  }, [patron.properties.meetingName]);

  useEffect(() => {
    if (room) {
      const setInRejoin = () => setCurrentState(MeetingState.InRejoin);
      room.on('disconnected', setInRejoin);
      return () => {
        room.off('disconnected', setInRejoin);
        room.disconnect();
      };
    }
    return () => undefined;
  }, [room]);

  useEffect(() => {
    if (!showMessaging && hasUnreadMessages) {
      if (_.size(previous) < _.size(messagesList)) {
        const messageToDisplay = _.get(_.last(messagesList), 'message', '');
        enqueueSnackbar('', {
          key: messageToDisplay,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          content: getContent(messageToDisplay),
          preventDuplicate: true,
          persist: false,
        });
      }
    } else if (showMessaging) {
      closeSnackbar();
    }
  }, [
    previous,
    hasUnreadMessages,
    showMessaging,
    messagesList,
    enqueueSnackbar,
    closeSnackbar,
    getContent,
  ]);

  return (
    <Box display="flex" flex={1} className="meeting-screen">
      <Box display="flex" flex={1} className="meeting-in-progress">
        {currentState === MeetingState.InTwilio ? (
          <>
            <TwilioVideoCall
              optionButtons={[
                <ChatButton
                  chat={chatPromise}
                  onClick={() => updateShowMessaging(true)}
                />,
              ]}
            />
            <MessagingDialog chat={chatPromise} patron={patron} />
          </>
        ) : (
          <SinglePanelWithMessaging patron={patron}>
            <Rejoin
              onContinue={onContinue}
              onRejoin={() =>
                patron.properties.meetingName
                  ? setCurrentState(MeetingState.InTwilio)
                  : window.open(patron.properties.meetingUrl)
              }
            />
          </SinglePanelWithMessaging>
        )}
      </Box>
    </Box>
  );
}

export default Meeting;
