
/**
 * @description Creates a deferred promise which returns the promise, and the resolve and reject functions.
 * @returns {Object} An object with the following members:
 *  - promise: a promise that can be used to wait for a result.
 *  - resolveFn: a function that can be called to resolve the promise.
 *  - rejectFn: a function that can be called to reject the promise.
 */
function DeferredPromise() {
  let resolveFn, rejectFn;
  let promise = new Promise((resolve, reject) => {
    resolveFn = resolve;
    rejectFn = reject;
  });

  return { promise , resolveFn, rejectFn };
}

module.exports = DeferredPromise;
