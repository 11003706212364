import { useEffect, useState } from "react";
export function useLocalStorageState(key, initialState) {
    const [value, setValue] = useState(() => {
        const item = window.localStorage.getItem(key);
        let val = initialState;
        try {
            if (item !== null) {
                val = JSON.parse(item);
            }
        }
        catch (e) {
            console.error(e);
        }
        return val;
    });
    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
}
