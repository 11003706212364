/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import NumberFormat from 'react-number-format';

function NumberFormatPhone({ inputRef, onChange, ...other }: any) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={
        onChange
          ? (values: any) => {
              onChange({
                target: {
                  value: values.value,
                },
              });
            }
          : () => {}
      }
      type="tel"
      mask=" "
      format="(###) ###-####"
      isNumericString
    />
  );
}

function NumberFormatPhoneCustomTextOnly(props: any) {
  return NumberFormatPhone({
    displayType: 'text',
    renderText: (v: any) => v,
    ...props,
  });
}

export { NumberFormatPhone, NumberFormatPhoneCustomTextOnly };
