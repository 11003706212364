var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Redirect, Route } from "react-router-dom";
import { useAppState } from "../../state";
export default function PrivateRoute(_a) {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const { isAuthReady, user } = useAppState();
    const renderChildren = user || !process.env.REACT_APP_SET_AUTH;
    if (!renderChildren && !isAuthReady) {
        return null;
    }
    return (_jsx(Route, Object.assign({}, rest, { render: ({ location }) => renderChildren ? (children) : (_jsx(Redirect, { to: {
                pathname: "/login",
                state: { from: location }
            } })) })));
}
