import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { SnackbarProvider } from 'notistack';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  ErrorDialog,
  useAppState,
  useConnectionOptions,
  VideoProvider,
} from 'twilio-client';
import buildNumber from '../build-number';
import { ChatProvider } from '../Contexts/ChatContext';
import DataContext, { IDataContext } from '../Contexts/DataContext';
import LinkFlow from '../Flows/LinkFlow/LinkFlow';
import StatesFlow from '../Flows/StatesFlow/StatesFlow';
import SurveyFlow from '../Flows/SurveyFlow/SurveyFlow';
import { useLocalStorage } from '../Hooks';
import { watchForInternetConnectionRestored } from '../Services/InternetConnectionService';
import '../Services/max-uptime';
import { checkInPatron } from '../Services/PatronService';
import { IPatronIds, ISurveyData } from '../Types';
import './App.scss';

watchForInternetConnectionRestored().subscribe(() => window.location.reload());

function setResizeTrick() {
  // Trick, to make sure we have the correct VH (viewport) on mobile phone when they show header and footer bar.
  // Reference: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const setProperty = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  window.addEventListener('resize', setProperty);
  setProperty();
}

const useStyles = makeStyles(() => ({
  snackbar: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

function CenteredSpinner() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

function RenderIfLoaded({ children }: { children: PropsWithChildren<any> }) {
  const { isLoaded } = useContext<IDataContext>(DataContext);

  return <>{!isLoaded ? <CenteredSpinner /> : children}</>;
}

function App() {
  setResizeTrick();
  useEffect(() => console.log(`RAAM inQ version: ${buildNumber}`), []);

  const [patronIds, setPatronIds] = useLocalStorage('patronIds', undefined);
  const [surveyError, setSurveyError] = useState(false);

  const checkIn = (
    newPatron: ISurveyData,
    initDateTime: string,
    denialType?: string
  ) => {
    return checkInPatron(newPatron, initDateTime, denialType)
      .then((r: IPatronIds) => {
        if (!denialType) {
          setPatronIds(r);
        }
      })
      .catch(() => {
        setPatronIds(undefined);
        setSurveyError(true);
      });
  };

  const unloadPatron = () => {
    setPatronIds(undefined);
  };

  const classes = useStyles();
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <Router>
        <RenderIfLoaded>
          <SnackbarProvider
            maxSnack={1}
            autoHideDuration={3000}
            classes={{
              variantInfo: classes.snackbar,
              variantError: classes.snackbar,
            }}
            iconVariant={{
              error: (
                <InfoIcon style={{ fontSize: '20px', marginRight: '8px' }} />
              ),
            }}
          >
            <Switch>
              <Route path="/" exact>
                {!patronIds ? (
                  <SurveyFlow
                    onCheckIn={checkIn}
                    error={surveyError}
                    setError={setSurveyError}
                  />
                ) : (
                  <ChatProvider>
                    <StatesFlow
                      patron={patronIds}
                      unloadPatron={unloadPatron}
                    />
                  </ChatProvider>
                )}
              </Route>
              <Route path="/j/:shortId">
                <LinkFlow />
              </Route>
              <Route render={() => <Redirect to={{ pathname: '/' }} />} />
            </Switch>
          </SnackbarProvider>
        </RenderIfLoaded>
      </Router>
    </VideoProvider>
  );
}

export default App;
