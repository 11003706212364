import React from 'react';
import SevenSegmentDigit from '../SevenSegmentDigit/SevenSegmentDigit';
import CountdownTimerSize from '../types';
import './SevenSegmentDisplay.scss';

function DotDot({
  size = CountdownTimerSize.NORMAL,
}: {
  size?: CountdownTimerSize;
}) {
  return (
    <div className={`seven-segment-dotdot ${size}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 6 20">
        <circle id="dotdot" cx="2" cy="5" r="1" />
        <circle id="dotdot" cx="2" cy="12" r="1" />
      </svg>
    </div>
  );
}

function SevenSegmentDisplay({
  message,
  size = CountdownTimerSize.NORMAL,
  pad = 0,
}: {
  message: string | number;
  size?: CountdownTimerSize;
  pad?: number;
}) {
  const messageAsString = `${message}`;
  const characters = messageAsString.split('');
  while (pad && characters.length < pad) {
    characters.unshift('');
  }
  const digits = characters.map((c, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={i}>
      {c === ':' && <DotDot size={size} />}
      {c !== ':' && <SevenSegmentDigit character={c} size={size} />}
    </React.Fragment>
  ));

  return <div className="seven-segment-panel">{digits}</div>;
}

export default SevenSegmentDisplay;
