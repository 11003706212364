import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Link } from "@material-ui/core";
import Snackbar from "../Snackbar/Snackbar";
import useIsRecording from "../../hooks/useIsRecording/useIsRecording";
var Snackbars;
(function (Snackbars) {
    Snackbars[Snackbars["none"] = 0] = "none";
    Snackbars[Snackbars["recordingStarted"] = 1] = "recordingStarted";
    Snackbars[Snackbars["recordingInProgress"] = 2] = "recordingInProgress";
    Snackbars[Snackbars["recordingFinished"] = 3] = "recordingFinished";
})(Snackbars || (Snackbars = {}));
export default function RecordingNotifications() {
    const [activeSnackbar, setActiveSnackbar] = useState(Snackbars.none);
    const prevIsRecording = useRef(null);
    const isRecording = useIsRecording();
    useEffect(() => {
        // Show "Recording in progress" snackbar when a user joins a room that is recording
        if (isRecording && prevIsRecording.current === null) {
            setActiveSnackbar(Snackbars.recordingInProgress);
        }
    }, [isRecording]);
    useEffect(() => {
        // Show "Recording started" snackbar when recording has started.
        if (isRecording && prevIsRecording.current === false) {
            setActiveSnackbar(Snackbars.recordingStarted);
        }
    }, [isRecording]);
    useEffect(() => {
        // Show "Recording finished" snackbar when recording has stopped.
        if (!isRecording && prevIsRecording.current === true) {
            setActiveSnackbar(Snackbars.recordingFinished);
        }
    }, [isRecording]);
    useEffect(() => {
        prevIsRecording.current = isRecording;
    }, [isRecording]);
    return (_jsxs(_Fragment, { children: [_jsx(Snackbar, { open: activeSnackbar === Snackbars.recordingStarted, handleClose: () => setActiveSnackbar(Snackbars.none), variant: "info", headline: "Recording has started.", message: "" }), _jsx(Snackbar, { open: activeSnackbar === Snackbars.recordingInProgress, handleClose: () => setActiveSnackbar(Snackbars.none), variant: "info", headline: "Recording is in progress.", message: "" }), _jsx(Snackbar, { open: activeSnackbar === Snackbars.recordingFinished, headline: "Recording Complete", message: _jsxs(_Fragment, { children: ["You can view the recording in the", " ", _jsx(Link, { target: "_blank", rel: "noopener", href: "https://www.twilio.com/console/video/logs/recordings", children: "Twilio Console" }), ". Recordings will be available once this room has ended."] }), variant: "info", handleClose: () => setActiveSnackbar(Snackbars.none) })] }));
}
