import React from 'react';
import MaskedInput from 'react-text-mask';

export function RegistrationCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9a-zA-Z]/,
        /[0-9a-zA-Z]/,
        /[0-9a-zA-Z]/,
        /[0-9a-zA-Z]/,
        /[0-9a-zA-Z]/,
        /[0-9a-zA-Z]/,
      ]}
      guide={false}
    />
  );
}

export default RegistrationCustom;
