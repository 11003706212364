import React, { MouseEventHandler } from 'react';
import './ThankYouForWaiting.scss';
import { Box, Button, Grid, useTheme, withStyles } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import i18next from 'i18next';
import { SinglePanelWithMessaging } from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import useApplicationConfig from '../../Hooks/useApplicationConfig';

const LeaveQueueButton = withStyles({
  root: {
    padding: 0,
  },
  label: {
    color: '#077ad3',
    fontWeight: 600,
    textDecoration: 'underline',
    fontStyle: 'italic',
    textTransform: 'none',
    fontSize: '14px',
  },
})(Button);

function ThankYouForWaiting({
  onLeave,
  patron,
}: {
  onLeave: MouseEventHandler<HTMLElement>;
  patron: any;
}) {
  const theme = useTheme();
  const applicationConfig = useApplicationConfig();
  const t = useExtendedTranslation();

  return (
    <Box display="flex" flex={1} className="thank-you-for-waiting">
      <SinglePanelWithMessaging
        patron={patron}
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <LeaveQueueButton variant="text" onClick={onLeave}>
                {t('thankyou.leave')}
              </LeaveQueueButton>
            </Grid>
          </Grid>
        }
      >
        <span style={{ color: theme.palette.primary.main }}>
          {ReactHtmlParser(
            applicationConfig.msgWaiting[i18next.language as 'en' | 'fr']
          )}
        </span>
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default ThankYouForWaiting;
