import { useMemo } from 'react';
import * as UAParser from 'ua-parser-js';

const uaParser = new UAParser.UAParser();

// Access window.navigator.userAgent only once, this prevents browser from re-rendering
const defaultUAString = window.navigator.userAgent;

function useUAParser(userAgentStr = defaultUAString) {
  return useMemo<string | null>(() => {
    try {
      uaParser.setUA(userAgentStr);
      return uaParser.getResult().ua;
    } catch (err) {
      return null;
    }
  }, [userAgentStr]);
}

export default useUAParser;
