export enum RegionNames {
  Winnipeg = 'Winnipeg/Churchill',
  Interlake = 'Interlake-Eastern',
  Southern = 'Southern',
  Prairie = 'Prairie Mountain',
  Northern = 'Northern',
  None = 'None',
}

export const ClinicRegionDetails = {
  Winnipeg: {
    en: 'Crisis Response Centre, Aboriginal Health and Wellness Centre & River Point Centre clinics',
    fr: 'Cliniques: Centre d’intervention d’urgence en santé mentale, Aboriginal Health and Wellness Centre et River Point Centre',
  },
  Interlake: {
    en: 'Selkirk clinic',
    fr: 'Clinique de Selkirk',
  },
  Southern: {
    en: 'Portage la Prairie clinic',
    fr: 'Clinique de Portage la Prairie',
  },
  Prairie: {
    en: 'Brandon clinic',
    fr: 'Clinique de Brandon',
  },
  Northern: {
    en: 'Thompson clinic',
    fr: 'Clinique de Thompson',
  },
};
