import {
  AppBar,
  Box,
  Container,
  Grid,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import {
  SupportedLanguages,
  useExtendedTranslation,
} from '../../../../Services/i18nService';
import BuildNumber from '../../../BuildNumber/BuildNumber';
import LanguageSelector from '../../../LanguageSelector/LanguageSelector';
import FrostedGlassPanel from '../FrostedGlassPanel/FrostedGlassPanel';
import './SinglePanel.scss';
import InQLogoIcon from './icons/InQ.svg';
import { useFeatureFlags } from '../../../../Hooks/useFeatureFlags';

function SinglePanelLayout({
  children = <></>,
  logo,
  title,
  footer,
  color = '',
  extraButton = <></>,
  maxWidth = 'sm',
  disablePadding = false,
  disableGutters = false,
  disableBuildNumber = true,
  ariaLive = 'polite',
}: {
  children?: JSX.Element | JSX.Element[];
  logo?: string;
  title?: JSX.Element;
  footer?: JSX.Element;
  color?: String;
  extraButton?: JSX.Element;
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  disablePadding?: boolean;
  disableGutters?: boolean;
  disableBuildNumber?: boolean;
  ariaLive?: 'off' | 'polite' | 'assertive';
}) {
  const t = useExtendedTranslation();
  const theme = useTheme();
  const isMediaXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { REACT_APP_DISABLE_FRENCH } = useFeatureFlags();

  return (
    <Grid
      container
      direction="column"
      className="single-panel-layout"
      wrap="nowrap"
    >
      <Grid item>
        <AppBar position="relative" color="transparent">
          <Toolbar>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              spacing={1}
            >
              <Grid
                item
                xs
                style={{
                  height: isMediaXs ? '50px' : '60px',
                }}
              >
                {logo && (
                  <img
                    style={{
                      objectFit: 'contain',
                      maxWidth: '100%',
                      height: '100%',
                    }}
                    src={logo}
                    alt={t('SinglePanel.logo.alt')}
                  />
                )}
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={1} wrap="nowrap">
                  <Grid item>{extraButton}</Grid>
                  {!REACT_APP_DISABLE_FRENCH && (
                    <Grid item>
                      <LanguageSelector
                        supportedLanguages={[
                          SupportedLanguages.English,
                          SupportedLanguages.French,
                        ]}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Box paddingLeft={1}>
                      <img className="inQLogo" src={InQLogoIcon} alt="InQ" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item xs>
        <Container
          maxWidth={maxWidth}
          className="container"
          disableGutters={disableGutters}
          aria-live={ariaLive}
        >
          {_.isNil(color) && (
            <FrostedGlassPanel
              title={title}
              content={children}
              footer={footer}
              disablePadding={disablePadding}
            />
          )}
          {!_.isNil(color) && (
            <FrostedGlassPanel
              title={title}
              content={children}
              footer={footer}
              color={color}
              disablePadding={disablePadding}
            />
          )}
        </Container>
      </Grid>
      <Grid item container justifyContent="center">
        {disableBuildNumber ? <Box padding={1} /> : <BuildNumber />}
      </Grid>
    </Grid>
  );
}

export default SinglePanelLayout;
