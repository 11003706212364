const _ = require('lodash');
const DeferredPromise = require('./DeferredPromise');

const waitForInvocationReaction = (bee, processReactionFn = _.identity, timeout) => async res => {
  const invocationId = _.get(res, 'invocationId');

  let { promise, resolveFn, rejectFn } = new DeferredPromise();

  const extractResponseFromReaction = reaction => {
    const details = _.get(reaction, 'details', {});
    if (details.code) {
      rejectFn(details);
    } else {
      resolveFn(processReactionFn(reaction));
    }
  };

  // If we have an invocationId, check if the reaction is in the bee's array of last core reactions already
  if (invocationId) {
    const invocationLabel = `invocation.${invocationId}`;
    const lastReactions = _.get(bee, 'lastReactions', []);
    for (let reaction of lastReactions) {
      if (_.includes(reaction.cc, invocationLabel)) {
        extractResponseFromReaction(reaction);
        return promise;
      }
    }
  }

  // Reaction is not in the existing reactions, so we wait
  let timeoutId;
  if (timeout) {
    const timeoutFn = () => rejectFn({ code: -1, message: `timeout waiting for reaction to ${invocationId}` });
    timeoutId = setTimeout(timeoutFn, timeout);
  }

  const callbackId = bee.reactions.setCallback(`core:invocation.${invocationId}`, reaction => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const details = _.get(reaction, 'details', {});
    if (details.code) {
      rejectFn(details);
    } else {
      resolveFn(processReactionFn(reaction));
    }
  });

  try {
    return await promise;
  } finally {
    bee.reactions.removeCallback(callbackId);
  }
};

const extractInfo = (id, numberItems = []) => paramsStr => {
  const params = new URLSearchParams(paramsStr);

  const info = _.pickBy({ id });

  for (let [ key, value ] of params.entries()) {
    _.set(info, key, value);
  };

  numberItems = _.compact(_.castArray(numberItems));
  for (let numberItem of numberItems) {
    const value = _.get(info, numberItem);
    if (typeof(value) === 'string') {
      _.set(info, numberItem, parseInt(value));
    }
  }

  return info;
};

module.exports = {
  waitForInvocationReaction,
  extractInfo,
};
