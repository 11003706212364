import { useEffect, useState } from 'react';

function getBrowserVisibilityProp() {
  // Opera 12.10 and Firefox 18 and later support
  if (typeof (document as any).msHidden !== 'undefined') {
    return 'msvisibilitychange';
  }
  if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
  return 'visibilitychange';
}

function getBrowserDocumentHiddenProp(): string {
  // Opera 12.10 and Firefox 18 and later support
  if (typeof (document as any).msHidden !== 'undefined') {
    return 'msHidden';
  }
  if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
  return 'hidden';
}

function getIsDocumentVisible() {
  return !(document as any)[getBrowserDocumentHiddenProp()];
}

function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(getIsDocumentVisible());
  const onVisibilityChange = () => setIsVisible(getIsDocumentVisible());

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}

export default usePageVisibility;
