/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line prettier/prettier

import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { SinglePanelLayout, TextBlockHint } from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import useClinicConfig from '../../Hooks/useClinicConfig';

export default function ClosedQuestion({
  questionKey,
  subKey,
  onYes,
  onNo,
}: {
  questionKey: string;
  subKey?: string | undefined;
  onYes: Function;
  onNo: Function;
}) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();

  return (
    <Box display="flex" flex={1}>
      <SinglePanelLayout
        title={
          <Typography variant="h5" color="primary" align="center">
            {t(questionKey)}
          </Typography>
        }
        footer={
          <Grid container justifyContent="space-evenly">
            <Grid item xs={3}>
              <Button
                variant="outlined"
                onClick={() => onYes()}
                color="primary"
                fullWidth
              >
                {t('questionnaire.yes.button')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                onClick={() => onNo()}
                color="primary"
                fullWidth
              >
                {t('questionnaire.no.button')}
              </Button>
            </Grid>
          </Grid>
        }
        logo={clinicConfig.logoName}
      >
        <>{subKey && <TextBlockHint>{t(subKey)}</TextBlockHint>}</>
      </SinglePanelLayout>
    </Box>
  );
}
