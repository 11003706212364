import { Paper } from '@material-ui/core';
import moment from 'moment';
import SevenSegmentDisplay from '../SevenSegmentDisplay/SevenSegmentDisplay';
import CountdownTimerSize from '../types';
import './CountdownTimer.scss';

/**
 * A function to stringify the number of seconds in h:mm:ss format
 */
export function formatDuration(seconds: number) {
  let absSeconds;
  if (seconds < 0) {
    absSeconds = 0;
  } else {
    absSeconds = seconds;
  }

  const duration = moment.duration(absSeconds, 'seconds');
  const formattable = moment.utc(duration.asMilliseconds());

  if (duration.asHours() >= 1) {
    return formattable.format('h:mm:ss');
  }
  return formattable.format('m:ss');
}

function CountdownTimer({
  seconds,
  size = CountdownTimerSize.NORMAL,
}: {
  seconds: number;
  size?: CountdownTimerSize;
}) {
  let timerColor;
  if (seconds <= 30) {
    if (seconds <= 0) {
      timerColor = 'red';
    } else {
      timerColor = 'orange';
    }
  } else {
    timerColor = 'blue';
  }

  return (
    <div className="countdown-timer" role="timer">
      <Paper id="timerbox" elevation={4} className={size}>
        {timerColor === 'blue' && (
          <div className="blue-timer">
            <SevenSegmentDisplay
              message={formatDuration(seconds)}
              pad={3}
              size={size}
            />
          </div>
        )}
        {timerColor === 'orange' && (
          <div className="orange-timer">
            <SevenSegmentDisplay
              message={formatDuration(seconds)}
              pad={3}
              size={size}
            />
          </div>
        )}
        {timerColor === 'red' && (
          <div className="red-timer">
            <SevenSegmentDisplay
              message={formatDuration(seconds)}
              pad={3}
              size={size}
            />
          </div>
        )}
      </Paper>
    </div>
  );
}

export default CountdownTimer;
