import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useWrappedTranslation } from "../../utils/i18nService";
function ErrorDialog({ dismissError, error }) {
    const { message, code } = error || {};
    const t = useWrappedTranslation();
    function enhanceMessage() {
        switch (code) {
            case 20101: // Invalid token error
                return (message + ". Please make sure you are using the correct credentials.");
            case 54000: // Invalid get room token
                return t("ErrorDialog.54000");
            default:
                return message;
        }
    }
    const enhancedMessage = enhanceMessage();
    return (_jsxs(Dialog, { open: error !== null && code !== 53118, onClose: () => dismissError(), fullWidth: true, maxWidth: "xs", children: [_jsx(DialogTitle, { children: t("ErrorDialog.title") }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: enhancedMessage }), Boolean(code) && (_jsx("pre", { children: _jsx("code", { children: code }) }))] }), _jsx(DialogActions, { children: _jsx(Button, { onClick: () => dismissError(), color: "primary", autoFocus: true, children: "OK" }) })] }));
}
export default ErrorDialog;
