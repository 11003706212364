import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import {
  SinglePanelLayout,
  TextBlockDescription,
  TextBlockHint,
} from '../../Components';
import useClinicConfig from '../../Hooks/useClinicConfig';
import { useExtendedTranslation } from '../../Services/i18nService';
import './ScreenHealthCardNeeded.scss';
import CaptureHealthCardImage from './icons/CaptureHealthCard.jpg';

function ScreenHealthCardNeeded({
  onCamera,
  onText,
}: {
  onCamera: Function;
  onText: Function;
}) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();

  return (
    <Box display="flex" flex={1} className="screen-health-card-needed">
      <SinglePanelLayout
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                onClick={() => onCamera()}
                variant="contained"
                color="primary"
              >
                {t('questionnaire.continue.button')}
              </Button>
            </Grid>
          </Grid>
        }
        logo={clinicConfig.logoName}
      >
        <TextBlockDescription>
          {t('healthcardneeded.description')}
        </TextBlockDescription>
        <TextBlockHint>{t('healthcardneeded.hint')}</TextBlockHint>
        <TextBlockHint>{t('healthcardneeded.hint2')}</TextBlockHint>
        <Grid container>
          <Grid item>
            <img
              src={CaptureHealthCardImage}
              alt={t('healthcardcaptureimage.alt')}
              className="capture-health-image"
            />
          </Grid>
        </Grid>
        <div className="screen-health-card-needed-skip">
          <TextBlockHint>
            <a href="# " onClick={() => onText()}>
              {t('healthcardneeded.skip')}
            </a>
          </TextBlockHint>
        </div>
      </SinglePanelLayout>
    </Box>
  );
}

export default ScreenHealthCardNeeded;
