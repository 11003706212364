import { MapFn } from '../../Util/util';

/**
 * Represents a sync object/value storage
 */
export interface SyncStorage {
  /**
   * Retrieves the object stored at the specified key
   * @param key The key to fetch
   */
  getItem<T>(key: string): T | undefined;
  /**
   * Stores an object at the specified key
   * @param key The key where to store the object
   * @param value The object to be stored
   */
  setItem<T>(key: string, value: T): void;

  /**
   * Removes an object with the specified key
   * @param key the key of the object to remove
   */
  removeItem(key: string): void;
}

function namespace(prefix: string): MapFn<string, string> {
  return (key: string) => prefix + key;
}

function serialize<T>(value: T): string {
  return JSON.stringify({ v: value });
}

function deserialize<T>(json: string | null): T {
  return json ? JSON.parse(json).v : null;
}

function combinePrefixDelimiter(prefix: string, delimiter: string): string {
  const useDelimiter = prefix && prefix.length > 0;
  return useDelimiter ? prefix + (delimiter || '') : prefix;
}

class SyncStorageImplementation implements SyncStorage {
  private ns: MapFn<string, string>;

  constructor(
    prefix: string,
    private storage: Storage,
    delimiter: string = '.'
  ) {
    this.ns = namespace(combinePrefixDelimiter(prefix, delimiter));
  }

  getItem<T>(key: string): T | undefined {
    return deserialize(this.storage.getItem(this.ns(key)));
  }

  setItem<T>(key: string, value: T) {
    this.storage.setItem(this.ns(key), serialize(value));
  }

  removeItem(key: string) {
    this.storage.removeItem(this.ns(key));
  }
}

type AllowedStorage = 'localstorage' | 'sessionstorage';

export function createSyncStorageInterface(
  prefix: string = '',
  storage: AllowedStorage = 'localstorage'
): SyncStorage {
  return new SyncStorageImplementation(
    prefix,
    storage === 'localstorage' ? localStorage : sessionStorage
  );
}
