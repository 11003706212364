import { Box, Button, Grid, TextField } from '@material-ui/core';
import _ from 'lodash';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ChatView } from '../../../Services/Chat';
import { useExtendedTranslation } from '../../../Services/i18nService';
import { PatronState } from '../../../Types/PatronState';
import './ChatComposer.scss';
import SendDisabledIcon from './icons/send-inactive.svg';
import SendIcon from './icons/send.svg';

const MAX_MESSAGE_LENGTH = 2048;
const WARNING_MESSAGE_LENGTH = MAX_MESSAGE_LENGTH - 200;

function ChatComposer({ chat, patron }: { chat: ChatView; patron?: any }) {
  const t = useExtendedTranslation();
  const [message, setMessage] = useState('');
  const isValidMessage = useMemo(() => {
    return (
      !_.isEmpty(message) &&
      message.replace(/(?:\r\n|\r|\n)/g, '').trim().length > 0 &&
      message.length <= MAX_MESSAGE_LENGTH
    );
  }, [message]);
  const [showCharacterLimitWarning, setShowCharacterLimitWarning] =
    useState(false);

  const updateMessage = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage((prevMsg: string) => {
      // fix bug where textarea is inserting new line on submit
      if (_.isEmpty(prevMsg)) {
        return _.trim(e.target.value);
      }
      return e.target.value;
    });
    if (e.target.value.length >= WARNING_MESSAGE_LENGTH) {
      return setShowCharacterLimitWarning(true);
    }
    return setShowCharacterLimitWarning(false);
  }, []);

  const getFirstCharOrSpace = (s: string) => _.get(s, 0, ' ');

  const send = useCallback(() => {
    const { firstName, lastName } = patron?.properties || {};

    const initials = `${getFirstCharOrSpace(firstName)}${getFirstCharOrSpace(
      lastName
    )}`;

    chat.send({ message: `${initials}|${message}` });
    setMessage('');
    setShowCharacterLimitWarning(false);
  }, [message, chat, setMessage, patron]);

  const typing = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && isValidMessage) {
        send();
      } else {
        chat.userIsTyping();
      }
    },
    [chat, send, isValidMessage]
  );

  if (
    _.includes(
      [PatronState.Completed, PatronState.Ejected, PatronState.Left],
      patron?.properties?.state
    )
  ) {
    return null;
  }

  return (
    <div className="chat-composer">
      <div className="composer-inner">
        <Grid container alignItems="center">
          <Grid item xs>
            <Box paddingLeft={1}>
              <TextField
                type="text"
                hiddenLabel
                multiline
                fullWidth
                minRows={1}
                maxRows={3}
                value={message}
                placeholder={t('chat.enterMessage')}
                onKeyDown={typing}
                onChange={updateMessage}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: 0,
                    height: '100%',
                  },
                }}
                inputProps={{
                  maxLength: MAX_MESSAGE_LENGTH,
                }}
                error={showCharacterLimitWarning}
                helperText={
                  showCharacterLimitWarning
                    ? t('chat.textLengthWarning', {
                        length: MAX_MESSAGE_LENGTH - message.length,
                      })
                    : ''
                }
                FormHelperTextProps={{ style: { paddingLeft: 10 } }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Button disabled={!isValidMessage} onClick={send}>
              {t('chat.sendButton')}
              {isValidMessage && <img src={SendIcon} alt="send" />}
              {!isValidMessage && (
                <img src={SendDisabledIcon} alt="send disabled" />
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ChatComposer;
