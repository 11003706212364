import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import { raam } from 'raam-client-lib';
import { createContext, useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { CAPTION_DISPLAY_TIMEOUT, DEFAULT_CAPTION_LANGUAGE_CODE, SELECTED_AUDIO_INPUT_KEY, } from '../../constants';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import useCCToken from '../../utils/useCCToken';
import useDataTrack from '../../utils/useDataTrack';
import useIdentity from '../../utils/useIdentity';
import useRoomConfig from '../../utils/useRoomConfig';
import useSpeechRecognizer from '../../utils/useSpeechRecognizer';
import DataMessage from '../DataTrack/DataMessage';
export const CaptionContext = createContext(null);
export function useCaption() {
    return useContext(CaptionContext);
}
export const CaptionProvider = ({ children }) => {
    const [captions, setCaptions] = useState({});
    const captionsRef = useRef({});
    const [locallyDisplayCaptions, setLocallyDisplayCaptions] = useState(false);
    const [message, setMessage] = useState('');
    const { room } = useVideoContext();
    const { room: roomName } = useAppState();
    const roomConfig = useRoomConfig(roomName);
    const { ccToken, ccDisabled } = useCCToken(room === null || room === void 0 ? void 0 : room.name);
    const [languageCode, setLanguageCode] = useState((roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage) || DEFAULT_CAPTION_LANGUAGE_CODE);
    const [isAudioEnabled] = useLocalAudioToggle();
    const micSource = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    const recognizer = useSpeechRecognizer(ccToken, languageCode, micSource, setMessage);
    const identity = useIdentity(room);
    const dataTrack = useDataTrack(room);
    useEffect(() => {
        if (isAudioEnabled && (roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participantsCaptioning.length)) {
            recognizer === null || recognizer === void 0 ? void 0 : recognizer.startContinuousRecognitionAsync();
            return;
        }
        recognizer === null || recognizer === void 0 ? void 0 : recognizer.stopContinuousRecognitionAsync();
    }, [recognizer, isAudioEnabled, roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participantsCaptioning]);
    const enqueueCaptionFn = useCallback((msg) => {
        setCaptions((prev) => {
            return Object.assign(Object.assign({}, prev), { [msg.source]: {
                    details: msg.details,
                    timeStamp: msg.timeStamp,
                } });
        });
        captionsRef.current = Object.assign(Object.assign({}, captionsRef.current), { [msg.source]: {
                details: msg.details,
                timeStamp: msg.timeStamp,
            } });
    }, []);
    useEffect(() => {
        if (!isAudioEnabled || !message || !identity) {
            return;
        }
        // Get the LocalDataTrack that we published to the room.
        const captionMessage = new DataMessage('captionData', identity, message, Date.now());
        dataTrack === null || dataTrack === void 0 ? void 0 : dataTrack.send(captionMessage);
        enqueueCaptionFn(captionMessage);
    }, [enqueueCaptionFn, isAudioEnabled, message, identity, dataTrack]);
    useEffect(() => {
        if (roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage) {
            setLanguageCode(roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage);
        }
    }, [roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage]);
    useEffect(() => {
        const interval = setInterval(() => {
            const nextCaptions = {};
            _.map(roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participants, (pid) => {
                const previousCaptionEntry = captionsRef.current[pid];
                if (previousCaptionEntry &&
                    previousCaptionEntry.timeStamp + CAPTION_DISPLAY_TIMEOUT > Date.now()) {
                    nextCaptions[pid] = previousCaptionEntry;
                }
                else {
                    nextCaptions[pid] = {
                        details: '',
                        timeStamp: Date.now(),
                    };
                }
            });
            setCaptions(nextCaptions);
            captionsRef.current = nextCaptions;
        }, 0.25 * CAPTION_DISPLAY_TIMEOUT);
        return () => {
            clearInterval(interval);
        };
    }, [roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participants]);
    useEffect(() => {
        if (room) {
            let removeRemoteParticipant = (r) => {
                raam.twilio.removeRoomParticipant(room.name, r.identity);
            };
            room.on('participantDisconnected', removeRemoteParticipant);
            return () => {
                room.off('participantDisconnected', removeRemoteParticipant);
            };
        }
    }, [room]);
    useEffect(() => {
        if (room) {
            let removeMyself = () => {
                raam.twilio.removeRoomParticipant(room.name, identity);
            };
            room.on('disconnected', removeMyself);
            return () => {
                room.off('disconnected', removeMyself);
            };
        }
    }, [room, identity]);
    return (_jsx(CaptionContext.Provider, { value: {
            captions,
            enqueueCaptionFn,
            languageCode,
            locallyDisplayCaptions,
            updateLocallyDisplayCaptionsFn: setLocallyDisplayCaptions,
            ccDisabled,
        }, children: children }));
};
