/* eslint-disable no-console */
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import { FullScreenPanelLayout, StyledFileInput } from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import CameraAcceptIcon from './icons/camera_accept.svg';
import CameraCancelIcon from './icons/camera_cancel.svg';
import CameraCaptureIcon from './icons/camera_capture.svg';
import CameraRetakeIcon from './icons/camera_retake.svg';
import CameraSwitchIcon from './icons/camera_switch.svg';
import CameraUploadIcon from './icons/camera_upload.svg';

function ScreenHealthCardCapture({
  onNext,
  onBack,
  onCameraError,
}: {
  onNext: Function;
  onBack: Function;
  onCameraError: Function;
}) {
  const [cameraConstraints, setCameraConstraints] = useState({
    facingMode: 'environment',
  });

  const t = useExtendedTranslation();

  const webcamRef = React.useRef<Webcam>(null);
  const [snapshotUrl, setSnapshotUrl] = useState('');
  const [snapshot, setSnapshot] = useState<Blob>();

  const switchSelectedCamera = useCallback(() => {
    if (_.get(cameraConstraints, 'facingMode', '') === 'user') {
      setCameraConstraints({ facingMode: 'environment' });
    } else {
      setCameraConstraints({ facingMode: 'user' });
    }
  }, [cameraConstraints]);

  useEffect(() => {
    if (snapshot) {
      setSnapshotUrl(URL.createObjectURL(snapshot));
    }
  }, [snapshot]);

  const [showInput, setShowInput] = useState(true);
  const [isPhotoInvalid, setIsPhotoInvalid] = useState(false);

  const useFile = useCallback(
    (blob: Blob) => {
      const allowedFileTypes = ['image/png', 'image/jpeg'];
      console.log('blob uploaded');
      if (allowedFileTypes.includes(blob.type) && blob.size / 1024 ** 2 < 20) {
        setIsPhotoInvalid(false);
        setSnapshot(blob);
        setShowInput(false);
      } else {
        setIsPhotoInvalid(true);
      }
    },
    [setSnapshot]
  );

  const takePicture = useCallback(async () => {
    if (webcamRef && webcamRef.current) {
      const canvas = webcamRef.current.getCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob) {
            setSnapshot(blob);
            setShowInput(false);
          }
        });
      }
    }
  }, [webcamRef, setShowInput]);

  const clearPicture = useCallback(() => {
    setSnapshotUrl('');
    setShowInput(true);
  }, [setShowInput]);

  const acceptPicture = () => {
    const d = {
      healthCardBlob: snapshot,
      healthCardNumber: '',
    };

    onNext(d);
  };

  const renderSnapshot = () =>
    snapshotUrl ? (
      <Box
        flex={1}
        style={{
          backgroundImage: `url(${snapshotUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
          backgroundSize: 'contain',
        }}
      />
    ) : (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: '100%' }}
      >
        <Grid item>
          <CircularProgress disableShrink />
        </Grid>
      </Grid>
    );

  return (
    <Box display="flex" flex={1} bgcolor="black">
      <Snackbar
        style={{
          pointerEvents: 'none',
          bottom: '120px',
        }}
        open={isPhotoInvalid}
        onClose={() => setIsPhotoInvalid(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={8000}
      >
        <Alert elevation={6} variant="filled" severity="error">
          {t('healthcardneeded.image.invalid')}
        </Alert>
      </Snackbar>
      <FullScreenPanelLayout>
        <Grid
          container
          direction="column"
          style={{ height: '100%' }}
          wrap="nowrap"
        >
          <Grid item xs style={{ display: 'flex', flexDirection: 'column' }}>
            {showInput ? (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={cameraConstraints}
                onUserMediaError={() => {
                  onCameraError();
                }}
                style={{
                  minHeight: 0,
                  maxWidth: '100%',
                  maxHeight: '100%',
                  flex: 1,
                  flexBasis: 0,
                }}
              />
            ) : (
              renderSnapshot()
            )}
          </Grid>
          <Grid item>
            <Box bgcolor="#eaeaea">
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  {showInput ? (
                    <IconButton disabled>
                      <Box width={50} height={50} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={clearPicture}>
                      <img
                        style={{ width: '50px', height: '50px' }}
                        src={CameraRetakeIcon}
                        alt={t('cameraButton.retake')}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid item>
                  {showInput ? (
                    <IconButton onClick={takePicture}>
                      <img
                        src={CameraCaptureIcon}
                        alt={t('cameraButton.capture')}
                        style={{ width: '60px', height: '60px' }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={acceptPicture}
                      disabled={showInput || !snapshot}
                    >
                      <img
                        style={{ width: '60px', height: '60px' }}
                        src={CameraAcceptIcon}
                        alt={t('cameraButton.accept')}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid item>
                  <StyledFileInput
                    accept={['image/png', 'image/jpeg']}
                    isIcon
                    onFileSelected={useFile}
                  >
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={CameraUploadIcon}
                      alt={t('cameraButton.upload')}
                    />
                  </StyledFileInput>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box position="absolute" top={0} left={0} right={0}>
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid item>
              <IconButton onClick={() => onBack()}>
                <img
                  style={{ width: '50px', height: '50px' }}
                  src={CameraCancelIcon}
                  alt={t('cameraButton.cancel')}
                />
              </IconButton>
            </Grid>
            <Grid item>
              {showInput ? (
                <IconButton onClick={switchSelectedCamera}>
                  <img
                    style={{ width: '50px', height: '50px' }}
                    src={CameraSwitchIcon}
                    alt={t('cameraButton.switch')}
                  />
                </IconButton>
              ) : (
                <IconButton disabled>
                  <Box width={50} height={50} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Box>
      </FullScreenPanelLayout>
    </Box>
  );
}

export default ScreenHealthCardCapture;
