import { useEffect, useState } from 'react';
import { calcSecFromDate } from '../Util/date';

const useTimer = (
  expiry: number,
  isValidExpiry: boolean,
  offset = 0
): [number, boolean] => {
  const [timeLeft, setTimeLeft] = useState(() =>
    calcSecFromDate(expiry, offset)
  );
  const [timerActive, setTimerActive] = useState(isValidExpiry);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calcSecFromDate(expiry, offset));
      setTimerActive(isValidExpiry);
      if (timeLeft <= 0) {
        clearInterval(interval);
        setTimerActive(false);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [expiry, isValidExpiry, offset, timeLeft]);

  return [timeLeft, timerActive];
};

export default useTimer;
