import React, {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { SinglePanelLayout } from '../../Components';
import './ScreenPleaseWait.scss';
import { useExtendedTranslation } from '../../Services/i18nService';
import TextBlockHint from '../../Components/TextStyles/TextBlockHint/TextBlockHint';
import useClinicConfig from '../../Hooks/useClinicConfig';

function ScreenPleaseWait({ message_key }: { message_key?: string }) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();

  return (
    <Box display="flex" flex={1} className="screen-please-wait">
      <SinglePanelLayout
        title={
          <Typography variant="h5" color="primary" align="center">
            {message_key ? t(message_key) : ''}
          </Typography>
        }
        logo={clinicConfig.logoName}
      >
        <TextBlockHint>{t('pleasewait.hint')}</TextBlockHint>
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </SinglePanelLayout>
    </Box>
  );
}

export default ScreenPleaseWait;
