import { useEffect, useState } from 'react';
export default function useIdentity(room) {
    var _a;
    const [identity, setIdentity] = useState();
    useEffect(() => {
        var _a, _b;
        if (!((_a = room === null || room === void 0 ? void 0 : room.localParticipant) === null || _a === void 0 ? void 0 : _a.identity)) {
            return;
        }
        setIdentity((_b = room === null || room === void 0 ? void 0 : room.localParticipant) === null || _b === void 0 ? void 0 : _b.identity);
    }, [(_a = room === null || room === void 0 ? void 0 : room.localParticipant) === null || _a === void 0 ? void 0 : _a.identity]);
    return identity;
}
