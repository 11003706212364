import { useCallback, useState } from 'react';

export enum AsyncStatus {
  INIT = 'init',
  PENDING = 'pending',
  DONE = 'done',
  ERROR = 'error',
}

interface HookAsyncFn<TData> {
  (...params: any): Promise<TData>;
  status: AsyncStatus;
  setStatus: (status: AsyncStatus) => void;
  value?: TData;
  error: any;
}

type AsyncFn<TData = unknown> = (...params: any) => Promise<TData>;

const useAsync = <TData>(asyncFn: AsyncFn<TData>) => {
  const [status, setStatus] = useState(AsyncStatus.INIT);
  const [value, setValue] = useState<TData>();
  const [error, setError] = useState();

  const fn = useCallback(
    (...params: any) => {
      setStatus(AsyncStatus.PENDING);
      setError(undefined);
      return asyncFn(...params)
        .then((response) => {
          setValue(response);
          setStatus(AsyncStatus.DONE);
          return Promise.resolve(response);
        })
        .catch((e) => {
          setError(e);
          setStatus(AsyncStatus.ERROR);
          return Promise.reject(e);
        });
    },
    [asyncFn]
  );

  const fnAsAsync = fn as HookAsyncFn<TData>;
  fnAsAsync.status = status;
  fnAsAsync.setStatus = setStatus;
  fnAsAsync.value = value;
  fnAsAsync.error = error;

  return fnAsAsync;
};

export default useAsync;
