import { Theme, makeStyles } from '@material-ui/core';
import _ from 'lodash';

export enum SupportedThemes {
  Dark = 'dark',
  Light = 'light',
}

let LightTheme: Theme;
let DarkTheme: Theme;
export function init(light: Theme, dark: Theme) {
  LightTheme = light;
  DarkTheme = dark;
}

const THEME_KEY = 'theme';
const DEFAULT_THEME = SupportedThemes.Light;

export function setThemeName(newTheme: SupportedThemes) {
  localStorage.setItem(THEME_KEY, newTheme);
  window.location.reload();
}

export function getThemeName(): SupportedThemes {
  const fromStore = localStorage.getItem(THEME_KEY) as SupportedThemes;
  if (!fromStore) {
    setThemeName(DEFAULT_THEME);
  }
  return fromStore || DEFAULT_THEME;
}

export function getTheme(): Theme {
  switch (getThemeName()) {
    case SupportedThemes.Dark:
      return DarkTheme;
    case SupportedThemes.Light:
      return LightTheme;
    default:
      throw new RangeError('Unsupported Theme');
  }
}

/**
 * Creates a memoized cssClass Factory
 * @param factory A function that will be passed props and the current theme and should return a style object
 */
export function createCssClassFactory<T>(
  factory: (props: T, theme: Theme) => any
) {
  const raw = makeStyles({
    temp: (props: any) => {
      const theme = getTheme();
      return factory(props, theme);
    },
  });
  const memoized = _.memoize(raw, (...args) => args.join('|'));

  const output = (props: T) => memoized(props).temp as string;
  return output;
}
