import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import _fp from "lodash/fp";
import { useContext, useLayoutEffect, useRef } from "react";
import { CaptionContext } from "../CaptionProvider";
export default function Caption() {
    const { captions, locallyDisplayCaptions } = useContext(CaptionContext);
    const refs = useRef([]);
    useLayoutEffect(() => {
        if (!refs.current) {
            return;
        }
        _.forEach(refs.current, (ref) => {
            if (!ref) {
                return;
            }
            ref.scrollTop = ref.scrollHeight;
        });
    }, [refs, captions]);
    if (!locallyDisplayCaptions) {
        return null;
    }
    return (_jsx("div", { children: _fp.flow([
            _fp.toPairs,
            _fp.sortBy([0]),
            _fp.entries,
            _fp.map(([key, caption]) => {
                const name = `${_.split(caption[0], "|")[0]}: `;
                return (_jsxs(Grid, { container: true, style: {
                        color: "white",
                        background: "rgba(0, 0, 0, 0.7)",
                        padding: "0 0.5rem"
                    }, children: [_jsx(Grid, { item: true, children: _jsx("div", { style: {
                                    fontSize: "1.25rem",
                                    fontWeight: "bold",
                                    whiteSpace: "pre-wrap"
                                }, children: name }) }), _jsx(Grid, { item: true, children: _jsx("div", { ref: (ref) => {
                                    refs.current[key] = ref;
                                }, style: {
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                    overflowY: "auto",
                                    scrollBehavior: "smooth",
                                    fontSize: "1.25rem",
                                    maxHeight: "3.6rem",
                                    whiteSpace: "pre-line"
                                }, children: _.get(caption, "1.details", "") }) })] }, key));
            })
        ])(captions) }));
}
