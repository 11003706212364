import React from 'react';
import {
  Grid,
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik, getIn } from 'formik';
import _ from 'lodash';
import {
  SinglePanelLayout,
  HealthCardCustom,
  FormikErrorSnackbar,
} from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import useClinicConfig from '../../Hooks/useClinicConfig';
import { ISurveyData } from '../../Types';
import { areAllValuesTruthy, HEALTH_CARD_REGEX } from '../../Util/util';

import './HealthCardForm.scss';

function HealthCardForm({
  userData,
  onNext,
}: {
  onNext: Function;
  userData: ISurveyData;
}) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();

  const validationSchema = Yup.object({
    healthCardNumber: Yup.string()
      .matches(HEALTH_CARD_REGEX, t('healthcardform.healthcard.error'))
      .required(t('healthcardform.healthcard.error')),
  });

  return (
    <Box display="flex" flex={1} className="health-card-form">
      <Formik
        initialValues={userData}
        validationSchema={validationSchema}
        onSubmit={(values) => onNext(values)}
      >
        {({ values, isSubmitting, isValidating, submitForm }) => (
          <Form style={{ display: 'flex', flex: 1 }}>
            <FormikErrorSnackbar />
            <SinglePanelLayout
              footer={
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        isSubmitting ||
                        isValidating ||
                        areAllValuesTruthy(_.pick(values, ['healthCardNumber']))
                      }
                      onClick={submitForm}
                    >
                      {t('questionnaire.continue.button')}
                    </Button>
                  </Grid>
                </Grid>
              }
              logo={clinicConfig.logoName}
            >
              <Typography>{t('healthcardform.description')}</Typography>
              <FormControl>
                <FormLabel required>{t('healthcardform.healthcard')}</FormLabel>
                <Field name="healthCardNumber">
                  {({ form, field }: FieldProps) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      error={
                        getIn(form.errors, field.name) &&
                        getIn(form.touched, field.name)
                      }
                      InputProps={{
                        inputComponent: HealthCardCustom,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        maxLength: 128,
                      }}
                      placeholder={t('healthcardform.healthcard.placeholder')}
                      {...field}
                    />
                  )}
                </Field>
              </FormControl>
            </SinglePanelLayout>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default HealthCardForm;
