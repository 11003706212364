import React from 'react';
import { Box, Grid } from '@material-ui/core';
import ChatLog from '../ChatLog/ChatLog';
import ChatComposer from '../ChatComposer/ChatComposer';
import { ChatView } from '../../../Services/Chat';
import { useMaybePromise } from '../../../Hooks';
import ErrorBoundary from '../../ErrorBoundary';
import { useExtendedTranslation } from '../../../Services/i18nService';
import ChatError from '../ChatError/ChatError';

function ChatDisplayInternal({
  chat,
  patron,
}: {
  chat?: Promise<ChatView>;
  patron?: any;
}) {
  const chatResolved = useMaybePromise(chat);

  return chatResolved ? (
    <Grid container direction="column" wrap="nowrap" className="chat-display">
      <Grid item xs container direction="column" wrap="nowrap">
        <Box
          overflow="auto"
          flexGrow={1}
          flexBasis={0}
          bgcolor="white"
          borderRadius="8px 8px 0 0"
        >
          <ChatLog chat={chatResolved} />
        </Box>
      </Grid>
      <Grid item>
        <ChatComposer chat={chatResolved} patron={patron} />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}

function GenericError() {
  const t = useExtendedTranslation();
  return <ChatError>{t('chatdisplay.generic_error')}</ChatError>;
}

function ChatDisplay({
  chat,
  patron,
}: {
  chat?: Promise<ChatView>;
  patron?: any;
}) {
  return (
    <ErrorBoundary fallback={() => <GenericError />}>
      <ChatDisplayInternal chat={chat} patron={patron} />
    </ErrorBoundary>
  );
}

export default ChatDisplay;
