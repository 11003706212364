import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Video from "twilio-video";
import { Container, Link, Typography, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
    container: {
        marginTop: "2.5em"
    },
    paper: {
        padding: "1em"
    },
    heading: {
        marginBottom: "0.4em"
    }
});
export default function UnsupportedBrowserWarning({ children }) {
    const classes = useStyles();
    if (!Video.isSupported) {
        return (_jsx(Container, { children: _jsx(Grid, { container: true, justifyContent: "center", className: classes.container, children: _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsxs(Paper, { className: classes.paper, children: [_jsx(Typography, { variant: "h4", className: classes.heading, children: "Browser or context not supported" }), _jsxs(Typography, { children: ["Please open this application in one of the", " ", _jsx(Link, { href: "https://www.twilio.com/docs/video/javascript#supported-browsers", target: "_blank", rel: "noopener", children: "supported browsers" }), ".", _jsx("br", {}), "If you are using a supported browser, please ensure that this app is served over a", " ", _jsx(Link, { href: "https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts", target: "_blank", rel: "noopener", children: "secure context" }), " ", "(e.g. https or localhost)."] })] }) }) }) }));
    }
    return children;
}
