import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useChatContext } from '../../../../Contexts/ChatContext';
import { ChatView } from '../../../../Services/Chat';
import { useExtendedTranslation } from '../../../../Services/i18nService';
import { BehaviourMarkAllAsRead, ChatDisplay } from '../../../Chat';
import './MessagingDialog.scss';

function MessagingDialog({
  chat,
  patron,
}: {
  chat?: Promise<ChatView>;
  patron?: any;
}) {
  const t = useExtendedTranslation();
  const theme = useTheme();
  const { showMessaging, updateShowMessaging } = useChatContext();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={showMessaging}
      onClose={() => updateShowMessaging(false)}
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs zeroMinWidth>
            <Typography noWrap variant="h5">
              {t('chat.dialogTitle')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label={t('chat.closeButton')}
              className="closeButton"
              onClick={() => updateShowMessaging(false)}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent
        style={{ height: fullScreen ? '100%' : '50vh', display: 'flex' }}
      >
        <ChatDisplay chat={chat} patron={patron} />
        <BehaviourMarkAllAsRead chat={chat} enabled={showMessaging} />
      </DialogContent>
    </Dialog>
  );
}

export default MessagingDialog;
