var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Cookie from "universal-cookie";
import _ from "lodash";
import { raam } from "raam-client-lib";
import { STT_TOKEN_MAX_AGE } from "../constants";
export function getTokenOrRefresh(roomName) {
    return __awaiter(this, void 0, void 0, function* () {
        const cookie = new Cookie();
        const speechToken = cookie.get("speech-token");
        if (speechToken === undefined) {
            const { region, token } = yield raam.twilio.getAzureSpeechToken(roomName);
            const authToken = String.fromCharCode(...token.data);
            cookie.set('speech-token', region + ':' + authToken, {
                maxAge: STT_TOKEN_MAX_AGE,
                path: '/',
            });
            return { authToken, region };
        }
        else {
            const [region, authToken] = _.split(speechToken, ":");
            return { authToken, region };
        }
    });
}
