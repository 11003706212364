/* eslint-disable react/prop-types */
import { Box, Fade, IconButton, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { FormikErrors, useFormikContext } from 'formik';
import _ from 'lodash';
import { SnackbarKey, useSnackbar } from 'notistack';
import { ComponentType, FC, useEffect, useState } from 'react';
import { useExtendedTranslation } from '../Services/i18nService';

const FormikErrorMessage: FC<{ errors: FormikErrors<any> }> = ({ errors }) => {
  const t = useExtendedTranslation();
  return (
    <Box>
      <Typography>{t('formikError.title')}</Typography>
      <ul style={{ margin: 0 }}>
        {_.map(_.uniq(_.values(errors)), (error, i) => (
          <Typography variant="body2" component="li" key={i}>
            {error as string}
          </Typography>
        ))}
      </ul>
    </Box>
  );
};

const FormikErrorSnackbar: FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const t = useExtendedTranslation();

  const { submitCount, isValid, errors } = useFormikContext();
  const [lastHandled, setLastHandled] = useState(0);
  const [snackbarKey, setSnackbarKey] = useState<SnackbarKey>('');

  useEffect(() => {
    if (submitCount <= lastHandled || isValid) {
      return;
    }
    const generatedKey = enqueueSnackbar(
      <FormikErrorMessage errors={errors} />,
      {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        TransitionComponent: Fade as ComponentType<TransitionProps>,
        preventDuplicate: true,
        persist: true,
        action: (key) => (
          <IconButton
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
      }
    );
    setSnackbarKey(generatedKey);
    setLastHandled(submitCount);
  }, [
    submitCount,
    isValid,
    closeSnackbar,
    enqueueSnackbar,
    errors,
    lastHandled,
    setLastHandled,
    t,
  ]);

  useEffect(() => {
    return () => {
      closeSnackbar(snackbarKey);
    };
  }, [closeSnackbar, snackbarKey]);

  return <></>;
};

export default FormikErrorSnackbar;
