import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useChatContext } from '../../Contexts/ChatContext';
import { usePatron } from '../../Hooks';
import {
  CompleteSession,
  Ejected,
  LeaveQueue,
  Meeting,
  ScreenPleaseWait,
  ThankYouForWaiting,
  ValidatingDetail,
  VisitTimer,
} from '../../Screens';
import { IPatronIds } from '../../Types';

enum Screen {
  Loading, // This screen state exist to avoid flickering. This will be blank page
  WaitingChart,
  Waiting,
  Countdown,
  Rejoin,
  InMeeting,
  HasBeenEjected,
  Completed,
  Leaving,
  Unload,
}

const StateToScreen = {
  WAITING_FOR_CHART: Screen.WaitingChart,
  WAITING_FOR_TRIAGE: Screen.Waiting,
  WAITING: Screen.Waiting,
  MEETING_REQUEST: Screen.Countdown,
  POST_TRIAGE: Screen.Waiting,
  IN_MEETING: Screen.InMeeting,
  EJECTED: Screen.HasBeenEjected,
  COMPLETED: Screen.Completed,
  LEFT: Screen.Unload,
};

const getScreenFromState = (s: string): Screen =>
  _.get(StateToScreen, s, Screen.Loading);

export default function StatesScreen({
  patron: patronIds,
  unloadPatron,
}: {
  patron: IPatronIds;
  unloadPatron: Function;
}) {
  const [screen, setScreen] = useState<Screen>(Screen.Loading);
  const [patron, inError] = usePatron(patronIds);

  const { updateShowMessaging } = useChatContext();

  const isOn = (expected: Screen) => screen === expected;
  const goTo = (newScreen: Screen) => () => setScreen(newScreen);
  const displayWhen = (expected: Screen, elements: JSX.Element) =>
    isOn(expected) && elements;

  useEffect(() => {
    setScreen(getScreenFromState(patron?.properties?.state));
  }, [patron?.properties?.state]);

  useEffect(() => {
    if (inError) {
      unloadPatron();
    }
  }, [inError, unloadPatron]);

  useEffect(() => {
    if (screen === Screen.Unload) {
      unloadPatron();
    }
  }, [unloadPatron, screen]);

  useEffect(() => {
    updateShowMessaging(false);
  }, [screen, updateShowMessaging]);

  return (
    <>
      {displayWhen(
        Screen.Loading,
        <ScreenPleaseWait message_key="pleasewait.title2" />
      )}

      {displayWhen(
        Screen.WaitingChart,
        <ValidatingDetail patron={patron} onLeave={goTo(Screen.Leaving)} />
      )}

      {displayWhen(
        Screen.Waiting,
        <ThankYouForWaiting patron={patron} onLeave={goTo(Screen.Leaving)} />
      )}

      {displayWhen(Screen.Countdown, <VisitTimer patron={patron} />)}

      {displayWhen(
        Screen.InMeeting,
        <Meeting
          patron={patron}
          onContinue={() => {
            setScreen(Screen.Waiting);
          }}
        />
      )}

      {displayWhen(
        Screen.HasBeenEjected,
        <Ejected patron={patron} onClose={() => unloadPatron()} />
      )}

      {displayWhen(
        Screen.Completed,
        <CompleteSession patron={patron} onComplete={() => unloadPatron()} />
      )}

      {displayWhen(
        Screen.Leaving,
        <LeaveQueue
          patron={patron}
          onCancel={() =>
            setScreen(getScreenFromState(patron?.properties?.state))
          }
        />
      )}
    </>
  );
}
