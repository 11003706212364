import { IconButton } from '@material-ui/core';
import {
  SupportedLanguages,
  getLanguage,
  setLanguage,
  useExtendedTranslation,
} from '../../Services/i18nService';
import EnglishIcon from './icons/english.svg';
import FrenchIcon from './icons/french.svg';

function toggleLanguage() {
  setLanguage(
    getLanguage() === SupportedLanguages.French
      ? SupportedLanguages.English
      : SupportedLanguages.French
  );
}

function LanguageSelector({
  supportedLanguages = [],
}: {
  supportedLanguages?: SupportedLanguages[] | undefined;
}) {
  const t = useExtendedTranslation();

  if (supportedLanguages && supportedLanguages.length === 1) {
    return null;
  }

  const langLogo =
    getLanguage() === SupportedLanguages.French ? FrenchIcon : EnglishIcon;

  return (
    <div>
      <IconButton onClick={toggleLanguage}>
        <img src={langLogo} alt={t('switchlanguage.alt')} />
      </IconButton>
    </div>
  );
}

export default LanguageSelector;
