/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import InQFieldDefaultInput, {
  InQFieldDefaultProps,
} from '../InQFieldDefaultInput/InQFieldDefaultInput';

import './InQField.scss';

export type InQFieldProps = InQFieldDefaultProps & {
  labelTwo?: string;
};

function InQField({
  className = '',
  id = '',
  label = '',
  labelTwo,
  hint,
  ...moreProps
}: InQFieldProps) {
  return (
    <div className={`inq-field ${className}`} id={id}>
      <div className="field-label">{label}</div>
      {labelTwo && <div className="field-label">{labelTwo}</div>}
      <div className="field-value">
        <InQFieldDefaultInput {...moreProps} />
      </div>
      {hint ? <div className="field-hint">{hint}</div> : <></>}
    </div>
  );
}

export default InQField;
