/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { FastField, Field, FieldProps, Form, Formik, getIn } from 'formik';
import _ from 'lodash';
import {
  SinglePanelLayout,
  TextBlockDescription,
  TextBlockHint,
  FormikErrorSnackbar,
} from '../../Components';
import './ProvideInfo.scss';
import { useExtendedTranslation } from '../../Services/i18nService';
import useClinicConfig from '../../Hooks/useClinicConfig';
import { ISurveyData } from '../../Types';
import {
  areAllValuesTruthy,
  USER_ADDRESS_ENTRY_REGEX,
  USER_ENTRY_REGEX,
} from '../../Util/util';

function ProvideInfo({
  userData,
  onNext,
}: {
  userData: ISurveyData;
  onNext: Function;
}) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();

  const validationSchema = Yup.object({
    familyDoctor: Yup.string()
      .matches(USER_ENTRY_REGEX, t('provideInfo.familyDoctor.error'))
      .max(128, t('provideInfo.familyDoctor.error')),
    homeAddress: Yup.string()
      .matches(USER_ADDRESS_ENTRY_REGEX, t('provideInfo.homeAddress.error'))
      .max(128, t('provideInfo.homeAddress.error')),
    pregnantQ: Yup.string().required(t('provideInfo.required')),
  });

  return (
    <Box display="flex" flex={1} className="provide-info">
      <Formik
        initialValues={userData}
        validationSchema={validationSchema}
        onSubmit={(values) => onNext(values)}
      >
        {({ values, isSubmitting, isValidating, submitForm }) => (
          <Form className="formLayout">
            <FormikErrorSnackbar />
            <SinglePanelLayout
              footer={
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        isSubmitting ||
                        isValidating ||
                        areAllValuesTruthy(_.pick(values, ['pregnantQ']))
                      }
                      onClick={submitForm}
                    >
                      {t('questionnaire.continue.button')}
                    </Button>
                  </Grid>
                </Grid>
              }
              logo={clinicConfig.logoName}
            >
              <TextBlockDescription>
                {t('provideInfo.description')}
              </TextBlockDescription>

              <TextBlockHint>{t('provideInfo.pleaseComplete')}</TextBlockHint>

              <p className="custom-label">{t('provideInfo.homeAddress')}</p>
              <Field name="homeAddress">
                {({ form, field }: FieldProps) => (
                  <TextField
                    placeholder={t('provideInfo.homeAddress.placeholder')}
                    fullWidth
                    error={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                    variant="outlined"
                    multiline
                    minRows={3}
                    margin="dense"
                    inputProps={{ maxLength: 128 }}
                    {...field}
                  />
                )}
              </Field>

              <p className="custom-label">{t('provideInfo.familyDoctor')}</p>
              <FastField name="familyDoctor">
                {({ form, field }: FieldProps) => (
                  <TextField
                    fullWidth
                    error={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                    variant="outlined"
                    margin="dense"
                    inputProps={{ maxLength: 128 }}
                    {...field}
                  />
                )}
              </FastField>

              <p className="custom-label">{t('provideInfo.pregnantQ')}</p>
              <Field name="pregnantQ">
                {({ field }: FieldProps) => (
                  <RadioGroup defaultValue="" {...field}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio color="primary" />}
                      label={
                        <Typography variant="body2">
                          {t('questionnaire.yes.button')}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Not sure"
                      control={<Radio color="primary" />}
                      label={
                        <Typography variant="body2">
                          {t('questionnaire.notsure.button')}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="No/Does not apply"
                      control={<Radio color="primary" />}
                      label={
                        <Typography variant="body2">
                          {t('questionnaire.no_or_dna.button')}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                )}
              </Field>
            </SinglePanelLayout>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default ProvideInfo;
