// TODO: rewrite in hive-client-utils later
const { waitForInvocationReaction } = require('./hive-client-utils-wrapper').utils;

const assertConnection = require('../utils').assertConnection;
const invokeActionAndWait = (actionName) => (...p) => {
  const {bee} = assertConnection();

  return bee.actions.invoke(actionName, ...p)
    .then(waitForInvocationReaction(bee));
};

const invoke = (actionName) => (...p) => {
  const {bee} = assertConnection();

  return bee.actions.invoke(actionName, ...p);
};

const invokeApi = (actionName) => (...p) => {
  const {bee} = assertConnection();

  return bee.api.invoke(actionName, ...p);
};

module.exports = {
  assertConnection,
  invokeActionAndWait,
  invoke,
  invokeApi,
};
