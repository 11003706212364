import { jsx as _jsx } from "react/jsx-runtime";
import { Grid, makeStyles } from "@material-ui/core";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
const useStyles = makeStyles((theme) => ({
    container: {
        background: "white",
        padding: "10px",
        height: `${theme.mobileTopBarHeight}px`
    },
    endCallButton: {
        height: "28px",
        fontSize: "0.85rem",
        padding: "0 0.6em"
    }
}));
export default function MobileTopMenuBar() {
    const classes = useStyles();
    // const { room } = useVideoContext();
    return (_jsx(Grid, { container: true, alignItems: "center", justifyContent: "flex-end", className: classes.container, children: _jsx(EndCallButton, { className: classes.endCallButton }) }));
}
