import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  SinglePanelLayout,
  TextBlockDescription,
  TextBlockHint,
} from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import useClinicConfig from '../../Hooks/useClinicConfig';

function ScreenSomethingHappened({ onNext }: { onNext: Function }) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();

  return (
    <Box display="flex" flex={1}>
      <SinglePanelLayout
        title={
          <Typography variant="h5" color="primary" align="center">
            {t('errorunknown.title')}
          </Typography>
        }
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onNext()}
              >
                {t('errorunknown.rejoin')}
              </Button>
            </Grid>
          </Grid>
        }
        logo={clinicConfig.logoName}
      >
        <TextBlockDescription>
          {t('errorunknown.description')}
        </TextBlockDescription>
        <TextBlockHint>{t('errorunknown.hint')}</TextBlockHint>
      </SinglePanelLayout>
    </Box>
  );
}

export default ScreenSomethingHappened;
