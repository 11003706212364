import React from 'react';
import MaskedInput from 'react-text-mask';

export function HealthCardCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[a-zA-z]/,
        /[a-zA-z]/,
      ]}
      guide={false}
    />
  );
}

export default HealthCardCustom;
