import { ConnectionService, DeferredPromise, raam } from 'raam-client-lib';
import Resumable from 'resumablejs';

function assertConnection(): any {
  let connection;
  ConnectionService.connection$
    .subscribe((conn: any) => {
      connection = conn;
    })
    .unsubscribe();
  if (!connection) {
    throw new Error('Connection is needed!');
  }

  return connection;
}

function getToken(connection: any): string {
  if (!connection || !connection.token) {
    throw new Error('Invalid token!');
  }

  return connection.token.access_token || connection.token;
}

/**
 * @module BlobService
 */

/**
 * @memberof BlobService
 * @description Downloads an image from Hive's blob storage, if permitted
 * @param {String} documentId - The document blob storage id.
 * @param {HTMLImageElement} img - An optional image element to set it's src.
 * @returns {Promise} - A promise with the image object, if needed.
 */
export async function downloadImage(
  documentId: string,
  img?: HTMLImageElement
) {
  const connection: any = assertConnection();
  const url = raam.clinician.getBlobUrl(documentId, 'download');

  const token = getToken(connection);
  const response: Response = await fetch(url, {
    headers: { Authorization: `Token ${token}` },
  });
  const blob: Blob = await response.blob();
  const object = window.URL.createObjectURL(blob);

  if (img) {
    // eslint-disable-next-line no-param-reassign
    img.src = object;
  }

  return object;
}

export async function uploadImage(file: File, url: string) {
  const connection: any = assertConnection();
  const token = getToken(connection);
  const r = new Resumable({
    target: url,
    forceChunkSize: true,
    simultaneousUploads: 1,
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  r.addFile(file);

  const { promise, resolveFn, rejectFn } = DeferredPromise();

  r.on('fileAdded', () => r.upload());
  r.on('fileError', (_file, message) => rejectFn(message));
  r.on('complete', () => resolveFn());
  r.on('error', (message) => rejectFn(message));

  return promise;
}
