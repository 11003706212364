import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme, alpha } from "@material-ui/core";
import useParticipantNetworkQualityLevel from "../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel";
const useStyles = makeStyles({
    outerContainer: {
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    innerContainer: {
        display: "flex",
        alignItems: "flex-end",
        "& div": {
            width: "2px",
            marginRight: "1px",
            "&:not(:last-child)": {
                borderRight: "none"
            }
        }
    }
});
const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];
export default function NetworkQualityLevel({ participant, color }) {
    const classes = useStyles();
    const theme = useTheme();
    const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
    const definedColor = color ? color : theme.palette.text.primary;
    if (networkQualityLevel === null)
        return null;
    return (_jsx("div", { className: classes.outerContainer, children: _jsx("div", { className: classes.innerContainer, children: BARS_ARRAY.map(level => (_jsx("div", { style: {
                    height: `${STEP * (level + 1)}px`,
                    background: networkQualityLevel > level
                        ? definedColor
                        : alpha(definedColor, 0.2)
                } }, level))) }) }));
}
