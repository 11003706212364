import { Instance } from 'hive-client-utils';

export interface INote {
  timeStamp: string;
  name: string;
  type: string;
  text?: string;
}

export default interface IPatron extends Instance {
  properties: {
    clinicianChatId?: string;
    conversationId?: string;
    currentCareId: string;
    justification?: string;
    meetingRequestExpiry: string;
    meetingUrl: string;
    meetingName: string;
    meetingPin?: string;
    state: string;
    firstName: string;
    lastName: string;
  };
}

export enum SatisfactionScore {
  Nothing,
  VerySad,
  Sad,
  Neither,
  Happy,
  VeryHappy,
}
