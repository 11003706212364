import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormControl, MenuItem, Typography, Select } from "@material-ui/core";
import { useAppState } from "../../../state";
import useDevices from "../../../hooks/useDevices/useDevices";
import { useWrappedTranslation } from "../../../utils/i18nService";
export default function AudioOutputList() {
    var _a;
    const t = useWrappedTranslation();
    const { audioOutputDevices } = useDevices();
    const { activeSinkId, setActiveSinkId } = useAppState();
    const activeOutputLabel = (_a = audioOutputDevices.find(device => device.deviceId === activeSinkId)) === null || _a === void 0 ? void 0 : _a.label;
    return (_jsx("div", { className: "inputSelect", children: audioOutputDevices.length > 1 ? (_jsxs(FormControl, { fullWidth: true, children: [_jsx(Typography, { variant: "subtitle2", gutterBottom: true, children: t("AudioOutputList") }), _jsx(Select, { onChange: e => setActiveSinkId(e.target.value), value: activeSinkId, variant: "outlined", children: audioOutputDevices.map(device => (_jsx(MenuItem, { value: device.deviceId, children: device.label }, device.deviceId))) })] })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle2", children: t("AudioOutputList") }), _jsx(Typography, { children: activeOutputLabel || t("AudioOutputList.NoDevices") })] })) }));
}
