import { useMemo, useEffect, useState } from 'react';
import { usePromise } from '.';
import AesGcmEncryptor from '../Services/Encrypt/AesEncrypt';

const getLocalStorageOrDefault = (key: string, defaultValue: any) => {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return Promise.resolve(defaultValue);
  }
  return AesGcmEncryptor.decrypt(stored)
    .catch(() => {
      return Promise.resolve(defaultValue);
    })
    .then((result) => {
      try {
        return JSON.parse(result);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Invalid JSON object in localStorage, ignoring...');
        return Promise.resolve(defaultValue);
      }
    });
};

function useEncryptedLocalStorage(key: string, defaultValue: any) {
  const dataPromise = useMemo(
    () => getLocalStorageOrDefault(key, defaultValue),
    [key, defaultValue]
  );
  const data = usePromise(defaultValue, dataPromise);

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(data);
  }, [data]);

  useEffect(() => {
    AesGcmEncryptor.encrypt(JSON.stringify(value)).then((result) => {
      localStorage.setItem(key, result);
    });
  }, [value, key]);

  return [value, setValue];
}

export default useEncryptedLocalStorage;
