import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import './InQRadioButtonGroup.scss';

export interface InQRadioButtonGroupMetaData {
  groupName: string;
  onChange: (newValue: string) => void;
  selectedValue?: string;
}

export const InQRadioButtonGroupContext =
  React.createContext<InQRadioButtonGroupMetaData>(undefined as any);

function InQRadioButtonGroup({
  id = '',
  className = '',
  value = '',
  groupName = '',
  onChange = () => {},
  children = <></>,
}: {
  id?: string;
  className?: string;
  groupName?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  children?: React.ReactNode;
}) {
  const [actualGroupName] = useState(groupName || uuidv4());
  const [selectedValue, setSelectedValue] = useState(value);
  const [previousValue, setPreviousValue] = useState(value);

  const onChangeHandler = useCallback(
    (newValue) => {
      if (newValue === previousValue) {
        // Probably the onChange handler changed, we don't care
        return;
      }
      setPreviousValue(newValue);
      setSelectedValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange, previousValue]
  );

  useEffect(() => setSelectedValue(value), [value]);

  const metaData: InQRadioButtonGroupMetaData = {
    groupName: actualGroupName,
    onChange: onChangeHandler,
    selectedValue,
  };
  return (
    <div className={`inq-radiobutton-group ${className}`} id={id}>
      <InQRadioButtonGroupContext.Provider value={metaData}>
        {children}
      </InQRadioButtonGroupContext.Provider>
    </div>
  );
}

export default InQRadioButtonGroup;
