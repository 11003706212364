import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Box, IconButton, Tooltip, useTheme } from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import _ from 'lodash';
import { useState } from 'react';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import { useWrappedTranslation } from '../../../utils/i18nService';
import { useDeviceOrientation } from '../../DeviceOrientationProvider';
import ParticipantListDialog from '../../ParticipantListDialog/ParticipantListDialog';
const ParticipantsButton = () => {
    const t = useWrappedTranslation();
    const theme = useTheme();
    const participants = useParticipants();
    const { isPortrait } = useDeviceOrientation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const label = t('ParticipantsButton');
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: label, placement: isPortrait ? 'left' : 'bottom', children: _jsx(Box, { boxShadow: 2, borderRadius: 20, children: _jsx(IconButton, { onClick: () => setDialogOpen(true), style: {
                            backgroundColor: theme.palette.grey[600],
                            color: theme.palette.getContrastText(theme.palette.grey[600]),
                            padding: '6px',
                        }, "aria-label": label, children: _jsx(Badge, { anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            }, badgeContent: _.size(participants) + 1, showZero: true, color: "primary", children: _jsx(PersonOutlineOutlinedIcon, {}) }) }) }) }), _jsx(ParticipantListDialog, { open: dialogOpen, onClose: () => setDialogOpen(false) })] }));
};
export default ParticipantsButton;
