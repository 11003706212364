import React from 'react';
import './TextBlockHint.scss';

function TextBlockHint({
  children = <></>,
  alignCenter = false,
}: {
  children?: JSX.Element;
  alignCenter?: boolean;
}) {
  if (!alignCenter) {
    return <div className="text-hint">{children}</div>;
  }
  return <div className="text-hint-center">{children}</div>;
}

export default TextBlockHint;
