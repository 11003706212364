/* eslint-disable no-console */
import { Observable, ReplaySubject } from 'rxjs';
import { autoTimeout } from '../Util/util';

/**
 * The internet connection service combines multiple events to get a faster, more accurate picture of the current connection state than
 * relying on a single event alone.  It uses the browsers built in network connection status to allow it to respond to device level events
 * such as toggling the wifi or wireless data, and it also uses keep alive checks to poll to catch intermittant signal drops and server outages
 *
 * There is a 30 second sample window for the server polling which means any outage should be detected in at most 30 seconds.  It also means that
 * intermittant outages lasting less than 30 seconds might not be detected.
 *
 * All blocking calls to the server should be wrapped with timeouts (ex: autoTimeout) to ensure that the user is not left in an irrecoverable state
 */

const internetConnectionLost = new ReplaySubject<any>(1);
const internetConnectionRestored = new ReplaySubject<any>(1);

const MAXIMUM_DETECTION_WINDOW = 30 * 1000;
const LEVEL2_TIMEOUT = 5 * 1000;
const LEVEL_2_GRACE_PERIOD = 5 * 1000;
const LEVEL_2_FAILURE_THRESHOLD = 2;
const LEVEL2_INTERVAL =
  MAXIMUM_DETECTION_WINDOW -
  LEVEL2_TIMEOUT -
  (LEVEL_2_GRACE_PERIOD + LEVEL2_TIMEOUT) * (LEVEL_2_FAILURE_THRESHOLD - 1);
const LEVEL2_EXPECTED_VALUE = "I'm alive!";
const LEVEL2_URL = 'keep-alive.txt';

if (LEVEL2_INTERVAL < 1) {
  throw new Error('Unrealistic expectations for internet outage detection');
}

let previousStatus: boolean | undefined;

let Level2FailureCount: number = 0;

export function watchForInternetConnectionLost(): Observable<any> {
  return internetConnectionLost;
}

export function watchForInternetConnectionRestored(): Observable<any> {
  return internetConnectionRestored;
}

const statusToText = (status: boolean) => (status ? 'online' : 'offline');

function setStatus(status: boolean) {
  if (status === previousStatus) {
    return;
  }
  console.log(
    `Connection status changed.  Application is now ${statusToText(status)}`
  );
  if (status && previousStatus === false) {
    internetConnectionRestored.next(Date.now());
  } else if (!status && previousStatus !== false) {
    internetConnectionLost.next(Date.now());
  }
  previousStatus = status;
}

export function isOnline() {
  return previousStatus;
}

async function checkLevel2Connectedness() {
  try {
    const request = await autoTimeout(fetch(LEVEL2_URL), LEVEL2_TIMEOUT);
    const content = await request.text();
    if (content !== LEVEL2_EXPECTED_VALUE) {
      throw new Error('Keep Alive value mismatch');
    }
    setStatus(true);
    if (Level2FailureCount > 0) {
      console.log('Internet Connection, Level 2 Recovered');
    }
    Level2FailureCount = 0;
  } catch (err) {
    Level2FailureCount += 1;
    console.warn(
      'Internet Connection, Level 2 Failure reported.  Current count',
      Level2FailureCount
    );
    if (Level2FailureCount >= LEVEL_2_FAILURE_THRESHOLD) {
      setStatus(false);
    } else {
      setTimeout(checkLevel2Connectedness, LEVEL_2_GRACE_PERIOD);
    }
  }
}

function init() {
  try {
    previousStatus = window.navigator.onLine;
    console.log(`Application is ${statusToText(previousStatus)}`);
  } catch (ignored) {
    console.error(
      'Could not determine if the internet is online or not, manual check will occur shortly'
    );
  }
  window.addEventListener('online', () => setStatus(true));
  window.addEventListener('offline', () => setStatus(false));

  setInterval(checkLevel2Connectedness, LEVEL2_INTERVAL);
  checkLevel2Connectedness();
}

init();
