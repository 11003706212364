import { jsx as _jsx } from "react/jsx-runtime";
import AvatarIcon from "../../../../icons/AvatarIcon";
import { Grid, makeStyles } from "@material-ui/core";
import VideoTrack from "../../../VideoTrack/VideoTrack";
import useVideoContext from "../../../../hooks/useVideoContext/useVideoContext";
const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        height: 0,
        overflow: "hidden",
        paddingTop: `${(9 / 16) * 100}%`,
        background: "black"
    },
    avatarContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "black",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                transform: "scale(0.7)"
            }
        }
    }
}));
export default function LocalVideoPreview() {
    const classes = useStyles();
    const { localTracks } = useVideoContext();
    const videoTrack = localTracks.find(track => !track.name.includes("screen") && track.kind === "video");
    return (_jsx(Grid, { container: true, direction: "column", style: { position: "relative", backgroundColor: "black" }, children: _jsx(Grid, { item: true, xs: true, style: { display: "flex", flexDirection: "column", width: "100%" }, children: videoTrack ? (_jsx(VideoTrack, { track: videoTrack, isLocal: true })) : (_jsx("div", { className: classes.avatarContainer, children: _jsx(AvatarIcon, {}) })) }) }));
}
