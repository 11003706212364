import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { SinglePanelLayout } from '../../Components';
import useClinicConfig from '../../Hooks/useClinicConfig';
import { assertConnection } from '../../Services/HiveService';
import {
  getLanguage,
  useExtendedTranslation,
} from '../../Services/i18nService';

function RegionalEligibility({ onNext }: { onNext: Function }) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const defaultImage = `./maps/${clinicConfig.mapName}.svg`;
  const connection = assertConnection();
  const language = getLanguage();

  const [mapPreview, setMapPreview] = useState(defaultImage);

  useEffect(() => {
    if (clinicConfig.mapPreview?.id) {
      connection.bee.blob
        .download(clinicConfig.mapPreview.id)
        .then((blob: { content: BlobPart; contentType: any }) => {
          const jsblob = new Blob([blob.content], {
            type: blob.contentType,
          });

          setMapPreview(URL.createObjectURL(jsblob));
        })
        .catch(() => {
          setMapPreview(defaultImage);
        });
    } else {
      setMapPreview(defaultImage);
    }
  }, [clinicConfig, connection, defaultImage]);

  return (
    <Box display="flex" flex={1} className="more-info">
      <SinglePanelLayout
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                onClick={() => onNext()}
                variant="contained"
                color="primary"
              >
                {t('questionnaire.continue.button')}
              </Button>
            </Grid>
          </Grid>
        }
        logo={clinicConfig.logoName}
      >
        <Grid
          container
          direction="column"
          style={{ height: '100%' }}
          wrap="nowrap"
        >
          <Grid item>
            <Typography paragraph variant="body2" color="primary">
              {ReactHtmlParser(
                language === 'fr'
                  ? clinicConfig.regionTextFr
                  : clinicConfig.regionTextEn
              )}
            </Typography>
          </Grid>
          {clinicConfig.includeRegionMap && (
            <Grid item xs style={{ minHeight: '200px', overflow: 'hidden' }}>
              <img
                style={{
                  objectFit: 'contain',
                  objectPosition: '50% 0',
                  flexGrow: 1,
                  flexBasis: '0px',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  borderRadius: '8px',
                  boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.5)',
                  background: 'rgba(0, 0, 0, 0.0001)',
                }}
                src={mapPreview}
                alt={t('more-information.alt')}
              />
            </Grid>
          )}
        </Grid>
      </SinglePanelLayout>
    </Box>
  );
}

export default RegionalEligibility;
