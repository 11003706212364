import {
  Box,
  Button,
  Card,
  IconButton,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { SnackbarContent, useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useChatContext } from '../../../Contexts/ChatContext';
import { useMaybePromise, useObservable, usePrevious } from '../../../Hooks';
import { ChatView } from '../../../Services/Chat';
import { useExtendedTranslation } from '../../../Services/i18nService';
import { ReactComponent as ChatClosed } from './icons/chat-closed.svg';
import { ReactComponent as ChatOpen } from './icons/chat-open.svg';
import { ReactComponent as Unread } from './icons/chat_message_waiting.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: 'white',
    width: '100%',
    textAlign: 'left',
    padding: '0.5rem 0.5rem 0 0.5rem',
  },
  messageIcon: {
    verticalAlign: 'middle',
  },
  messageText: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '0.5rem',
  },
  tapButton: {
    color: '#027AC5',
  },
}));

const messageHasInitial = (s: string): boolean => _.get(s, 2) === '|';
const removeMaybeInitial = (s: string): string =>
  messageHasInitial(s) ? _.join(_.slice(s, 3), '') : s;

function MessagingIndicator({ chat }: { chat?: Promise<ChatView> }) {
  const chatUnwrapped = useMaybePromise(chat);
  const hasUnreadMessages = useObservable(
    false,
    chatUnwrapped?.hasUnreadMessages
  );
  const messagesList = useObservable([], chatUnwrapped?.messages);
  const t = useExtendedTranslation();
  const classes = useStyles();
  let icon;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { showMessaging, updateShowMessaging } = useChatContext();
  const previous = usePrevious(messagesList);

  const getContent = useCallback(
    (messageToDisplay: string) => {
      return (
        <SnackbarContent className={classes.root}>
          <Card className={classes.card}>
            <Typography variant="body1">
              <SvgIcon component={Unread} className={classes.messageIcon} />
              {`  ${t('messagenotif.newmessage')}`}
            </Typography>
            <Typography variant="body1" className={classes.messageText}>
              &quot;{removeMaybeInitial(messageToDisplay)}&quot;
            </Typography>
            <Box textAlign="center">
              <Button
                onClick={() => updateShowMessaging(true)}
                variant="text"
                className={classes.tapButton}
              >
                {t('messagenotif.respond')}
              </Button>
            </Box>
          </Card>
        </SnackbarContent>
      );
    },
    [classes, t, updateShowMessaging]
  );

  useEffect(() => {
    if (!showMessaging && hasUnreadMessages) {
      if (_.size(previous) < _.size(messagesList)) {
        const messageToDisplay = _.get(_.last(messagesList), 'message', '');
        enqueueSnackbar('', {
          key: messageToDisplay,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          content: getContent(messageToDisplay),
          preventDuplicate: true,
          persist: false,
        });
      }
    } else if (showMessaging) {
      closeSnackbar();
    }
  }, [
    previous,
    hasUnreadMessages,
    showMessaging,
    messagesList,
    enqueueSnackbar,
    closeSnackbar,
    getContent,
  ]);

  if (!showMessaging && hasUnreadMessages) {
    icon = <SvgIcon component={Unread} />;
  } else if (showMessaging) {
    icon = <SvgIcon component={ChatOpen} />;
  } else {
    icon = <SvgIcon component={ChatClosed} />;
  }

  return (
    <IconButton
      onClick={() => updateShowMessaging(true)}
      disabled={!!showMessaging}
      aria-label={
        !showMessaging && hasUnreadMessages
          ? t('chat.button.unread')
          : t('chat.button')
      }
    >
      {icon}
    </IconButton>
  );
}

export default MessagingIndicator;
