import { useEffect } from 'react';
import { useCaption } from '../CaptionProvider';
export default function DataTrack({ track }) {
    const { enqueueCaptionFn, locallyDisplayCaptions } = useCaption();
    useEffect(() => {
        const handleMessage = (message) => {
            try {
                const messageData = JSON.parse(message);
                switch (messageData.subtype) {
                    case 'captionData':
                        if (locallyDisplayCaptions) {
                            enqueueCaptionFn(messageData);
                        }
                        break;
                    default:
                        console.error('unrecognized message type:', messageData.subtype);
                }
            }
            catch (e) {
                console.error(e, message);
            }
        };
        track.on('message', handleMessage);
        return () => {
            track.off('message', handleMessage);
        };
    }, [track, enqueueCaptionFn, locallyDisplayCaptions]);
    return null; // This component does not return any HTML, so we will return 'null' instead.
}
