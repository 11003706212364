import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { Box, Button, IconButton, MenuItem, Tooltip, Typography, useTheme, } from '@material-ui/core';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { useWrappedTranslation } from '../../../utils/i18nService';
import { IconContainer } from '../../MenuBar/Menu/Menu';
export default function ToggleVideoButton(props) {
    const t = useWrappedTranslation();
    const theme = useTheme();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const lastClickTimeRef = useRef(0);
    const { hasVideoInputDevices } = useDevices();
    const label = !hasVideoInputDevices
        ? t('ToggleVideoButton.NoVideo')
        : isVideoEnabled
            ? t('ToggleVideoButton.EnableVideo')
            : t('ToggleVideoButton.DisableVideo');
    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }
    }, [toggleVideoEnabled]);
    if (props.variant && props.variant === 'menuItem') {
        return (_jsxs(MenuItem, { className: props.className, onClick: toggleVideo, disabled: !hasVideoInputDevices || props.disabled, children: [_jsx(IconContainer, { children: isVideoEnabled ? (_jsx(VideocamOutlinedIcon, {})) : (_jsx(VideocamOffOutlinedIcon, {})) }), _jsx(Typography, { variant: "body1", children: label })] }));
    }
    if (props.variant && props.variant === 'icon') {
        return (_jsx(Tooltip, { title: label, placement: props.placement, children: _jsx(Box, { boxShadow: 2, borderRadius: 20, children: _jsx(IconButton, { className: props.className, onClick: toggleVideo, style: {
                        backgroundColor: isVideoEnabled
                            ? theme.palette.grey[600]
                            : theme.palette.error.main,
                        color: theme.palette.getContrastText(isVideoEnabled
                            ? theme.palette.grey[600]
                            : theme.palette.error.main),
                        padding: '6px',
                    }, disabled: !hasVideoInputDevices || props.disabled, "aria-label": isVideoEnabled
                        ? t('ToggleVideoButton.EnableVideo')
                        : t('ToggleVideoButton.DisableVideo'), children: isVideoEnabled ? (_jsx(VideocamOutlinedIcon, {})) : (_jsx(VideocamOffOutlinedIcon, {})) }) }) }));
    }
    return (_jsx(Button, { className: props.className, onClick: toggleVideo, disabled: !hasVideoInputDevices || props.disabled, startIcon: isVideoEnabled ? _jsx(VideocamOutlinedIcon, {}) : _jsx(VideocamOffOutlinedIcon, {}), "aria-label": isVideoEnabled
            ? t('ToggleVideoButton.EnableVideo')
            : t('ToggleVideoButton.DisableVideo'), children: label }));
}
