export interface Deferred<T> {
  promise: Promise<T>;
  resolveFn: (value: T) => void;
  rejectFn: (reason: any) => void;
}

export function DeferredPromise<T>(): Deferred<T> {
  const result: Deferred<T> = {} as any as Deferred<T>;
  result.promise = new Promise<T>((resolve, reject) => {
    result.resolveFn = resolve;
    result.rejectFn = reject;
  });

  return result;
}
