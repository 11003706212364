/* eslint-disable */

import React, { useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  InQRadioButtonGroupMetaData,
  InQRadioButtonGroupContext,
} from '../InQRadioButtonGroup/InQRadioButtonGroup';

import './InQRadioButton.scss';
import HollowIcon from './icons/hollow.svg';
import PipIcon from './icons/pip.svg';
import ActiveIcon from './icons/active.svg';
import InactiveIcon from './icons/inactive.svg';

function InQRadioButton({
  id = '',
  className = '',
  label = '',
  value = '',
  children = <></>,
  whiteBackground = false,
}: {
  id?: string;
  className?: string;
  label?: JSX.Element | string;
  value: string;
  children?: React.ReactNode;
  whiteBackground?: Boolean;
}) {
  const metaData: InQRadioButtonGroupMetaData | undefined = useContext(
    InQRadioButtonGroupContext
  );
  const [radioButtonId] = useState(uuidv4());
  const handleChange = useCallback(
    (e: any) => {
      metaData.onChange(e.target.value);
    },
    [metaData]
  );
  const isChecked = value === metaData.selectedValue;

  if (!metaData) {
    return (
      <div className="inq-radiobutton">
        Must be nested inside InqRadioButtonGroup
      </div>
    );
  }

  const renderRadioIcon = () => {
    if (whiteBackground) {
      if (isChecked) {
        return <img src={ActiveIcon} alt="active" />;
      }
      return <img src={InactiveIcon} alt="inactive" />;
    }
    if (isChecked) {
      return <img src={PipIcon} alt="pip" />;
    }
    return <img src={HollowIcon} alt="hollow" />;
  };

  return (
    <div className={`inq-radiobutton ${className}`} id={id}>
      <input
        id={radioButtonId}
        type="radio"
        name={metaData.groupName}
        value={value}
        onChange={handleChange}
        checked={isChecked}
        tabIndex={0}
      />
      <label htmlFor={radioButtonId}>
        <div className={`radio-icon ${isChecked ? 'checked' : ''}`}>
          {renderRadioIcon()}
        </div>
        <div className="inq-label">
          {label}
          {children}
        </div>
      </label>
    </div>
  );
}

export default InQRadioButton;
