import React from 'react';
import './TextBlockDescription.scss';

function TextBlockDescription({
  children = <></>,
  alignCenter = false,
}: {
  children?: JSX.Element;
  alignCenter?: Boolean;
}) {
  if (!alignCenter) {
    return <div className="text-description">{children}</div>;
  }
  return <div className="text-description-center">{children}</div>;
}

export default TextBlockDescription;
