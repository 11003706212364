import { jsx as _jsx } from "react/jsx-runtime";
import { Box, IconButton, Tooltip, useTheme } from '@material-ui/core';
import CallEndOutlinedIcon from '@material-ui/icons/CallEndOutlined';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useWrappedTranslation } from '../../../utils/i18nService';
import { useDeviceOrientation } from '../../DeviceOrientationProvider';
export default function EndCallButton(props) {
    const t = useWrappedTranslation();
    const theme = useTheme();
    const { room } = useVideoContext();
    const { isPortrait } = useDeviceOrientation();
    const label = t('EndCallButton');
    const handleClick = () => {
        room.disconnect();
    };
    return (_jsx(Tooltip, { title: label, placement: isPortrait ? 'left' : 'top', children: _jsx(Box, { boxShadow: 2, borderRadius: 20, children: _jsx(IconButton, { className: props.className, onClick: handleClick, style: {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.getContrastText(theme.palette.error.main),
                    padding: '6px',
                }, "aria-label": label, "data-cy-disconnect": true, children: _jsx(CallEndOutlinedIcon, {}) }) }) }));
}
