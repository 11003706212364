import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Video from "twilio-video";
function AboutDialog({ open, onClose }) {
    return (_jsxs(Dialog, { open: open, onClose: onClose, fullWidth: true, maxWidth: "xs", children: [_jsx(DialogTitle, { children: "About" }), _jsx(Divider, {}), _jsxs(DialogContent, { children: [_jsxs(DialogContentText, { children: ["Browser supported: ", String(Video.isSupported)] }), _jsxs(DialogContentText, { children: ["SDK Version: ", Video.version] })] }), _jsx(Divider, {}), _jsx(DialogActions, { children: _jsx(Button, { onClick: onClose, color: "primary", variant: "contained", autoFocus: true, children: "OK" }) })] }));
}
export default AboutDialog;
