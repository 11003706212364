import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@material-ui/core';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { useAppState } from '../../state';
import { isMobile } from '../../utils';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleCaptionButton from '../Buttons/ToggleCaptionButton/ToggleCaptionButton';
import ToggleScreenShareButton from '../Buttons/ToggleScreenShareButton/ToggleScreenShareButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useDeviceOrientation } from '../DeviceOrientationProvider';
export default function MenuBar() {
    const roomState = useRoomState();
    const { isPortrait } = useDeviceOrientation();
    const isReconnecting = roomState === 'reconnecting';
    const { captioningAllowed } = useAppState();
    return (_jsxs(Grid, { container: true, direction: isPortrait ? 'column' : 'row', justifyContent: "flex-end", alignItems: "flex-end", spacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(ToggleAudioButton, { variant: "icon", disabled: isReconnecting, placement: isPortrait ? 'left' : 'top' }) }), _jsx(Grid, { item: true, children: _jsx(ToggleVideoButton, { variant: "icon", disabled: isReconnecting, placement: isPortrait ? 'left' : 'top' }) }), captioningAllowed && (_jsx(Grid, { item: true, children: _jsx(ToggleCaptionButton, { variant: "icon", disabled: isReconnecting, placement: isPortrait ? 'left' : 'top' }) })), !isMobile && (_jsx(Grid, { item: true, children: _jsx(ToggleScreenShareButton, { variant: "icon", disabled: isReconnecting }) })), _jsx(Grid, { item: true, children: _jsx(EndCallButton, {}) })] }));
}
