const _ = require('lodash');
const { invokeApi } = require('./utils');

const createCSV = (header, data) => {
  return _.join([
    _.join(header, ','),
    ..._.map(data, row => _.join(row, ',')),
  ], '\n')
};

const downloadReports = async ({visitReport, denialReport, queueReport, eventReport}, from, to) => {
  let mergedData = {};

  do {
    let content = await invokeApi('raam.downloadReports')({
      visitReport,
      denialReport,
      queueReport,
      eventReport,
      from,
      to,
    });
    from = content.nextFrom;

    _.each(_.pull(_.keys(content), 'nextFrom', 'queryTo'),
      (key) => {
        let mData = mergedData[key] ?? {header: null, data: []};
        let {data, header} = content[key];

        mData.header ??= header;
        mData.data.push(...data);

        mergedData[key] = mData;
      });
  } while (from);

  return _.map(mergedData, ({header, data}, key) => {
    let s = createCSV(header, data)
    return [key, s];
  });
};

module.exports = {
  downloadReports
};
