import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useRef, useCallback } from "react";
import { makeStyles, Link } from "@material-ui/core";
import { useAppState } from "../../../state";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
const useStyles = makeStyles({
    userContainer: {
        position: "absolute",
        top: 0,
        right: 0,
        margin: "1em",
        display: "flex",
        alignItems: "center"
    },
    userButton: {
        color: "white"
    },
    logoutLink: {
        color: "white",
        cursor: "pointer",
        padding: "10px 20px"
    }
});
const UserMenu = () => {
    const classes = useStyles();
    const { user, signOut } = useAppState();
    const { localTracks } = useVideoContext();
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleSignOut = useCallback(() => {
        localTracks.forEach(track => track.stop());
        signOut === null || signOut === void 0 ? void 0 : signOut();
    }, [localTracks, signOut]);
    if (process.env.REACT_APP_SET_AUTH === "passcode") {
        return (_jsx("div", { className: classes.userContainer, children: _jsx(Link, { onClick: handleSignOut, className: classes.logoutLink, children: "Logout" }) }));
    }
    return null;
};
export default UserMenu;
