import _ from 'lodash';
import { useEffect, useState } from 'react';
import { observe } from '../Services/PatronService';
import { IPatronIds } from '../Types';

export default function usePatron(patronIds: IPatronIds) {
  /* TODO: Make this a IPatron ? */
  const [patron, setPatron] = useState<any>({});
  const [inError, setInError] = useState<boolean>(false);

  useEffect(() => {
    const o$ = observe(patronIds);

    const subscription = o$.subscribe(
      (v: any) => {
        // If the incoming object has and id field, it most likely is a Patron instance
        if (!_.has(v, 'id')) {
          setInError(true);
        }

        setPatron(v);
      },
      () => setInError(true)
    );

    return () => subscription.unsubscribe();
  }, [patronIds]);

  return [patron, inError];
}
