/* eslint-disable class-methods-use-this */
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { chat as chatClient, conversation } from 'hive-chat-client';
import _ from 'lodash';
import { assertConnection } from '../HiveService';
import {
  ChatApi,
  ChatApiMessage,
  ChatApiReadMarker,
  ChatApiTypingNotification,
  ChatApiParticipant,
  ParticipantType,
} from './chat.model';
/**
 * This is a lower level event driven API.  It is intended to be consumed by the Chat Service and used to interface with the messaging back end.
 * Please do not directly access this API from anywhere except the chat service
 */
class HiveChatApiImplementation implements ChatApi {
  public onMessage: ReplaySubject<ChatApiMessage[]>;

  public onRead: ReplaySubject<ChatApiReadMarker[]>;

  public onTyping: Observable<ChatApiTypingNotification[]>;

  public onParticipants: ReplaySubject<ChatApiParticipant[]>;

  private conversation$: Observable<any>;

  private subscription: Subscription;

  constructor(
    private chatId: string,
    private senderId: string,
    participantType: ParticipantType,
    peerParticipantType: ParticipantType
  ) {
    const { bee } = assertConnection();
    this.conversation$ = conversation.observe(bee, this.chatId, this.senderId);

    let participantsPropagated = false;
    this.onMessage = new ReplaySubject<ChatApiMessage[]>();
    this.subscription = this.conversation$.subscribe({
      next: (nextConversation: any) => {
        if (!participantsPropagated) {
          participantsPropagated = true;
          this.onParticipants.next(
            _.map(nextConversation.participants, (p) => ({
              id: p.id,
              type:
                p.id === this.senderId ? participantType : peerParticipantType,
            }))
          );
        }

        const hiveMessages: any[] = nextConversation.messages;
        const messages: ChatApiMessage[] = hiveMessages.map((m) =>
          this.processMessage(m)
        ) as any;
        this.onMessage.next(messages);

        const readMarkers = _.compact(
          hiveMessages.map((m) => this.extractReadMarker(m))
        );
        this.onRead.next(readMarkers);
      },
      error: (error: any) => {
        this.onMessage.error(error);
      },
    });

    this.onRead = new ReplaySubject();
    this.onTyping = new ReplaySubject();
    this.onParticipants = new ReplaySubject();
  }

  processMessage(message: any): Partial<ChatApiMessage> {
    return {
      id: message.id,
      authorId: message.sender,
      whenSent: new Date(message.created),
      message: message.text,
      hasBeenEdited: false,
    };
  }

  extractReadMarker(message: any): ChatApiReadMarker | undefined {
    const readBy = _.get(message, 'readBy', []);
    if (_.includes(readBy, this.senderId)) {
      return {
        participantId: this.senderId,
        messageId: message.id,
        timestamp: new Date(message.created),
      };
    }
    return undefined;
  }

  dispose(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  sendMessage(message: Partial<ChatApiMessage>) {
    const { bee } = assertConnection();
    return chatClient.sendMessage(
      bee,
      this.chatId,
      this.senderId,
      message.message
    );
  }

  deleteMessage() {
    // Nothing to do
  }

  userIsTyping() {
    // Nothing to do
  }

  markRead(userId: string, messageId: string) {
    const { bee } = assertConnection();
    return chatClient.markMessageAsRead(bee, messageId, userId);
  }
}

async function openChatApi(
  chatId: string,
  participantType: ParticipantType,
  ...args: any[]
): Promise<ChatApi> {
  const senderId = args[0];
  const peerParticipantType =
    participantType === ParticipantType.Admin
      ? ParticipantType.User
      : ParticipantType.Admin;
  return new HiveChatApiImplementation(
    chatId,
    senderId,
    participantType,
    peerParticipantType
  );
}

export default openChatApi;
