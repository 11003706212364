import React, { ChangeEventHandler, useCallback, useState } from 'react';
import './StyledFileInput.scss';
import { Button, IconButton } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

function getBlob(elem: HTMLInputElement | undefined): Blob | undefined {
  if (!elem || !elem.files || elem.files.length < 0) {
    return undefined;
  }
  return elem.files[0];
}

function checkAllowedAcceptCharacters(accept: string) {
  return /^[a-zA-Z/]+$/.test(accept);
}
function assertAllowedAcceptCharacters(accept: string) {
  if (!checkAllowedAcceptCharacters(accept)) {
    throw new Error(`Invalid characters in accept string: ${accept}.  a-zA-Z/`);
  }
}

function StyledFileInput({
  children,
  accept,
  takeNew,
  color,
  size,
  variant,
  isIcon,
  onFileSelected,
}: {
  children?: JSX.Element;
  accept: string[];
  takeNew?: boolean;
  color?: any;
  size?: any;
  variant?: any;
  isIcon?: boolean;
  onFileSelected: (file: Blob) => void;
}) {
  accept.forEach(assertAllowedAcceptCharacters);
  const [id] = useState(uuidv4());
  const sendFile: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const blob = getBlob(e.target);
      if (blob) {
        onFileSelected(blob);
      }
    },
    [onFileSelected]
  );

  return (
    <div className="styled-file-input">
      <input
        id={id}
        type="file"
        accept={accept.join(', ')}
        capture={takeNew}
        onChange={sendFile}
      />
      <label htmlFor={id}>
        {isIcon ? (
          <IconButton component="span">{children}</IconButton>
        ) : (
          <Button component="span" color={color} size={size} variant={variant}>
            {children}
          </Button>
        )}
      </label>
    </div>
  );
}

export default StyledFileInput;
