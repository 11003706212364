import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  useAppState,
  useAsync,
  useEncryptedLocalStorage,
  useUAParser,
  useValidateQueryParams,
  useWindowDimensions,
} from '../../Hooks/';
import { useFeatureFlags } from '../../Hooks/useFeatureFlags';
import {
  AboutInQ,
  ClosedQuestion,
  FrontPage,
  ManitobaRegion,
  ProvideInfo,
  RegionalEligibility,
  ScreenCheckIn,
  ScreenHealthCardCapture,
  ScreenHealthCardNeeded,
  ScreenManitobaHealthCardForm,
  ScreenOntarioHealthCardForm,
  ScreenPleaseWait,
  ScreenSomethingHappened,
  ScreenSubstanceUnsupported,
  ScreenSummary,
} from '../../Screens';
import { getCurrentDateTime } from '../../Services/PatronService';
import { useExtendedTranslation } from '../../Services/i18nService';
import { DenialType, ISurveyData } from '../../Types';
import { AppStates } from '../../Types/IAppState';
import DEFAULT_USER_DATA_RAW from '../../Util/defaultUserData';

enum Screen {
  Welcome,
  PatronInfo,
  RegionalInfo,

  FirstTimeQ,
  ManitobaRegionQ,
  HaveBookingQ,
  AlcoholQ,
  OpioidQ,
  StimulantsQ,
  OtherSubstancesQ,
  MentalHealthQ,

  MorePatronInfo,

  HealthCardNeeded,
  HealthCardPhoto,
  HealthCardText,

  ManitobaHealthCard,

  Summary,

  SubstanceUnsupported,
  PleaseWait,
  Error,

  About,
}

const DEFAULT_USER_DATA = { ...DEFAULT_USER_DATA_RAW, currentAddress: '' };

export default function SurveyFlow({
  onCheckIn,
  error,
  setError,
}: {
  onCheckIn: Function;
  error: boolean;
  setError: Function;
}) {
  const [rememberedData, setRememberedData] = useEncryptedLocalStorage(
    'surveyData',
    DEFAULT_USER_DATA
  );

  const fromOFD = useValidateQueryParams({ check_in: 'true' });
  const [screen, setScreen] = useState<Screen>(
    fromOFD ? Screen.PatronInfo : Screen.Welcome
  );
  const [surveyData, setSurveyData] = useState<ISurveyData>(rememberedData);
  const getCurrentDateTimeAsync = useAsync(getCurrentDateTime);
  const initDateTime = _.get(
    getCurrentDateTimeAsync,
    ['value', 'details', 'message'],
    ''
  );

  const userAgentDetails = useUAParser();
  const windowDimension = useWindowDimensions();

  const { REACT_APP_USE_MANITOBA_HEALTHCARD } = useFeatureFlags();

  useEffect(() => {
    setSurveyData((survey) => {
      return {
        ...survey,
        ...rememberedData,
      };
    });
  }, [rememberedData]);

  const appendSurveyData = (data: any) => {
    const m = { ...surveyData, ...data };
    setSurveyData(m);
    return m;
  };

  const goTo = (newScreen: Screen) => () => setScreen(newScreen);
  const isOn = (expected: Screen) => screen === expected;
  const displayWhen = (expected: Screen, elements: JSX.Element) =>
    isOn(expected) && elements;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const appState = useAppState();
  const t = useExtendedTranslation();

  const solveError = () => setError(false);

  useEffect(() => {
    if (fromOFD) {
      getCurrentDateTimeAsync()
        .then(goTo(Screen.PatronInfo))
        .catch(goTo(Screen.Error));
    }
  }, [getCurrentDateTimeAsync, fromOFD]);

  useEffect(() => {
    if (error) {
      setScreen(Screen.Error);
    }
    if (appState !== AppStates.OPEN && screen !== Screen.Welcome) {
      enqueueSnackbar(
        <Box>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', marginRight: '4px' }}
          >
            {t('error.queue.closed.snackbar')}
          </Typography>
        </Box>,
        {
          key: 'queue-closed',
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          preventDuplicate: true,
          persist: true,
          action: (key) => (
            <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
              <CloseIcon />
            </IconButton>
          ),
        }
      );
      setScreen(Screen.Welcome);
    }
  }, [appState, enqueueSnackbar, closeSnackbar, t, screen, error]);

  return (
    <>
      {displayWhen(
        Screen.Welcome,
        <FrontPage
          appState={appState}
          onNext={_.flow([
            () =>
              getCurrentDateTimeAsync()
                .then(goTo(Screen.PatronInfo))
                .catch(goTo(Screen.Error)),
          ])}
          onAbout={goTo(Screen.About)}
        />
      )}

      {displayWhen(
        Screen.PatronInfo,
        <ScreenCheckIn
          onNext={(d: any) => {
            const { inRegion } = appendSurveyData(d);
            if (d.rememberMe) {
              setRememberedData({
                ...rememberedData,
                ..._.pick(d, [
                  'firstName',
                  'lastName',
                  'phoneNumber',
                  'allowSMS',
                  'email',
                  'inRegion',
                  'rememberMe',
                ]),
              });
            } else {
              localStorage.removeItem('surveyData');
            }
            if (inRegion) {
              setScreen(Screen.FirstTimeQ);
            } else {
              _.flow([
                () =>
                  onCheckIn(surveyData, initDateTime, DenialType.SERVICE_AREA),
                goTo(Screen.SubstanceUnsupported),
              ])();
            }
          }}
          onRegion={_.flow([appendSurveyData, goTo(Screen.RegionalInfo)])}
          userData={surveyData}
        />
      )}

      {displayWhen(
        Screen.RegionalInfo,
        <RegionalEligibility onNext={goTo(Screen.PatronInfo)} />
      )}

      {displayWhen(
        Screen.FirstTimeQ,
        <ClosedQuestion
          questionKey="survey.question.firstTime"
          onYes={_.flow([
            () =>
              appendSurveyData({
                isFirstTime: true,
                userAgent: userAgentDetails,
                screenWidth: windowDimension.width,
                screenHeight: windowDimension.height,
                screenOrientation:
                  windowDimension.width > windowDimension.height
                    ? 'landscape'
                    : 'portrait',
              }),
            REACT_APP_USE_MANITOBA_HEALTHCARD
              ? goTo(Screen.ManitobaRegionQ)
              : goTo(Screen.AlcoholQ),
          ])}
          onNo={_.flow([
            () =>
              appendSurveyData({
                isFirstTime: false,
                userAgent: userAgentDetails,
                screenWidth: windowDimension.width,
                screenHeight: windowDimension.height,
                screenOrientation:
                  windowDimension.width > windowDimension.height
                    ? 'landscape'
                    : 'portrait',
              }),
            goTo(Screen.HaveBookingQ),
          ])}
        />
      )}

      {displayWhen(
        Screen.ManitobaRegionQ,
        <ManitobaRegion
          onContinue={(shmbRegion: string) => {
            let survey = appendSurveyData({
              shmbRegion,
            });

            let nextScreenFn = survey.isFirstTime
              ? goTo(Screen.AlcoholQ)
              : goTo(Screen.HaveBookingQ);

            nextScreenFn();
          }}
        />
      )}

      {displayWhen(
        Screen.HaveBookingQ,
        <ClosedQuestion
          questionKey="survey.question.havebooking"
          onYes={_.flow([
            () => appendSurveyData({ haveAppointment: true }),
            (data) => onCheckIn(data, initDateTime),
            goTo(Screen.PleaseWait),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ haveAppointment: false }),
            (data) => onCheckIn(data, initDateTime),
            goTo(Screen.PleaseWait),
          ])}
        />
      )}

      {displayWhen(
        Screen.AlcoholQ,
        <ClosedQuestion
          questionKey="survey.question.useAlcohol"
          onYes={_.flow([
            () => appendSurveyData({ alcoholQ: true }),
            goTo(Screen.OpioidQ),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ alcoholQ: false }),
            goTo(Screen.OpioidQ),
          ])}
        />
      )}

      {displayWhen(
        Screen.OpioidQ,
        <ClosedQuestion
          questionKey="survey.question.useOpioid"
          subKey="survey.question.opioid.small"
          onYes={_.flow([
            () => appendSurveyData({ opioidQ: true }),
            goTo(Screen.StimulantsQ),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ opioidQ: false }),
            goTo(Screen.StimulantsQ),
          ])}
        />
      )}

      {displayWhen(
        Screen.StimulantsQ,
        <ClosedQuestion
          questionKey="survey.question.stimulantsOrAmphetamines"
          subKey="survey.question.stimulantsOrAmphetamines.small"
          onYes={_.flow([
            () => appendSurveyData({ stimulantsQ: true }),
            goTo(Screen.OtherSubstancesQ),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ stimulantsQ: false }),
            goTo(Screen.OtherSubstancesQ),
          ])}
        />
      )}

      {displayWhen(
        Screen.OtherSubstancesQ,
        <ClosedQuestion
          questionKey="survey.question.useOfOtherSubstances"
          onYes={_.flow([
            () => appendSurveyData({ otherSubstancesQ: true }),
            goTo(Screen.MentalHealthQ),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ otherSubstancesQ: false }),
            ({ alcoholQ, opioidQ, stimulantsQ }) => {
              if (alcoholQ || opioidQ || stimulantsQ) {
                setScreen(Screen.MentalHealthQ);
              } else {
                _.flow([
                  () =>
                    onCheckIn(
                      surveyData,
                      initDateTime,
                      DenialType.INVALID_ADDICTION
                    ),
                  goTo(Screen.SubstanceUnsupported),
                ])();
              }
            },
          ])}
        />
      )}

      {displayWhen(
        Screen.MentalHealthQ,
        <ClosedQuestion
          questionKey="survey.question.mentalHealth"
          onYes={_.flow([
            () => appendSurveyData({ mentalHealthQ: true }),
            goTo(Screen.MorePatronInfo),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ mentalHealthQ: false }),
            goTo(Screen.MorePatronInfo),
          ])}
        />
      )}

      {displayWhen(
        Screen.MorePatronInfo,
        <ProvideInfo
          userData={surveyData}
          onNext={_.flow([
            appendSurveyData,
            goTo(
              REACT_APP_USE_MANITOBA_HEALTHCARD
                ? Screen.ManitobaHealthCard
                : Screen.HealthCardNeeded
            ),
          ])}
        />
      )}

      {displayWhen(
        Screen.HealthCardNeeded,
        <ScreenHealthCardNeeded
          onCamera={goTo(Screen.HealthCardPhoto)}
          onText={goTo(Screen.HealthCardText)}
        />
      )}

      {displayWhen(
        Screen.HealthCardPhoto,
        <ScreenHealthCardCapture
          onNext={_.flow([appendSurveyData, goTo(Screen.Summary)])}
          onBack={goTo(Screen.HealthCardNeeded)}
          onCameraError={goTo(Screen.HealthCardText)}
        />
      )}

      {displayWhen(
        Screen.HealthCardText,
        <ScreenOntarioHealthCardForm
          userData={surveyData}
          onNext={_.flow([appendSurveyData, goTo(Screen.Summary)])}
        />
      )}

      {displayWhen(
        Screen.ManitobaHealthCard,
        <ScreenManitobaHealthCardForm
          userData={surveyData}
          onNext={_.flow([appendSurveyData, goTo(Screen.Summary)])}
        />
      )}

      {displayWhen(
        Screen.Summary,
        <ScreenSummary
          userData={surveyData}
          setUserData={setSurveyData}
          onNext={_.flow([
            appendSurveyData,
            (data) => onCheckIn(data, initDateTime),
            goTo(Screen.PleaseWait),
          ])}
        />
      )}

      {displayWhen(
        Screen.SubstanceUnsupported,
        <ScreenSubstanceUnsupported onNext={goTo(Screen.Welcome)} />
      )}
      {displayWhen(
        Screen.PleaseWait,
        <ScreenPleaseWait message_key="pleasewait.title" />
      )}
      {displayWhen(
        Screen.Error,
        <ScreenSomethingHappened
          onNext={_.flow([solveError, goTo(Screen.Welcome)])}
        />
      )}

      {displayWhen(
        Screen.About,
        <AboutInQ onNext={_.flow([goTo(Screen.Welcome)])} />
      )}
    </>
  );
}
