import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormControl, FormControlLabel, MenuItem, Select, Switch, Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isSupported } from '@twilio/video-processors';
import { useEffect, useState } from 'react';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY, } from '../../../constants';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useWrappedTranslation } from '../../../utils/i18nService';
import VideoTrack from '../../VideoTrack/VideoTrack';
const useStyles = makeStyles({
    preview: {
        width: '300px',
        maxHeight: '200px',
        margin: '0.5em auto',
        '& video': {
            maxHeight: '200px',
        },
    },
});
export default function VideoInputList() {
    const t = useWrappedTranslation();
    const classes = useStyles();
    const { videoInputDevices } = useDevices();
    const { localTracks, backgroundSettings, setBackgroundSettings } = useVideoContext();
    const localVideoTrack = localTracks.find((track) => track.kind === 'video');
    const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
    const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY));
    const localVideoInputDeviceId = (mediaStreamTrack === null || mediaStreamTrack === void 0 ? void 0 : mediaStreamTrack.getSettings().deviceId) || storedLocalVideoDeviceId;
    const [blurEnabled, setBlurEnabled] = useState(backgroundSettings.type === 'blur');
    useEffect(() => {
        blurEnabled
            ? setBackgroundSettings({ type: 'blur' })
            : setBackgroundSettings({ type: 'none' });
    }, [blurEnabled, setBackgroundSettings]);
    function replaceTrack(newDeviceId) {
        // Here we store the device ID in the component state. This is so we can re-render this component display
        // to display the name of the selected device when it is changed while the users camera is off.
        setStoredLocalVideoDeviceId(newDeviceId);
        window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
        localVideoTrack === null || localVideoTrack === void 0 ? void 0 : localVideoTrack.restart(Object.assign(Object.assign({}, DEFAULT_VIDEO_CONSTRAINTS), { deviceId: { exact: newDeviceId } }));
    }
    return (_jsxs("div", { children: [localVideoTrack && (_jsx("div", { className: classes.preview, children: _jsx(VideoTrack, { isLocal: true, track: localVideoTrack }) })), videoInputDevices.length > 1 ? (_jsxs(FormControl, { fullWidth: true, children: [_jsx(Typography, { variant: "subtitle2", gutterBottom: true, children: t('VideoInputList') }), _jsx(Select, { onChange: (e) => replaceTrack(e.target.value), value: localVideoInputDeviceId || '', variant: "outlined", children: videoInputDevices.map((device) => (_jsx(MenuItem, { value: device.deviceId, children: device.label }, device.deviceId))) })] })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle2", gutterBottom: true, children: t('VideoInputList') }), _jsx(Typography, { children: (localVideoTrack === null || localVideoTrack === void 0 ? void 0 : localVideoTrack.mediaStreamTrack.label) ||
                            t('VideoInputList.NoDevices') })] })), isSupported && (_jsx(FormControlLabel, { control: _jsx(Switch, { checked: blurEnabled, onChange: () => setBlurEnabled((prev) => !prev), color: "primary", name: "blur background" }), label: t('ToggleBlurBackground') }))] }));
}
