import React, { MouseEventHandler, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import {
  TextBlockDescription,
  TextBlockHint,
  InQField,
  SinglePanelWithMessaging,
} from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import sanitize from '../../Util/sanitize';
import { leaveQueue } from '../../Services/PatronService';

function LeaveQueue({
  patron,
  onCancel,
}: {
  patron: any;
  onCancel: MouseEventHandler<HTMLElement>;
}) {
  const t = useExtendedTranslation();
  const [reason, setReason] = useState('');

  const handleLeave = () => {
    if (patron?.id) {
      leaveQueue(patron?.id, sanitize(reason));
    }

    // THIS should never happen
  };

  return (
    <Box display="flex" flex={1}>
      <SinglePanelWithMessaging patron={patron}>
        <TextBlockDescription>{t('leave.sure')}</TextBlockDescription>
        <TextBlockHint>{t('leave.lose')}</TextBlockHint>
        <InQField
          placeholder={t('leave.placeholder')}
          value={reason}
          onChange={setReason}
          inputProps={{ maxLength: 256 }}
          multiline
          minRows={8}
        />
        <Grid container justifyContent="space-evenly">
          <Grid item xs={6}>
            <Button
              variant="outlined"
              onClick={handleLeave}
              color="primary"
              fullWidth
            >
              {t('leave.queue')}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              onClick={onCancel}
              color="primary"
              fullWidth
            >
              {t('leave.cancel')}
            </Button>
          </Grid>
        </Grid>
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default LeaveQueue;
