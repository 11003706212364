import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { useExtendedTranslation } from '../../Services/i18nService';

function ScreenUnsupportedBrowser() {
  const t = useExtendedTranslation();

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography
          variant="h5"
          color="primary"
          align="center"
          style={{ fontStyle: 'italic' }}
        >
          {t('unsupported.title')}
        </Typography>
        <Typography variant="body2" color="primary" align="center">
          {t('unsupported.instruction.browser')}
          <br />
          {t('unsupported.instruction.connection')}
          <Link
            href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts"
            target="_blank"
            rel="noopener"
          >
            <HelpIcon fontSize="small" />
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ScreenUnsupportedBrowser;
