/* eslint-disable no-console */
import { Participant, Chat, ChatView, ParticipantType } from './chat.model';
import { memoizeFunction, setImmediate } from '../../Util/util';
import openChatApi from './chat.api.hive';
import ChatImplementation from './chat.class';
import ChatViewImplementation from './chatview.class';
import { isFeatureEnabled, FeatureFlags } from '../featureFlags';

const MAX_TIME_TO_WAIT_FOR_PARTICIPANT_REGISTRATION_IN_MS = 10 * 1000;

function createChatViewForParticipant(
  chat: Chat,
  participant: Participant
): ChatView {
  return new ChatViewImplementation(chat, participant);
}

async function createChatById(
  chatId: string,
  participantType: ParticipantType,
  ...args: any[]
): Promise<Chat> {
  return new ChatImplementation(
    await openChatApi(chatId, participantType, ...args),
    chatId
  );
}

function createChatViewNotMemoized(
  chatId: string,
  participantType: ParticipantType,
  ...args: any[]
): Promise<ChatView> {
  if (!isFeatureEnabled(FeatureFlags.Chat)) {
    return new Promise(() => {}) as any;
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<ChatView>(async (resolve, reject) => {
    try {
      const chat = await createChatById(chatId, participantType, ...args);
      const watchParticipants = chat.participants.subscribe((list) => {
        try {
          const user = list.find((u) => u.type === participantType);
          if (user) {
            setImmediate(() => watchParticipants.unsubscribe());
            const chatView = createChatViewForParticipant(chat, user);
            resolve(chatView);
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
      setTimeout(
        () => reject(new Error("Couldn't find matching user")),
        MAX_TIME_TO_WAIT_FOR_PARTICIPANT_REGISTRATION_IN_MS
      );
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
}

/**
 * Creates a chat view for a specific chat conversation using the specified view.
 * This function assumes that that there will be at most one participant per participant type.
 * @param chatId
 * @param participantType
 */
const createChatView = memoizeFunction(
  createChatViewNotMemoized,
  (chatId: any, participantType: any) => `${chatId}|${participantType}`
);

export default createChatView;
