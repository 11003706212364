import { useEffect, useState } from 'react';
export default function useDataTrack(room) {
    var _a, _b, _c;
    const [dataTrack, setDataTrack] = useState();
    useEffect(() => {
        var _a, _b;
        if (!((_b = (_a = room === null || room === void 0 ? void 0 : room.localParticipant) === null || _a === void 0 ? void 0 : _a.dataTracks) === null || _b === void 0 ? void 0 : _b.size)) {
            return;
        }
        const dataT = room.localParticipant.dataTracks.values().next()
            .value;
        setDataTrack({
            send: (msg) => {
                return dataT === null || dataT === void 0 ? void 0 : dataT.track.send(JSON.stringify(msg));
            },
        });
    }, [
        (_a = room === null || room === void 0 ? void 0 : room.localParticipant) === null || _a === void 0 ? void 0 : _a.dataTracks,
        (_c = (_b = room === null || room === void 0 ? void 0 : room.localParticipant) === null || _b === void 0 ? void 0 : _b.dataTracks) === null || _c === void 0 ? void 0 : _c.size,
    ]);
    return dataTrack;
}
