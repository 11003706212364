import {
  Box,
  Button,
  Fab,
  FabProps,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import { FC } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { SinglePanelLayout } from '../../Components';
import useApplicationConfig from '../../Hooks/useApplicationConfig';
import useClinicConfig from '../../Hooks/useClinicConfig';
import {
  getLanguage,
  useExtendedTranslation,
} from '../../Services/i18nService';

const ContactFab: FC<{ label: string } & FabProps> = ({
  href,
  label,
  children,
}) => {
  const theme = useTheme();
  return (
    <Grid container direction="column" alignItems="center" spacing={1}>
      <Grid item>
        <Fab
          size="large"
          href={href}
          style={{
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.contrastText,
          }}
        >
          {children}
        </Fab>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="primary" align="center">
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface SubstanceUnsupportedScreenProps {
  onNext: () => void;
}

function ScreenSubstanceUnsupported({
  onNext,
}: SubstanceUnsupportedScreenProps) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const language = getLanguage();
  const applicationConfig = useApplicationConfig();

  return (
    <Box display="flex" flex={1}>
      <SinglePanelLayout
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                onClick={() => onNext()}
                color="primary"
              >
                {t('questionnaire.close')}
              </Button>
            </Grid>
          </Grid>
        }
        logo={clinicConfig.logoName}
      >
        <Grid
          container
          direction="column"
          spacing={8}
          style={{
            paddingTop: '12px',
            paddingBottom: '12px',
            height: '100%',
            width: '100%',
            margin: 0,
            padding: 0,
          }}
        >
          <Grid item style={{ padding: '0.5em' }}>
            <Typography paragraph variant="body2" color="primary">
              {ReactHtmlParser(
                applicationConfig.msgSubstanceUnsupported[language]
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="center" spacing={8}>
              {applicationConfig.msgSubstanceUnsupported.showPhone && (
                <Grid item>
                  <ContactFab
                    href={`tel:${clinicConfig.phoneNo}${
                      clinicConfig.phoneNoExt
                        ? `,${clinicConfig.phoneNoExt}`
                        : ''
                    }`}
                    label={t('substanceUnsupportedScreen.callNow')}
                  >
                    <PhoneOutlinedIcon fontSize="large" />
                  </ContactFab>
                </Grid>
              )}
              {applicationConfig.msgSubstanceUnsupported.showEmail && (
                <Grid item>
                  <ContactFab
                    href={`mailto:${clinicConfig.email}`}
                    label={t('substanceUnsupportedScreen.emailNow')}
                  >
                    <EmailOutlinedIcon fontSize="large" />
                  </ContactFab>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 'auto' }}>
            <Typography
              variant="body2"
              color="primary"
              style={{ fontStyle: 'italic' }}
              align="center"
            >
              {t('substanceUnsupportedScreen.emergency')}
            </Typography>
          </Grid>
        </Grid>
      </SinglePanelLayout>
    </Box>
  );
}
export default ScreenSubstanceUnsupported;
