import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CssBaseline } from '@material-ui/core';
import App from '../App';
import '../types';
import { CaptionProvider } from './CaptionProvider';
import { ChatProvider } from './ChatProvider';
import ResponsiveProvider from './DeviceOrientationProvider';
import UnsupportedBrowserWarning from './UnsupportedBrowserWarning/UnsupportedBrowserWarning';
export const TwilioVideoCall = ({ optionButtons }) => {
    return (_jsxs(_Fragment, { children: [_jsx(CssBaseline, {}), _jsx(UnsupportedBrowserWarning, { children: _jsx(CaptionProvider, { children: _jsx(ResponsiveProvider, { children: _jsx(ChatProvider, { children: _jsx(App, { optionButtons: optionButtons }) }) }) }) })] }));
};
