var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_AUDIO_INPUT_KEY, SELECTED_VIDEO_INPUT_KEY } from "../../../constants";
import { getDeviceInfo, isPermissionDenied } from "../../../utils";
import { useCallback, useState } from "react";
import Video from "twilio-video";
export default function useLocalTracks() {
    const [audioTrack, setAudioTrack] = useState();
    const [videoTrack, setVideoTrack] = useState();
    const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);
    const getLocalAudioTrack = useCallback((deviceId) => {
        const options = {};
        if (deviceId) {
            options.deviceId = { exact: deviceId };
        }
        return Video.createLocalAudioTrack(options).then(newTrack => {
            setAudioTrack(newTrack);
            return newTrack;
        });
    }, []);
    const getLocalVideoTrack = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);
        const { videoInputDevices } = yield getDeviceInfo();
        const hasSelectedVideoDevice = videoInputDevices.some(device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId);
        const options = Object.assign(Object.assign(Object.assign({}, DEFAULT_VIDEO_CONSTRAINTS), { name: `camera-${Date.now()}` }), (hasSelectedVideoDevice && {
            deviceId: { exact: selectedVideoDeviceId }
        }));
        return Video.createLocalVideoTrack(options).then(newTrack => {
            setVideoTrack(newTrack);
            return newTrack;
        });
    }), []);
    const removeLocalAudioTrack = useCallback(() => {
        if (audioTrack) {
            audioTrack.stop();
            setAudioTrack(undefined);
        }
    }, [audioTrack]);
    const removeLocalVideoTrack = useCallback(() => {
        if (videoTrack) {
            videoTrack.stop();
            setVideoTrack(undefined);
        }
    }, [videoTrack]);
    const getAudioAndVideoTracks = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = yield getDeviceInfo();
        if (!hasAudioInputDevices && !hasVideoInputDevices)
            return Promise.resolve();
        if (isAcquiringLocalTracks || audioTrack || videoTrack)
            return Promise.resolve();
        setIsAcquiringLocalTracks(true);
        const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
        const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);
        const hasSelectedAudioDevice = audioInputDevices.some(device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId);
        const hasSelectedVideoDevice = videoInputDevices.some(device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId);
        // In Chrome, it is possible to deny permissions to only audio or only video.
        // If that has happened, then we don't want to attempt to acquire the device.
        const isCameraPermissionDenied = yield isPermissionDenied("camera");
        const isMicrophonePermissionDenied = yield isPermissionDenied("microphone");
        const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
        const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;
        const localTrackConstraints = {
            video: shouldAcquireVideo && Object.assign(Object.assign(Object.assign({}, DEFAULT_VIDEO_CONSTRAINTS), { name: `camera-${Date.now()}` }), (hasSelectedVideoDevice && {
                deviceId: { exact: selectedVideoDeviceId }
            })),
            audio: shouldAcquireAudio &&
                (hasSelectedAudioDevice
                    ? { deviceId: { exact: selectedAudioDeviceId } }
                    : hasAudioInputDevices)
        };
        return Video.createLocalTracks(localTrackConstraints)
            .then(tracks => {
            var _a;
            const newVideoTrack = tracks.find(track => track.kind === "video");
            const newAudioTrack = tracks.find(track => track.kind === "audio");
            if (newVideoTrack) {
                setVideoTrack(newVideoTrack);
                // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
                // in cases where the user's video is disabled.
                window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, (_a = newVideoTrack.mediaStreamTrack.getSettings().deviceId) !== null && _a !== void 0 ? _a : "");
            }
            if (newAudioTrack) {
                setAudioTrack(newAudioTrack);
            }
            // These custom errors will be picked up by the MediaErrorSnackbar component.
            if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
                const error = new Error();
                error.name = "NotAllowedError";
                throw error;
            }
            if (isCameraPermissionDenied) {
                throw new Error("CameraPermissionsDenied");
            }
            if (isMicrophonePermissionDenied) {
                throw new Error("MicrophonePermissionsDenied");
            }
        })
            .finally(() => setIsAcquiringLocalTracks(false));
    }), [audioTrack, videoTrack, isAcquiringLocalTracks]);
    const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined);
    return {
        localTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        isAcquiringLocalTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        getAudioAndVideoTracks
    };
}
