import _ from 'lodash';
import { AudioConfig, ProfanityOption, SpeechConfig, SpeechRecognizer, } from 'microsoft-cognitiveservices-speech-sdk';
import { useEffect, useState } from 'react';
export default function useSpeechRecognizer(token, languageCode, micSource, setMessage) {
    const [recognizer, setRecognizer] = useState();
    useEffect(() => {
        if (!token) {
            return setRecognizer(undefined);
        }
        const speechConfig = SpeechConfig.fromAuthorizationToken(token.authToken, token.region);
        speechConfig.speechRecognitionLanguage = languageCode;
        speechConfig.setProfanity(ProfanityOption.Raw);
        // undefined is used as default microphone
        const audioConfig = AudioConfig.fromMicrophoneInput(micSource !== null && micSource !== void 0 ? micSource : undefined);
        const recog = new SpeechRecognizer(speechConfig, audioConfig);
        recog.recognizing = (_sender, eventArgs) => {
            setMessage(_.get(eventArgs, 'result.text', ''));
        };
        recog.recognized = (_sender, eventArgs) => {
            if (eventArgs) {
                setMessage(_.get(eventArgs, 'result.text', ''));
            }
        };
        recog.canceled = () => {
            recog.stopContinuousRecognitionAsync();
        };
        recog.sessionStopped = () => {
            recog.stopContinuousRecognitionAsync();
        };
        setRecognizer(recog);
        return () => recog.close();
    }, [languageCode, micSource, setMessage, token]);
    return recognizer;
}
