const ConnectionService = require('./ConnectionService');

const logAndThrow = message => {
  console.error(message);
  throw new Error(message);
};

function assertConnection() {
  let connection;
  ConnectionService.connection$
    .subscribe(conn => (connection = conn))
    .unsubscribe();
  if (!connection) {
    throw new Error('Connection is needed!');
  }

  return connection;
}

module.exports = {
  logAndThrow,
  assertConnection,
};