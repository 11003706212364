export const DEFAULT_VIDEO_CONSTRAINTS = {
    // https://twilio.github.io/twilio-video-processors.js/classes/gaussianblurbackgroundprocessor.html
    width: 640,
    height: 480,
    frameRate: 24,
};
// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';
// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';
// These are used for speech-to-text (live captioning)
export const DEFAULT_CAPTION_LANGUAGE_CODE = 'en-CA';
export const CAPTION_DISPLAY_TIMEOUT = 8000;
export const STT_TOKEN_MAX_AGE = 540; // token is valid for 10 minutes however Microsoft recommends using for 9 minutes as a buffer
