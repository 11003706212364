import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core/styles";
import AvatarIcon from "../../icons/AvatarIcon";
import MainParticipantInfoBox from "../MainParticipantInfoBox/MainParticipantInfoBox";
import Typography from "@material-ui/core/Typography";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import { Grid } from "@material-ui/core";
import { useWrappedTranslation } from "../../utils/i18nService";
const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        display: "flex",
        alignItems: "center"
    },
    identity: {
        background: "rgba(0, 0, 0, 0.5)",
        color: "white",
        padding: "0.1em 0.3em 0.1em 0",
        display: "inline-flex",
        "& svg": {
            marginLeft: "0.3em"
        },
        marginRight: "0.4em",
        alignItems: "center"
    },
    infoContainer: {
        position: "absolute",
        zIndex: 2,
        height: "100%",
        width: "100%",
        display: "flex",
        padding: "16px"
    },
    reconnectingContainer: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(40, 42, 43, 0.75)",
        zIndex: 1
    },
    fullWidth: {
        gridArea: "1 / 1 / 2 / 3",
        [theme.breakpoints.down("sm")]: {
            gridArea: "1 / 1 / 3 / 3"
        }
    },
    avatarContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "black",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        "& svg": {
            transform: "scale(2)"
        }
    },
    recordingIndicator: {
        position: "absolute",
        bottom: 0,
        display: "flex",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
        color: "white",
        padding: "0.1em 0.3em 0.1em 0",
        fontSize: "1.2rem",
        height: "28px",
        [theme.breakpoints.down("sm")]: {
            bottom: "auto",
            right: 0,
            top: 0
        }
    },
    circle: {
        height: "12px",
        width: "12px",
        background: "red",
        borderRadius: "100%",
        margin: "0 0.6em",
        animation: `1.25s $pulsate ease-out infinite`
    },
    "@keyframes pulsate": {
        "0%": {
            background: `#A90000`
        },
        "50%": {
            background: "#f00"
        },
        "100%": {
            background: "#A90000"
        }
    }
}));
export default function MainParticipantInfo({ participant, children, disableInfoBox = false }) {
    const t = useWrappedTranslation();
    const classes = useStyles();
    const publications = usePublications(participant);
    const videoPublication = publications.find(p => !p.trackName.includes("screen") && p.kind === "video");
    const screenSharePublication = publications.find(p => p.trackName.includes("screen"));
    const videoTrack = useTrack(screenSharePublication || videoPublication);
    const isVideoEnabled = Boolean(videoTrack);
    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);
    return (_jsxs(Grid, { container: true, "data-cy-main-participant": true, "data-cy-participant": participant.identity, alignItems: "center", direction: "column", style: { position: "relative" }, children: [_jsx("div", { className: classes.infoContainer, children: !disableInfoBox && (_jsx(Grid, { container: true, justifyContent: "center", alignItems: "flex-end", children: _jsx(Grid, { item: true, children: _jsx(MainParticipantInfoBox, { participant: participant }) }) })) }), (!isVideoEnabled || isVideoSwitchedOff) && (_jsx("div", { className: classes.avatarContainer, children: _jsx(AvatarIcon, {}) })), isParticipantReconnecting && (_jsx("div", { className: classes.reconnectingContainer, children: _jsx(Typography, { variant: "body1", style: { color: "white" }, children: t("MainParticipantInfo.Reconnecting") }) })), _jsx(Grid, { xs: true, item: true, style: { display: "flex", flexDirection: "column", width: "100%" }, children: children })] }));
}
